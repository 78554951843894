import React from 'react';
import PropTypes from 'prop-types';

import { currencyFormatter } from '@frontend/common';

import { Paper } from '@mui/material';

import styles from './styles.module.css';

export function ProgramTotals(props) {
  const inlineStyles = {
    paperContainer: {
      minWidth: '350px',
      marginTop: '20px',
    },
  };

  /* eslint-disable indent */
  return (
    <Paper
      elevation={1}
      style={inlineStyles.paperContainer}
    >
      <div className={styles.ProgramTotals_paperContent}>
        <div className={styles.ProgramTotals_header}>Total Values</div>
        <div className={styles.ProgramTotals_body}>
          <div className={styles.ProgramTotals_totalRow}>
            <span className={styles.ProgramTotals_rowTitle}>Master Accounts Total</span>
            <span>{currencyFormatter(props.masterAccountsTotal)}</span>
          </div>
          { props.individualAccountsTotal > 0
            ? <div className={styles.ProgramTotals_totalRow}>
                <span className={styles.ProgramTotals_rowTitle}>Individual Accounts Total</span>
                <span>{currencyFormatter(props.individualAccountsTotal)}</span>
              </div>
            : null
          }
          { props.scholarshipAccountsTotal > 0
            ? <div className={styles.ProgramTotals_totalRow}>
                <span className={styles.ProgramTotals_rowTitle}>Scholarship Accounts Total</span>
                <span>{currencyFormatter(props.scholarshipAccountsTotal)}</span>
              </div>
            : null
          }
          <div className={styles.ProgramTotals_totalRow}>
            <span className={styles.ProgramTotals_rowTitle}>All Accounts Total</span>
            <span>{currencyFormatter(props.masterAccountsTotal + props.scholarshipAccountsTotal + props.individualAccountsTotal)}</span>
          </div>
        </div>
      </div>
    </Paper>
  );
  /* eslint-enable indent */
}

ProgramTotals.propTypes = {
  masterAccountsTotal: PropTypes.number.isRequired,
  scholarshipAccountsTotal: PropTypes.number.isRequired,
  individualAccountsTotal: PropTypes.number.isRequired,
};

ProgramTotals.defaultProps = {
  masterAccountsTotal: 0,
  scholarshipAccountsTotal: 0,
  individualAccountsTotal: 0,
};

export default ProgramTotals;
