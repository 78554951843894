import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot, } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import promise from 'redux-promise';

import { apiResponse } from 'utils/helpers/middleware';

import throttle from 'lodash/throttle';

import rootReducer from './reducers';
import { saveState, loadState, } from 'utils/helpers/state_handler';
import AppRoot from 'components/AppRoot/index';

// retreive state from localStorage
const preloadedState = loadState();

// check if in dev environment
const URL = window.location.host;
const isDevEnv = Boolean(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) // eslint-disable-line no-underscore-dangle
  && (URL.includes('localhost') || URL.includes('dev') || URL.includes('qa'));

// create store
export const store = configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware: [promise, apiResponse],
  devTools: isDevEnv, // makes sure devTools are turn off in prod
});

// save store to localStorage
store.subscribe(throttle(() => {
  saveState(store.getState());
}), 1000);

if (process.env.NODE_ENV !== 'test') {
  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <AppRoot />
      </BrowserRouter>
    </Provider>
  );
}