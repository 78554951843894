import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import Banner from 'components/Features/Banner';
import { BANNER_LOCATIONS } from 'components/AppRoot/StaticResources/constants';

import {
  TextField,
  Button,
} from '@mui/material';

import {
  FormWrapper,
  LoadingOverlay,
  StyledLink,
  notificationShow,
  allNotificationsHide
} from '@frontend/common';

import { userLogin } from 'components/AppRoot/Navigation/actions';
import { getWebMessages } from 'components/AppRoot/StaticResources/actions';

import styles from './styles.module.css';

const select = (state) => ({
  isValid: state.session.isValid,
  webMessages: state.staticResources.webMessages,
});

export class Login extends Component {

  static propTypes = {
    userLogin: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    notificationShow: PropTypes.func.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    getWebMessages: PropTypes.func.isRequired,
    webMessages: PropTypes.object.isRequired,
  };

  state = {
    username: '',
    password: '',
    errors: {},
    loading: false,
    publicLoading: false,
  };

  formValidate = () => {
    let isValid = false;
    let errors = {};

    if (!this.state.username) {
      errors.username = 'Username is required.';
    }

    if (!this.state.password) {
      errors.password = 'Password is required.';
    }

    if (this.state.username && this.state.password) {
      errors = {};
      isValid = true;
    }

    this.setState({
      errors
    });

    return isValid;
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    this.props.allNotificationsHide();

    if (this.formValidate()) {
      document.getElementById('loginUsername').blur();
      document.getElementById('loginPassword').blur();
      const params = {
        username: this.state.username,
        password: this.state.password
      };
      this.setState({ loading: true });
      this.props.userLogin(params)
        .catch(() => this.setState({ loading: false }));
    }
  }

  onInputChange = (inputName, value) => {
    const clonedErrors = cloneDeep(this.state.errors);

    if (value.length > 0 && this.state.errors[inputName]) {
      clonedErrors[inputName] = '';
    }

    this.setState({
      [inputName]: value,
      errors: clonedErrors,
    });
  }

  componentDidMount() {
    this.setState({ publicLoading: true });
    this.props.getWebMessages()
      .then(() => this.setState({ publicLoading: false }))
      .catch(() => this.setState({ publicLoading: false }));
  }

  /* eslint-disable indent */
  render() {
    const { loading, publicLoading } = this.state;
    const { webMessages } = this.props;

    return (
      <div>
        <Banner show={Boolean(webMessages[BANNER_LOCATIONS.LOGIN])} body={webMessages[BANNER_LOCATIONS.LOGIN]} />
        <FormWrapper
          onSubmit={() => this.onFormSubmit()}
          title='Log In'
        >
          <LoadingOverlay show={publicLoading} width='100%'>
            <form
              className={styles.formContainer}
              onSubmit={this.onFormSubmit}
              data-testid='auto-Login-submit-form'
            >
              <TextField
                id='loginUsername'
                name='username'
                label='Email'
                onChange={(e) => this.onInputChange('username', e.target.value)}
                inputProps={{ 'data-testid': 'auto-Login-usernameEmail-input' }}
                value={this.state.username}
                disabled={loading}
                error={Boolean(this.state.errors.username)}
                helperText={this.state.errors.username}
                fullWidth

              />
              <TextField
                id='loginPassword'
                name='password'
                label='Password'
                type='password'
                onChange={(e) => this.onInputChange('password', e.target.value)}
                inputProps={{ 'data-testid': 'auto-Login-password-input' }}
                value={this.state.password}
                disabled={loading}
                error={Boolean(this.state.errors.password)}
                helperText={this.state.errors.password}
                fullWidth
              />
              <div className={styles.Login_loginButton}>
                <LoadingOverlay show={loading} width='100%'>
                  <Button
                    type='submit'
                    variant='contained'
                    disabled={loading}
                    fullWidth
                    data-testid='auto-Login-submit-button'
                  >
                    Log In
                  </Button>
                </LoadingOverlay>
              </div>
              <div className={styles.Login_linkContainer}>
                {loading
                  ? <small key='forgotPassword' className={styles.Login_disabledLink}>Forgot Password?</small>
                  : <StyledLink to='/forgot-password' key='forgotPasswordLink'><small>Forgot Password?</small></StyledLink>
                }
              </div>
            </form>

          </LoadingOverlay>
        </FormWrapper>
      </div>
    );
  }
  /* eslint-enable indent */
}

export default withRouter(connect(select, {
  userLogin,
  notificationShow,
  allNotificationsHide,
  getWebMessages,
})(Login));
