import { 
  GETrequest, 
  POSTrequest, 
  PUTrequest,
  DELETErequest,
} from 'utils/helpers/api_handler';
import {
  ACCOUNT_ADDRESS_CREATE,
  ACCOUNT_ADDRESS_UPDATE,
  ACCOUNT_ADDRESS_DELETE,
  ACCOUNTS_GET,
  ACCOUNT_GET,
  ACCOUNTS_BY_PROGRAM_GET,
  INTERESTED_PARTY_ADD,
  INTERESTED_PARTY_REMOVE,
  INTERESTED_PARTY_RESEND,
  STUDENT_NUMBER_UPDATE,
  TRANSFER_DETAILS_GET,
} from './constants';

export function accountAddressCreate(id, addressType, personType, params) {
  const response = POSTrequest(`/accounts/${id}/${personType}address`, params);
  return {
    type: ACCOUNT_ADDRESS_CREATE,
    meta: { addressType, personType },
    payload: response
  };
}

export function accountAddressUpdate(id, addressType, personType, params) {
  const response = PUTrequest(`/accounts/${id}/${personType}address`, params);
  return {
    type: ACCOUNT_ADDRESS_UPDATE,
    meta: { addressType, personType },
    payload: response
  };
}

export function accountAddressDelete(id, personType, addressId) {
  const response = DELETErequest(`/accounts/${id}/${personType}address/${addressId}`);
  return {
    type: ACCOUNT_ADDRESS_DELETE,
    payload: response
  };
}

export function getAccounts() {
  const response = GETrequest('/accounts');
  return {
    type: ACCOUNTS_GET,
    payload: response
  };
}

export function getAccount(id) {
  const response = GETrequest(`/accounts/${id}`);
  return {
    type: ACCOUNT_GET,
    payload: response
  };
}

export function getAccountsByProgram(programId) {
  const response = GETrequest(`/accounts?programId=${programId}`);
  return {
    type: ACCOUNTS_BY_PROGRAM_GET,
    payload: response
  };
}

export function interestedPartyAdd(id, params) {
  const response = POSTrequest(`/accounts/${id}/interestedparty`, params);
  return {
    type: INTERESTED_PARTY_ADD,
    payload: response
  };
}

export function interestedPartyRemove(accountId, partyId) {
  const response = PUTrequest(`/accounts/${accountId}/interestedparty/${partyId}/remove`);
  return {
    type: INTERESTED_PARTY_REMOVE,
    payload: response
  };
}

export function interestedPartyResend(accountId, partyId) {
  const response = POSTrequest(`/accounts/${accountId}/interestedparty/${partyId}/resendactivationemail`, {});
  return {
    type: INTERESTED_PARTY_RESEND,
    payload: response
  };
}

export function studentNumberUpdate(id, params) {
  const response = POSTrequest(`/accounts/${id}/studentnumber`, params);
  return {
    type: STUDENT_NUMBER_UPDATE,
    payload: response
  };
}

export function getTransferDetails(acctId, transferId) {
  const response = GETrequest(`/accounts/${acctId}/transfers/${transferId}`);
  return {
    type: TRANSFER_DETAILS_GET,
    payload: response,
    meta: { transferId },
  };
}

