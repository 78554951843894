import { notificationShow } from '@frontend/common';

// Middleware
export const apiResponse = store => next => action => {
  if (action.error) return;

  const { payload } = action;
  
  if (payload) { // Async actions
    
    // debugger; // eslint-disable-line
    
    const errorMsg = [];
    const warningMsg = [];
    const infoMsg = [];
    
    if (payload.status >= 299 && payload.status < 400) { // Redirection
      errorMsg.push('A temporary redirect was issued by the server.'); 
      window.location.href = '/';
    } 
    else if (payload.status === 400) { // payload.data may be an array of messages.
      if (payload.data && payload.data.length > 0) {
        payload.data.forEach(msg => {
          switch (msg.MessageType) {
            case 0: infoMsg.push(msg.Message); break;
            case 1: warningMsg.push(msg.Message); break;
            case 2: errorMsg.push(msg.Message); break;
            default: break;
          }
        });
      }
      else {
        errorMsg.push('A bad request or invalid credentials were provided. No messages were provided. Status: 400.');
      }
    }
    else if (payload.status === 401) { // Likely invalid token
      errorMsg.push('Unauthorized request.');
      import('components/AppRoot/Navigation/actions')
        .then(({ clearStore }) => {
          store.dispatch(clearStore());
        });
    }
    else if (payload.status === 403) { // Likely user doesn't have needed Claim/Permission
      errorMsg.push('Access denied');
      import('components/AppRoot/Navigation/actions')
        .then(({ clearStore }) => {
          store.dispatch(clearStore());
        });
    }
    else if (payload.status === 404) {
      errorMsg.push('Not found');
    }
    else if (payload.status > 404 && payload.status < 409) { // race condition
      errorMsg.push('You are attempting an action that is not allowed. Contact my529 if you continue to experience this issue.');
    }
    else if (payload.status === 409) {
      errorMsg.push('You are attempting to modify a record that is in the process of being modified.');
    }
    else if (payload.status > 409 && payload.status < 600) {
      import('./error_handler')
        .then(({ persistClientErrors }) => {
          persistClientErrors(payload.statusText); // send the GUID to the API so it can persist the GUID into the WebErrorLog table
        });
      errorMsg.push('There was an issue communicating with the server. Please try again later, or contact my529 if you continue to experience this issue.');
    }

    if (errorMsg.length > 0) {
      errorMsg.forEach(error => store.dispatch(notificationShow(error, 'error')));
      const updatedAction = action;
      updatedAction.type = `${action.type}_ERRORS`;
      next(updatedAction);
      return Promise.reject(action);
    }
    else {
      warningMsg.forEach(warning => store.dispatch(notificationShow(warning, 'warning')));
      infoMsg.forEach(info => store.dispatch(notificationShow(info, 'warning')));
      return next(action);
    }

  }
  else { // Non-async actions
    return next(action);
  }

};