import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Modal,
  notificationShow
} from '@frontend/common';
import { TextField } from '@mui/material';

import { witdrawalReject } from 'components/Features/protected/Withdrawals/actions';

import styles from './styles.module.css';


export class RejectWithdrawalModal extends React.Component {

  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    notificationShow: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    tableDataGet: PropTypes.func.isRequired,
    withdrawalInfo: PropTypes.array.isRequired,
    witdrawalReject: PropTypes.func.isRequired,
  };

  state = {
    rejectionButtonDisabled: true,
    rejectionReason: '',
    loading: false,
  };

  rejectionReasonChange = (e) => {
    const val = e.target.value;
    if (val.length >= 1) {
      this.setState({
        rejectionReason: val,
        rejectionButtonDisabled: false,
      });
    }
    else {
      this.setState({ rejectionButtonDisabled: true });
    }
  }

  onWithdrawalReject() {
    this.setState({
      loading: true,
    });

    this.props.witdrawalReject(this.props.id, { rejectionReason: this.state.rejectionReason })
      .then(() => {
        this.rejectionModalClose();
        this.props.notificationShow('Withdrawal rejected.', 'success');
        this.props.tableDataGet();
      })
      .catch(() => null)
      .finally(() => this.setState({ loading: false }));
  }

  rejectionModalClose() {
    this.setState({
      rejectionButtonDisabled: true,
      rejectionReason: ''
    });
    this.props.onCloseModal();
  }

  render() {
    return (
      <div>
        <Modal
          actionButtons={[
            {
              label: 'Cancel',
              action: this.props.onCloseModal,
              disabled: this.state.loading,
            },
            {
              label: 'Reject',
              action: () => this.onWithdrawalReject(),
              disabled: this.state.rejectionButtonDisabled,
              loading: this.state.loading,
              buttonType: 'contained',
            },
          ]}
          onCloseModal={this.props.onCloseModal}
          show={this.props.show}
          title='Reject Withdrawal'
        >
          <div className={styles.RejectWithdrawalModal_tableContainer}>
            <table className={styles.RejectWithdrawalModal_tableContent}>
              {this.props.withdrawalInfo}
            </table>
          </div>
          <TextField
            label='Rejection reason'
            onChange={this.rejectionReasonChange}
            fullWidth
            autoFocus
            inputProps={{ 'data-testid': 'auto-RejectWithdrawalModal-rejectionReason-input' }}
          />
        </Modal>
      </div>
    );
  }
}

export default connect(null, { notificationShow, witdrawalReject })(RejectWithdrawalModal);
