import { cloneDeep } from 'lodash';
import {
  REPORTS_GET,
  REPORT_GET,
  PROGRAMS_GET
} from './constants';

const initialState = {
  reportList: [],
  reportData: {},
  programs: [],
};

function ReportsReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case REPORT_GET: {
      newState.reportData = action.payload.data;
      return newState;
    }

    case REPORTS_GET: {
      newState.reportList = action.payload.data;
      return newState;
    }

    case PROGRAMS_GET: {
      newState.programs = action.payload.data.Programs;
      return newState;
    }

    default:
      return state;
  }
  
}

export default ReportsReducer;