import {
  GETrequest,
  POSTrequest,
  DELETErequest,
  PUTrequest
} from 'utils/helpers/api_handler';

import {
  TAG_GET,
  TAGS_GET,
  TAG_CREATE,
  TAG_DELETE,
  TAG_RENAME,
  TAGS_ASSIGN,
  TAGS_UNASSIGN
} from './constants';

export function getTag(id) {
  const response = GETrequest(`/tags/${id}`);
  return {
    type: TAG_GET,
    payload: response
  };
}

export function getTags() {
  const response = GETrequest('/tags');
  return {
    type: TAGS_GET,
    payload: response
  };
}

export function createTag(params) {
  const response = POSTrequest('/tags', params);
  return {
    type: TAG_CREATE,
    payload: response
  };
}

export function deleteTag(id) {
  const response = DELETErequest(`/tags/${id}`);
  return {
    type: TAG_DELETE,
    payload: response,
  };
}

export function renameTag(params) {
  const response = PUTrequest('/tags', params);
  return {
    type: TAG_RENAME,
    payload: response
  };
}

export function tagsAssign(params) {
  const response = PUTrequest('/tags/assign', params);
  return {
    type: TAGS_ASSIGN,
    payload: response
  };
}

export function tagsUnassign(params) {
  const response = PUTrequest('/tags/unassign', params);
  return {
    type: TAGS_UNASSIGN,
    payload: response
  };
}