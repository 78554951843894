import { emailValidate } from '@frontend/common';

export const errors = {
  required_field: 'This field is required.',
  name: 'Please use allowable characters: . & / , ‘ -',
  phone_10_digits: 'Phone number needs to be 10 digits.',
  no_spaces_allowed: 'This field cannot contain spaces',
  invalid_email: 'Email address is not valid.',
  invalid_date: 'Date is not valid.',
  taxId_length: 'Tax ID number must be 9 digits.',
  alphanumeric_only: 'Please use only alphanumeric characters.',
  security_code_required: 'Security code is required.',
  pin_required: 'PIN is required.',
};

export const getNameError = (name) => {
  const nameRegex = /^[A-Za-z.\s,&/'-]+$/g; // eslint-disable-line no-useless-escape
  let error = '';
  if (!name) {
    error = errors.required_field;
  }
  else if (!nameRegex.test(name)) {
    error = errors.name;
  }
  return error;
};

export function getPhoneHasNo10DigitsError(phone) {
  if (phone.toString().match(/\d/g).length !== 10) {
    return errors.phone_10_digits;
  }
  return '';
}

export function getEmailError(email) {
  if (email.match(/\s+/)) {
    return errors.no_spaces_allowed; // no spaces anywhere
  }
  if (!emailValidate(email)) {
    return errors.invalid_email;
  }
  return '';
}

export function taxIdNumValidator(value) {
  const alphaNumericOnlyRegex = /^[0-9a-zA-Z\s]+$/g; // eslint-disable-line no-useless-escape
  if (!value) {
    return errors.required_field;
  }
  if (!alphaNumericOnlyRegex.test(value)) {
    return errors.alphanumeric_only;
  }
  if (value.length < 9 || value.length > 9) {
    return errors.taxId_length;
  }
  return '';
}

export function alphaNumericOnlyValidator(str) {
  const nonAlphanumericCharsRegex = /[^0-9a-zA-Z]/g; // space is not included

  if (nonAlphanumericCharsRegex.test(str)) {
    return errors.alphanumeric_only;
  }
  return '';
}

export function tokenValidator(token) {
  if (!token) {
    return errors.security_code_required;
  }
  return '';
}

export function pinValidator(pin) {
  if (!pin) {
    return errors.pin_required;
  }
  return '';
}


export const cleanDigits = (str) => str ? str.toString().replace(/[^\d]+/g, '') : ''; // remove all non-digit characters
