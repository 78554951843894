import { cloneDeep } from 'lodash';
import { properCase } from '@frontend/common';
import {
  ACCOUNT_ADDRESS_CREATE,
  ACCOUNT_ADDRESS_UPDATE,
  ACCOUNTS_GET,
  ACCOUNT_GET,
  ACCOUNTS_BY_PROGRAM_GET,
  TRANSFER_DETAILS_GET,
} from './constants';


const initialState = {
  accountList: [],
  accountsByProgramList: [],
  account: {},
  loadedTransferDetails: [],
  selectedTransfer: {},
};

const alphabeticalSortFunction = (benA, benB) => {
  const lastNameA = benA.LastName;
  const lastNameB = benB.LastName;
  const firstNameA = benA.FirstName;
  const firstNameB = benB.FirstName;
  const middleNameA = benA.MiddleName;
  const middleNameB = benB.MiddleName;

  // start with the last name
  if (lastNameA > lastNameB)
    return 1;
  else if (lastNameA < lastNameB)
    return -1;

  // else go to the first name
  if (firstNameA > firstNameB)
    return 1;
  else if (firstNameA < firstNameB)
    return -1;

  // else go the the middle name
  if (middleNameA > middleNameB)
    return 1;
  else if (middleNameA < middleNameB)
    return -1;
  else // nothing to sort them by
    return 0;
};

const displayName = (beneficiary) => properCase(`${beneficiary.LastName}, ${beneficiary.FirstName} ${beneficiary.MiddleName}`);

const formatAccountList = (accounts) => accounts
  .map(account => {
    account.BeneficiaryDisplayName = account.Beneficiary ? displayName(account.Beneficiary) : '';
    return account;
  })
  .sort((accountA, accountB) => alphabeticalSortFunction(accountA.Beneficiary, accountB.Beneficiary));

function AccountsReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case ACCOUNT_ADDRESS_CREATE:
    case ACCOUNT_ADDRESS_UPDATE: {
      const { personType, addressType } = action.meta;
      const address = action.payload.data;

      newState.account[`${properCase(personType)}${addressType}Address`] = {
        AddressId: address.AddressId,
        StreetAddress1: address.StreetAddress1,
        StreetAddress2: address.StreetAddress2,
        StreetAddress3: address.StreetAddress3,
        City: address.City,
        State: address.State,
        Zip: address.PostalCode,
      };
      return newState;
    }

    case ACCOUNTS_GET: {
      // add display name and order alphabeticaly
      newState.accountList = formatAccountList(action.payload.data);
      return newState;
    }

    case ACCOUNT_GET: {
      newState.account = action.payload.data;
      return newState;
    }

    case ACCOUNTS_BY_PROGRAM_GET: {
      // add display name and order alphabeticaly
      newState.accountsByProgramList = formatAccountList(action.payload.data);
      return newState;
    }

    case TRANSFER_DETAILS_GET: {
      const transferDetails = {
        transferId: action.meta.transferId,
        transfers: action.payload.data,
      };

      newState.selectedTransfer = transferDetails;
      newState.loadedTransferDetails.push(transferDetails);

      return newState;
    }

    default:
      return state;
  }

}

export default AccountsReducer;