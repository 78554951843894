import { POSTrequest } from 'utils/helpers/api_handler';
import {
  PASSWORD_CHANGE,
} from './constants';

export function changePassword(params) {
  const response = POSTrequest('/authentication/changepassword', params);
  return {
    type: PASSWORD_CHANGE,
    payload: response
  };
}