/*
*
* MyInfo Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PersistentDrawers, { Row, LeftMenuItems, LeftMenuItem, RightDrawer } from './PersistentDrawers';

import {
  LoadingOverlay,
} from '@frontend/common';

import { getPreferredMethod } from 'components/Features/protected/Multifactor/actions';

import ChangePassword from './ChangePassword';
import TwoFA from './TwoFA';
import Email from './Email';
import PhoneNumbers from './PhoneNumbers';
import NameHeaderWithEdit from './NameHeaderWithEdit';

import styles from './styles.module.css';

const select = (state) => ({
  userDetails: state.session.userDetails,
  preferredInfo: state.multifactor.preferredInfo,
  is2FABypassed: state.session.is2FABypassed,
});

export class MyInfo extends React.Component {
  static propTypes = {
    userDetails: PropTypes.object.isRequired,
    getPreferredMethod: PropTypes.func.isRequired,
    preferredInfo: PropTypes.object.isRequired,
    is2FABypassed: PropTypes.bool.isRequired,
  };

  state = {
    loading: false,
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.props.getPreferredMethod()
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    const { loading } = this.state;
    const {
      userDetails: {
        IsEntityAgent, EmailAddress, PhoneNumber
      },
      preferredInfo,
      is2FABypassed,
    } = this.props;

    return (
      <div className={styles.myInfo_left_container}>
        <LoadingOverlay show={loading} width='100%'>
          <NameHeaderWithEdit />
          <PersistentDrawers drawerWidth='390px' marginTop='60px'>
            <Row>
              <LeftMenuItems>
                <LeftMenuItem>
                  <div className={styles.title}>
                    Email (Username)
                  </div>
                </LeftMenuItem>
                <LeftMenuItem>
                  <div className={styles.contentLighter}>
                    {EmailAddress}
                  </div>
                </LeftMenuItem>
              </LeftMenuItems>
              <RightDrawer title='Email (Username)'>
                <Email />
              </RightDrawer>
            </Row>

            <Row>
              <LeftMenuItems>
                <LeftMenuItem>
                  <div className={styles.title}>
                    Password
                  </div>
                </LeftMenuItem>
                <LeftMenuItem>
                  <div className={styles.contentLighter}>
                    **********
                  </div>
                </LeftMenuItem>
              </LeftMenuItems>
              <RightDrawer title='Password'>
                <ChangePassword />
              </RightDrawer>
            </Row>
            {preferredInfo.IsRegistered && !is2FABypassed && (
              <Row>
                <LeftMenuItems>
                  <LeftMenuItem>
                    <div className={styles.title}>
                      Two-Factor Authentication
                    </div>
                  </LeftMenuItem>
                  <LeftMenuItem>
                    <div className={styles.contentLighter}>
                      {preferredInfo.IsRegistered ? 'Registered' : 'Not Registered'}
                    </div>
                  </LeftMenuItem>
                </LeftMenuItems>
                <RightDrawer title='Two-Factor Authentication'>
                  <TwoFA />
                </RightDrawer>
              </Row>
            )}
            {IsEntityAgent &&
              <Row>
                <LeftMenuItems>
                  <LeftMenuItem>
                    <div className={styles.title}>
                      Phone Number
                    </div>
                  </LeftMenuItem>
                  <LeftMenuItem>
                    <div className={styles.contentLighter}>
                      {PhoneNumber}
                    </div>
                  </LeftMenuItem>
                </LeftMenuItems>
                <RightDrawer
                  title={(
                    <div>
                      Phone Number
                    </div>
                  )}
                >
                  <PhoneNumbers />
                </RightDrawer>
              </Row>
            }

          </PersistentDrawers>

        </LoadingOverlay>
      </div>
    );
  }
}

export default connect(select, {
  getPreferredMethod
})(MyInfo);