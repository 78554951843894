
// export const DEVICE_TOKEN_CREATE = 'src/components/Features/protected/Multifactor/DEVICE_TOKEN_CREATE';
// export const DEVICE_TOKEN_GET = 'src/components/Features/protected/Multifactor/DEVICE_TOKEN_GET';

export const TWO_FACTOR_REGISTRATION = 'src/components/Features/protected/Multifactor/TWO_FACTOR_REGISTRATION';
export const TWO_FACTOR_REGISTRATION_UPDATE = 'src/components/Features/protected/Multifactor/TWO_FACTOR_REGISTRATION_UPDATE';
export const TWO_FACTOR_TOKEN = 'src/components/Features/protected/Multifactor/TWO_FACTOR_TOKEN';
export const TWO_FACTOR_TOKEN_VERIFICATION = 'src/components/Features/protected/Multifactor/TWO_FACTOR_TOKEN_VERIFICATION';
export const TWO_FACTOR_QRCODES = 'src/components/Features/protected/Multifactor/TWO_FACTOR_QRCODES';
export const TWO_FACTOR_PUSH = 'src/components/Features/protected/Multifactor/TWO_FACTOR_PUSH';
export const TWO_FACTOR_PUSH_VERIFICATION = 'src/components/Features/protected/Multifactor/TWO_FACTOR_PUSH_VERIFICATION';

export const TWO_FACTOR_PREFERRED_METHOD = 'src/components/Features/protected/Multifactor/TWO_FACTOR_PREFERRED_METHOD';
export const TWO_FACTOR_SAVE_PREFERRED_METHOD = 'src/components/Features/protected/Multifactor/TWO_FACTOR_SAVE_PREFERRED_METHOD';

export const VERIFY_PIN = 'src/components/Features/protected/Multifactor/VERIFY_PIN';
export const IS_PIN_VERIFIED = 'src/components/Features/protected/Multifactor/IS_PIN_VERIFIED';

export const REGISTER_METHODS = {
  SMS: 'SMS',
  CALL: 'CALL',
  INAPP: 'INAPP',
  INAPPTOKEN: 'INAPPTOKEN'
};