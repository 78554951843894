/*
*
* TransfeUI Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Icon,
  Button,
  Stepper,
  Step,
  StepButton,
  FormControl,
  FormHelperText,
  TextField
} from '@mui/material';
import {
  Dropdown,
  currencyFormatter,
  CurrencyInput,
  Card,
  LoadingOverlay,
  Modal,
  ConfirmModal,
  AutoComplete
} from '@frontend/common';
import { REQUEST_TYPE } from './constants';
import styles from './styles.module.css';

export class TransferUI extends React.Component {
  static propTypes = {
    requestType: PropTypes.oneOf([REQUEST_TYPE.CREATE, REQUEST_TYPE.UPDATE]).isRequired,
    notificationShow: PropTypes.func.isRequired,
    error: PropTypes.shape({
      Total: PropTypes.string,
      Program: PropTypes.string,
      targets: PropTypes.arrayOf(
        PropTypes.shape({
          AccountId: PropTypes.string,
          Amount: PropTypes.string
        }))
    }),
    stepIndex: PropTypes.number.isRequired,
    setStepIndex: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    requesting: PropTypes.bool.isRequired,
    programs: PropTypes.arrayOf(
      PropTypes.shape({
        AccountId: PropTypes.number,
        Name: PropTypes.string,
        MasterValue: PropTypes.number
      })
    ).isRequired,
    accountsByProgram: PropTypes.arrayOf(
      PropTypes.shape({
        AccountId: PropTypes.number,
        BeneficiaryDisplayName: PropTypes.string,
        AccountType: PropTypes.string,
        AccountNumber: PropTypes.number,
      })
    ).isRequired,
    milestones: PropTypes.arrayOf(
      PropTypes.string
    ).isRequired,
    selectedProgramId: PropTypes.oneOfType([PropTypes.number, PropTypes.string,]).isRequired,
    transferTargets: PropTypes.arrayOf(
      PropTypes.shape({
        AccountId: PropTypes.number,
        BeneficiaryDisplayName: PropTypes.string,
        AccountType: PropTypes.string,
        AccountNumber: PropTypes.number,
        Amount: PropTypes.number,
        Milestone: PropTypes.string
      })
    ).isRequired,
    onBackButtonClick: PropTypes.func.isRequired,
    onNextButtonClick: PropTypes.func.isRequired,
    cancelTransfer: PropTypes.func.isRequired,
    onProgramChange: PropTypes.func.isRequired,
    onTransferSubmit: PropTypes.func.isRequired,
    onAccountChange: PropTypes.func.isRequired,
    onMilestoneChange: PropTypes.func.isRequired,
    onAmountChange: PropTypes.func.isRequired,
    onAddRow: PropTypes.func.isRequired,
    onRemoveRow: PropTypes.func.isRequired,
    addNewMilestone: PropTypes.func.isRequired
  };

  state = {
    showNewMilestoneMode: false,
    showIndividualTransferConfirmation: false,
    newMilestone: '',
  };

  onAccountChange = (rowIndex) => (option) => {
    this.props.onAccountChange(rowIndex, option.value);
  };

  onMilestoneChange = (rowIndex) => value => {
    this.props.onMilestoneChange(rowIndex, value);
  };

  onAmountChange = (rowIndex) => objValue => {
    this.props.onAmountChange(rowIndex, objValue);
  };

  onRemoveRow = rowIndex => {
    this.props.onRemoveRow(rowIndex);
  }

  openNewMilestoneMode = () => this.setState({
    showNewMilestoneMode: true,
    newMilestone: ''
  });

  onTransferSubmit = () => {
    // show confirmation if there is at least one transfer to an individual account
    const isAnyTransferIndividual = this.props.transferTargets.find(target => target.AccountType === 'Individual');
    if (isAnyTransferIndividual) {
      this.setState({ showIndividualTransferConfirmation: true });
    }
    else {
      this.props.onTransferSubmit();
    }
  }

  onConfirmIndividualTransfer = () => {
    this.props.onTransferSubmit();
    this.setState({ showIndividualTransferConfirmation: false });
  }

  onCancelIndividualTransfer = () => {
    this.setState({ showIndividualTransferConfirmation: true });
  }

  addNewMilestone = () => {
    this.props.addNewMilestone(this.state.newMilestone);
    this.setState({ showNewMilestoneMode: false });
  };

  onNewMilestoneInputChange = (value) => {
    // capitalize first character of all words
    const formattedValue = value.toLowerCase().replace(/\b\w/g, (ch) => ch.toUpperCase());
    return this.setState({ newMilestone: formattedValue });
  };

  renderStepperButtons = () => {
    const { stepIndex, loading, requesting, onNextButtonClick, onBackButtonClick } = this.props;
    const cancelButton = (
      <Button
        key='cancel'
        disabled={requesting}
        onClick={this.props.cancelTransfer}
        variant='text'
        style={{ marginRight: '20px' }}
        data-testid='auto-TransferUI-cancelTransfer-button'
      >
        Cancel
      </Button>
    );
    const backButton = (
      <Button
        key='prevStep'
        disabled={requesting}
        onClick={onBackButtonClick}
        variant='text'
        style={{ marginRight: '20px' }}
        data-testid='auto-TransferUI-prevStep-button'
      >
        Back
      </Button>
    );
    const submitButton = (
      <LoadingOverlay key='updateButton' show={requesting}>
        <Button
          onClick={this.onTransferSubmit}
          variant='contained'
          data-testid='auto-TransferUI-submitTransfer-button'
        >
          Submit
        </Button>
      </LoadingOverlay>
    );
    const nextButton = (
      <LoadingOverlay
        show={loading}
        key={`nextStep-step-${stepIndex}`}
      >
        <Button
          onClick={onNextButtonClick}
          variant='contained'
          data-testid='auto-TransferUI-nextStep-button'
        >
          Next
        </Button>
      </LoadingOverlay>
    );

    let buttons = [
      cancelButton
    ];
    if (stepIndex !== 0) {
      buttons = [
        ...buttons,

        backButton
      ];
    }
    if (stepIndex === 2) {
      buttons = [
        ...buttons,
        submitButton
      ];
    }
    else {
      buttons = [
        ...buttons,
        nextButton
      ];
    }
    return buttons;
  };

  renderStepperContent = () => {
    const { showNewMilestoneMode, showIndividualTransferConfirmation } = this.state;
    const { error, milestones, transferTargets, selectedProgramId, stepIndex, programs, accountsByProgram, onAddRow, onProgramChange } = this.props;
    switch (stepIndex) {
      case 0: {
        return (
          <div className={styles.Transfer_program}>
            <Dropdown
              label='Program'
              value={selectedProgramId}
              onChange={onProgramChange}
              errorText={error.Program}
              options={programs.map(program => ({
                display: program.Name,
                value: program.AccountId
              }))}
              SelectProps={{
                inputProps: {
                  'data-testid': 'auto-TransferUI-program-dropdown'
                },
              }}
            />
          </div>
        );
      }
      case 1: {
        return (
          <div className={styles.Transfer_targets}>
            <div className={styles.New_milestone_button}>
              <Button
                onClick={this.openNewMilestoneMode}
                variant='text'
                data-testid='auto-TransferUI-openNewMilestone-button'
              >
                Add New Milestone
              </Button>
            </div>
            {transferTargets.map((target, i) => {
              return (
                <div key={`Transfer_target-${i}-${target.AccountId}`}>
                  <div className={styles.Transfer_target}>
                    <div className={styles.Transfer_targetAccount}>
                      <AutoComplete
                        MenuItemProps={{
                          divider: true,
                          style: {
                            padding: '2px',
                            whiteSpace: 'unset', // causes word wrap in dropdown options
                            height: '100%' // causes word wrap in dropdown options
                          }
                        }}
                        TextFieldProps={{
                          multiline: true, // causes word wrap in input field
                          label: 'Type account name or number',
                          error: Boolean(error.targets[i].AccountId),
                          inputProps: {
                            'data-testid': 'auto-TransferUI-accountIDAutoComplete-input'
                          }, // replaces inputProps on component so that the correct value will re-populate if user edits field
                        }}
                        onSelect={this.onAccountChange(i)}
                        options={accountsByProgram.map(account => ({
                          display: `${account.BeneficiaryDisplayName} (${account.AccountNumber})`,
                          value: account.AccountId
                        }))}
                        DownshiftProps={{
                          selectedItem: {
                            display: target.BeneficiaryDisplayName ? `${target.BeneficiaryDisplayName} (${target.AccountNumber})` : '',
                            value: target.AccountId
                          }
                        }}
                      />
                      {error.targets[i].AccountId && <FormHelperText error={Boolean(error.targets[i].AccountId)}>{error.targets[i].AccountId}</FormHelperText>}
                    </div>

                    <div className={styles.Transfer_targetMilestone}>
                      <Dropdown
                        label='Milestone (optional)'
                        value={target.Milestone || ''}
                        onChange={this.onMilestoneChange(i)}
                        options={[
                          {
                            value: '',
                            display: ' ',
                          },
                          ...milestones.map(milestone => ({ display: milestone, value: milestone }))
                        ]}
                        SelectProps={{
                          inputProps: {
                            'data-testid': 'auto-TransferUI-milestoneChange-dropdown'
                          },
                        }}
                      />
                    </div>
                    <div className={styles.Transfer_targetAmount}>
                      <CurrencyInput
                        className={styles.Transfer_targetAmount}
                        onChange={this.onAmountChange(i, 'Amount')}
                        value={parseFloat(target.Amount) || 0}
                        errorText={error.targets[i].Amount}
                      />
                    </div>
                    <div className={styles.Transfer_targetRemove}>
                      <IconButton
                        onClick={() => this.onRemoveRow(i)}
                        data-testid='auto-TransferUI-removeTargetRow-button'
                      >
                        <Icon>remove_circle</Icon>
                      </IconButton>
                    </div>
                  </div>
                  <div className={styles.Transfer_target}>
                    <div className={styles.Transfer_targetType}>{target.AccountType ? target.AccountType : ''}</div>
                    <div />
                    <div />
                    <div />
                  </div>
                </div>
              );
            }
            )}
            <div className={styles.Transfer_target}>
              {transferTargets.length > 0 ? (
                <FormControl className={styles.Transfers_total} error={Boolean(error.Total)}>
                  <span>
                    Total: {currencyFormatter(transferTargets.reduce((sum, target) => sum + target.Amount, 0))}
                  </span>
                  <FormHelperText>{error.Total}</FormHelperText>
                </FormControl>
              ) : null}
            </div>
            <div className={styles.Transfer_addTarget}>
              <IconButton
                onClick={onAddRow}
                data-testid='auto-TransferUI-addTargetRow-button'
              >
                <Icon>add_circle</Icon>
              </IconButton>
            </div>
            <Modal
              actionButtons={[
                {
                  label: 'Cancel',
                  action: () => this.setState({ showNewMilestoneMode: false })
                },
                {
                  label: 'Add',
                  buttonType: 'contained',
                  action: this.addNewMilestone
                }
              ]}
              onCloseModal={() => this.setState({ showNewMilestoneMode: false })}
              show={showNewMilestoneMode}
              title='Add New Milestone'
            >
              <TextField
                name='newMilestone'
                label='New Milestone'
                value={this.state.newMilestone}
                onChange={(e) => this.onNewMilestoneInputChange(e.target.value)}
                fullWidth
                autoFocus
                inputProps={{
                  'data-testid': 'auto-TransferUI-newMilestone-input'
                }}
              />
            </Modal>
          </div>
        );
      }
      case 2: {
        return (
          <div>
            <div className={styles.Transfer_reviewLines}>
              <div className={styles.Review_colHeader}>
                Account
              </div>
              <div className={styles.Review_colHeader}>
                Milestone
              </div>
              <div className={`${styles.Review_colHeader} ${styles['targetReview-Amount']}`}>
                Amount
              </div>
              {transferTargets.map((target, index) => {
                const milestoneName = target.Milestone ? milestones.find(milestone => milestone === target.Milestone) : '';
                return ([
                  <div
                    id={`targetReview-${index}-BeneficiaryName`}
                    key={`targetReview-${index}-BeneficiaryName`}
                    className={styles.Transfer_reviewBeneficiaryName}
                  >
                    {`${target.BeneficiaryDisplayName} (${target.AccountNumber})`}
                  </div>,
                  <div
                    id={`targetReview-${index}-Milestone`}
                    key={`targetReview-${index}-Milestone`}
                    className={styles.Transfer_reviewMilestoneId}
                  >
                    {milestoneName}
                  </div>,
                  <div
                    id={`targetReview-${index}-Amount`}
                    key={`targetReview-${index}-Amount`}
                    className={styles.Transfer_reviewAmount}
                  >
                    {currencyFormatter(target.Amount)}
                  </div>,
                  <div
                    id={`targetReview-${index}-type`}
                    key={`targetReview-${index}-type`}
                    className={styles.Transfer_targetType}
                  >
                    {target.AccountType}
                  </div>,
                  <div id={`targetReview-${index}-empty1`} key={`targetReview-${index}-empty1`} />,
                  <div id={`targetReview-${index}-empty2`} key={`targetReview-${index}-empty2`} />
                ]);
              })}
              <div className={styles.Transfer_totalReviewAmount}>
                Total: {currencyFormatter(transferTargets.reduce((sum, target) => sum + target.Amount, 0))}
              </div>
            </div>
            <ConfirmModal
              show={showIndividualTransferConfirmation}
              title={''}
              body={'By clicking confirm, I understand that I am requesting a transfer from a CSA entity-owned master account to an individual-owned account. I understand that I am surrendering control and all rights to the money and any interest being transferred.'}
              onModalClose={() => this.setState({ showIndividualTransferConfirmation: false })}
              onConfirm={this.onConfirmIndividualTransfer}
              isLoading={false}
            />
          </div>
        );
      }
      default:
        return 'Uknown step';
    }
  };

  render() {
    const title = this.props.requestType === REQUEST_TYPE.CREATE ? 'Create Transfer' : 'Update Transfer';
    return (
      <div className={styles.Transfers_container}>
        <Card title={title} className={styles.Transfers_workflow}>
          <Stepper
            className={styles.Transfer_stepper}
            activeStep={this.props.stepIndex}
            orientation={window.innerWidth > 600 ? 'horizontal' : 'vertical'}
          >
            <Step>
              <StepButton
                onClick={() => this.props.setStepIndex(0)}
                data-testid='auto-TransferUI-step0SelectProgram-stepButton'
              >
                Select Program
              </StepButton>
            </Step>
            <Step>
              <StepButton
                onClick={() => this.props.setStepIndex(1)}
                data-testid='auto-TransferUI-step1SetupAccounts-stepButton'
              >
                Setup Accounts
              </StepButton>
            </Step>
            <Step>
              <StepButton
                onClick={() => this.props.setStepIndex(2)}
                data-testid='auto-TransferUI-step2Review-stepButton'
              >
                Review
              </StepButton>
            </Step>
          </Stepper>
          <div className={styles.Transfers_contentContainer}>
            <LoadingOverlay show={this.props.loading}>
              {this.renderStepperContent()}
            </LoadingOverlay>
          </div>
          <div className={styles.Transfer_stepperButtons}>
            {this.renderStepperButtons()}
          </div>
        </Card>
      </div>
    );
  }
}

export default TransferUI;
