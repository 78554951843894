import {
  GETrequest,
  POSTrequest,
  PUTrequest,
  DELETErequest
} from 'utils/helpers/api_handler';

import {
  ENTITY_GET,
  ADDRESS_CREATE,
  ADDRESS_UPDATE,
  ADDRESS_DELETE
} from './constants';

export function getEntity() {
  const response = GETrequest('/foundation/taxableentity');
  return {
    type: ENTITY_GET,
    payload: response
  };
}

export function createAddress(acctId, params) {
  const response = POSTrequest(`/accounts/${acctId}/agentaddress`, params);
  return {
    type: ADDRESS_CREATE,
    payload: response
  };
}

export function updateAddress(acctId, params) {
  const response = PUTrequest(`/accounts/${acctId}/agentaddress`, params);
  return {
    type: ADDRESS_UPDATE,
    payload: response
  };
}

export function deleteAddress(acctId, addressId) {
  const response = DELETErequest(`/accounts/${acctId}/agentaddress/${addressId}`);
  return {
    type: ADDRESS_DELETE,
    payload: response
  };
}
