import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  notificationShow,
  LoadingOverlay,
} from '@frontend/common';

import { getReports } from 'components/Features/protected/Reports/actions';

import ReportCard from './ReportCard';

import styles from './styles.module.css';

const select = (state) => ({
  reportList: state.reports.reportList,
});

export class ReportsList extends React.Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    getReports: PropTypes.func.isRequired,
    reportList: PropTypes.array.isRequired
  };

  state = {
    reports1000: [],
    reports2000: [],
    reports3000: [],
    reportsCustom: [],
    loading: true,
  };

  cardColumnsCompose = () => {
    const report1000Cards = this.reportCardsCompose(this.state.reports1000);
    const report2000Cards = this.reportCardsCompose(this.state.reports2000);
    const report3000Cards = this.reportCardsCompose(this.state.reports3000);
    const reportsCustomCards = this.reportCardsCompose(this.state.reportsCustom);

    return (
      <div className={styles.Reports_cardsColumns}>
        <div className={styles.Reports_column}>
          <span className={styles.Reports_columnHeader}>Accounts</span>
          {report1000Cards}
        </div>
        <div className={styles.Reports_column}>
          <span className={styles.Reports_columnHeader}>Transactions</span>
          {report2000Cards}
        </div>
        <div className={styles.Reports_column}>
          <span className={styles.Reports_columnHeader}>Contributions</span>
          {report3000Cards}
        </div>
        {reportsCustomCards.length > 0 && (
          <div className={styles.Reports_column}>
            <span className={styles.Reports_columnHeader}>Custom</span>
            {reportsCustomCards}
          </div>
        )}
      </div>
    );
  }

  reportCardsCompose(cards) {
    return cards.map(report => {
      return (
        <div key={report.ReportId}>
          <ReportCard report={report} />
        </div>
      );
    });
  }

  componentDidMount() {
    this.props.getReports()
      .then(() => {
        const { reportList } = this.props;
        const parseReportNumber = reportNumber => reportNumber && reportNumber.includes('CSA') && parseInt(reportNumber.split('CSA ')[1]);

        this.setState({
          reports1000: reportList.filter(report => {
            const reportNumber = parseReportNumber(report.InternalReportNumber);
            return reportNumber >= 1000 && reportNumber < 2000;
          }),
          reports2000: reportList.filter(report => {
            const reportNumber = parseReportNumber(report.InternalReportNumber);
            return reportNumber >= 2000 && reportNumber < 3000;
          }),
          reports3000: reportList.filter(report => parseReportNumber(report.InternalReportNumber) >= 3000),
          reportsCustom: reportList.filter(report => !parseReportNumber(report.InternalReportNumber)),
          loading: false,
        });
      })
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    return (
      <div className={styles.Reports_cardsContainer}>
        {this.state.loading
          ? <LoadingOverlay show width='100%' indicatorHeight='15px' />
          : this.cardColumnsCompose()
        }
      </div>
    );
  }
}

export default connect(select, { notificationShow, getReports })(ReportsList);
