import React from 'react';
import PropTypes from 'prop-types';

import {
  emailValidate,
  Modal,
  Checkbox,
} from '@frontend/common';
import { TextField } from '@mui/material';


export class AddInterestedPartyModal extends React.Component {

  static propTypes = {
    show: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    canApproveWithdrawals: PropTypes.bool,
  };

  state = {
    email: '',
    emailConfirm: '',
    emailError: '',
    emailConfirmError: '',
    ableToRequestScholarshipWithdrawal: false,
    formIsValid: false,
  };

  addClickHandle = () => {
    this.props.onAdd(this.state.email, this.state.emailConfirm, this.state.ableToRequestScholarshipWithdrawal);
  }

  formValidate = () => {
    let isValid = false;
    let emailError = '';
    let emailConfirmError = '';

    if (
      emailValidate(this.state.email)
      && emailValidate(this.state.emailConfirm)
      && this.state.email === this.state.emailConfirm
    ) {
      isValid = true;
    }
    else {
      if (this.state.email && !emailValidate(this.state.email)) {
        emailError = 'Must be a valid email address.';
      }

      if (this.state.emailConfirm && this.state.email !== this.state.emailConfirm) {
        emailConfirmError = 'Emails must match.';
      }
    }

    this.setState({
      formIsValid: isValid,
      emailError,
      emailConfirmError
    });
  }

  onEmailChange = (field, email) => {
    this.setState({
      [field]: email,
    }, () => {
      this.formValidate();
    });
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    if (this.state.formIsValid) {
      this.addClickHandle();
    }
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props;
    if (!show && show !== prevProps.show) {
      this.setState({
        email: '',
        emailConfirm: '',
        emailError: '',
        emailConfirmError: '',
        ableToRequestScholarshipWithdrawal: false,
        formIsValid: false,
      });
    }
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        title='Add Interested Party'
        onCloseModal={this.props.onModalClose}
        actionButtons={[
          {
            label: 'Cancel',
            disabled: this.props.loading,
            action: this.props.onModalClose,
          },
          {
            label: 'Add',
            action: this.addClickHandle,
            disabled: !this.state.formIsValid,
            loading: this.props.loading,
            buttonType: 'contained',
          }
        ]}
      >
        <form onSubmit={this.onFormSubmit}>
          <TextField
            name='email'
            label='Email'
            value={this.state.email}
            onChange={(e) => this.onEmailChange('email', e.target.value)}
            error={Boolean(this.state.emailError)}
            helperText={this.state.emailError}
            fullWidth
            autoFocus
            inputProps={{ 'data-testid': 'auto-AddInterestedPartyModal-emailChange-input' }}
          />
          <TextField
            name='emailConfirm'
            label='Confirm Email'
            value={this.state.emailConfirm}
            onChange={(e) => this.onEmailChange('emailConfirm', e.target.value)}
            error={Boolean(this.state.emailConfirmError)}
            helperText={this.state.emailConfirmError}
            fullWidth
            inputProps={{ 'data-testid': 'auto-AddInterestedPartyModal-emailConfirm-input' }}
          />
          {this.props.canApproveWithdrawals &&
            <Checkbox
              style={{ marginTop: '20px' }}
              checked={this.state.ableToRequestScholarshipWithdrawal}
              label='Should this interested party be able to request a withdrawal from the scholarship account?'
              onChange={event => this.setState({ ableToRequestScholarshipWithdrawal: event.target.checked })}
            />
          }
        </form>
      </Modal>
    );
  }
}

export default AddInterestedPartyModal;