/**
 * timer_real_time
 * 
 * It looks like JS setTimer is paused in inactive tab hence the need 
 * to build a homemade one based on setTimeout (this seems to work ok) and real time
 * 
 *  testing
  
start(
  () => console.log('tick at: ', new Date().toISOString()), // callback to execute every second
  () => console.log('finished at: ', new Date().toISOString()), // callback to execute when timeout
  60000, // duration in ms
);
stop();
 */

const CLOCK_INTERVAL_IN_MS = 1000; // hardcoded 1 sec tick interval

let cbTick, cbFinish;
let dur = 0; // timeout duration
let elapsedTime = 0;
let currentTimer = null;
let startedAt = null;

export function startTimer(callbackTick, callbackFinish, duration) {
  dur = duration; 
  cbTick = callbackTick;
  cbFinish = callbackFinish;
  startedAt = Date.now();
  update();
}

export function stopTimer() {
  clearTimeout(currentTimer);
}

export function getRemainingTimeInMs() {
  return dur - elapsedTime;
}

function update() {
  clearTimeout(currentTimer);
  elapsedTime = Date.now() - startedAt;
  cbTick(); // call this function every 1 sec

  if (elapsedTime >= dur) {    
    cbFinish(); // finished - execute callback
  }
  else {
    currentTimer = setTimeout(update, CLOCK_INTERVAL_IN_MS); 
  }
}