import { POSTrequest } from 'utils/helpers/api_handler';
import {
  PASSWORD_FORGOT
} from './constants';


export function forgotPassword(params) {
  const response = POSTrequest('/authentication/forgotpassword', params);
  return {
    type: PASSWORD_FORGOT,
    payload: response
  };
}