/*
*
* Transfers Actions
*
*/
import {
  TRANSFER_CREATE,
  TRANSFER_EDIT,
  TRANSFER_GET
} from './constants';
import {
  GETrequest,
  POSTrequest,
  PUTrequest
} from 'utils/helpers/api_handler';

export function createTransfer(transfer) {
  const response = POSTrequest('/transactions/transfers', transfer);
  return {
    type: TRANSFER_CREATE,
    payload: response,
  };
}

export function updateTransfer(id, transfer) {
  const response = PUTrequest(`/transactions/transfers/${id}`, transfer);
  return {
    type: TRANSFER_EDIT,
    payload: response,
  };
}

export function getTransfer(id) {
  const response = GETrequest(`/transactions/transfers/${id}`);
  return {
    type: TRANSFER_GET,
    payload: response,
  };
}
