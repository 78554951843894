import axios from 'axios';
import { store } from 'src/index';

const frontendConfig = window.frontendConfig;

function configureRequest(apiPath) {
  const token = store.getState().session.token;
  axios.defaults.headers.common.Authorization = token || 'no~auth';
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  return frontendConfig.api + apiPath;
}

export function GETrequest(apiPath, config = {}) {
  const endPoint = configureRequest(apiPath);
  return axios.get(endPoint, config)
    .then(res => res)
    .catch(err => err.response);
}

export function POSTrequest(apiPath, data, config = {}) {
  const endPoint = configureRequest(apiPath);
  return axios.post(endPoint, data, config)
    .then(res => res)
    .catch(err => err.response);
}

export function PUTrequest(apiPath, data, config = {}) {
  const endPoint = configureRequest(apiPath);
  return axios.put(endPoint, data, config)
    .then(res => res)
    .catch(err => err.response);
}

export function DELETErequest(apiPath, config = {}) {
  const endPoint = configureRequest(apiPath);
  return axios.delete(endPoint, config)
    .then(res => res)
    .catch(err => err.response);
}