import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { 
  DataTable,
  dateTimeStringSplit,
  properCase
} from '@frontend/common';

import styles from './styles.module.css';


export class ReportDataTable extends React.Component {

  static propTypes = {
    columnNames: PropTypes.array.isRequired,
    columnTypes: PropTypes.array.isRequired,
    data: PropTypes.arrayOf(PropTypes.array).isRequired,
    noDataReturned: PropTypes.bool.isRequired,
    onDownloadClick: PropTypes.func.isRequired,
    downloadLoading: PropTypes.bool,
  };

  state = {
    reportServiceData: [],
    tableDataMapping: {
      id: 'id',
      headers: {}
    },
    tableData: [],
  };

  dataMap = (dataArray, columns, columnTypes) => {
    const mappedData = [];

    dataArray.forEach(data => {
      const dataObject = {
        id: uuidv4(),
      };

      columns.forEach((column, index) => {
        const value = data[index];

        if (value && columnTypes[index] === 'DateTime') {
          const dateString = value.split(' ')[0];
          dataObject[column] = dateTimeStringSplit(dateString);
        }
        else if (value && (columnTypes[index] === 'Int32' || columnTypes[index] === 'Decimal')) {
          dataObject[column] = parseFloat(value);
        }
        else {
          dataObject[column] = value;
        }
      });

      mappedData.push(dataObject);
    });

    this.setState({
      tableData: mappedData,
    });
  }

  tableDataMappingSet = (columns) => {
    this.setState({
      tableDataMapping: {
        ...this.state.tableDataMapping,
        headers: columns.reduce((result, value) => ({ ...result, [properCase(value)]: value }), {})
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.columnNames, this.props.columnNames)) {
      this.tableDataMappingSet(this.props.columnNames);
    }

    if (!isEqual(prevProps.data, this.props.data)) {
      this.dataMap(this.props.data, this.props.columnNames, this.props.columnTypes);
    }
  }

  render() {
    let table = null;
    if (!this.props.noDataReturned && this.state.tableData.length > 0) {
      table = (
        <DataTable
          tableData={this.state.tableData}
          tableDataMapping={this.state.tableDataMapping}
          showDownload={true}
          onDownloadClick={this.props.onDownloadClick}
          downloadLoading={this.props.downloadLoading}
        />
      );
    }
    else if (this.props.noDataReturned && this.state.tableData.length < 1) {
      table = (
        <div className={styles.ReportDataTable_noDataMessage}>
          <div>There is no data for the selected criteria.</div>
          <div>Please try again.</div>
        </div>
      );
    }

    return table;
  }
}

export default ReportDataTable;
