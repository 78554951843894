import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  ConfirmModal,
  notificationShow,
  LoadingOverlay,
} from '@frontend/common';

import { deleteTag } from '../actions';

import styles from './styles.module.css';


export class DeleteTagsModal extends React.Component {

  static propTypes = {
    loadingOnMount: PropTypes.bool.isRequired,
    notificationShow: PropTypes.func.isRequired,
    deleteTag: PropTypes.func.isRequired,
    tagSelected: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };

  state = {
    loading: false,
  };

  bodyCompose = () => {
    /* eslint-disable indent */
    const { loadingOnMount, tagSelected } = this.props;
    return (
      <div className={styles.DeleteTag_body}>
        {loadingOnMount
          ? <LoadingOverlay
              show
              width='100%'
              height='10px'
            />
          : `${tagSelected.accountsAffected.length} accounts will be affected. Are you sure you want to delete tag '${tagSelected.name}'?`
        }
      </div>
    );
    /* eslint-enable indent */
  }

  tagDelete() {
    this.setState({ loading: true });
    this.props.deleteTag(this.props.tagSelected.id)
      .then(() => {
        this.props.notificationShow('Tag deleted.', 'success');
        this.props.onClose();
      })
      .catch(() => null)
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    return (
      <ConfirmModal
        body={this.bodyCompose()}
        isLoading= {this.state.loading || this.props.loadingOnMount}
        onModalClose={this.props.onClose}
        onConfirm={() => this.tagDelete()}
        show={this.props.open}
        title='Delete Tag?'
      />
    );
  }
}

export default connect(null, { deleteTag, notificationShow })(DeleteTagsModal);
