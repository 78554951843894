import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Paper } from '@mui/material';

import styles from './styles.module.css';


export class ReportCard extends React.Component {

  static propTypes = {
    report: PropTypes.object.isRequired,
  };

  state = {
    focused: false,
  };

  render() {
    const { report } = this.props;

    return (
      <Paper
        elevation={this.state.focused ? 3 : 1}
        style={{
          width: '400px',
          margin: '0 10px 20px 10px',
          border: 'none',
          borderLeft: '5px solid var(--accent)',
          cursor: 'pointer',
        }}
        onClick={() => this.props.history.push(`/report/${report.ReportId}`)}
        onMouseEnter={() => this.setState({ focused: true })}
        onMouseLeave={() => this.setState({ focused: false })}
        data-testid={`auto-ReportCard-reportId${report.ReportId}`}
      >
        <div className={styles.ReportCard_title}>
          {report.DisplayName}
        </div>
        <div className={styles.ReportCard_body}>
          <small>
            {report.InternalReportNumber && (
              <div>
                <strong>Report Number: </strong>
                <span>{report.InternalReportNumber}</span>
              </div>
            )}
            {report.Description && (
              <div>
                <strong>Description: </strong>
                <span>{report.Description}</span>
              </div>
            )}
          </small>
        </div>
      </Paper>
    );
  }
}

export default withRouter(ReportCard);
