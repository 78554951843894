/*
*
* Email Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  TextField,
  Button,
} from '@mui/material';

import {
  notificationShow,
  Modal
} from '@frontend/common';

import { getEmailError } from 'utils/helpers/formValidators';

import { changeEmail, userLogout, clearStore } from 'components/AppRoot/Navigation/actions';

import styles from '../styles.module.css';

const select = (state) => ({
  userDetails: state.session.userDetails,
  token: state.session.token,
});

export class Email extends React.Component {
  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    changeEmail: PropTypes.func.isRequired,
    userLogout: PropTypes.func.isRequired,
    token: PropTypes.string,
    clearStore: PropTypes.func.isRequired,
  };

  state = {
    newEmail: '',
    confirmEmail: '',
    newEmailError: '',
    confirmEmailError: '',
    submitting: false,
    isSubmitBtnDisabled: true,
    showModal: false,
  }

  onEmailChange = (keyName, value) => {
    // also reset username errors
    this.setState({ [keyName]: value }, () => this.emailInputValidate());
  }

  emailInputValidate = () => {
    const { userDetails: { EmailAddress } } = this.props;
    const { newEmail, confirmEmail } = this.state;

    let newEmailError = '';
    let confirmEmailError = '';

    if (newEmail) {
      newEmailError = getEmailError(newEmail);
    }

    if (confirmEmail) {
      confirmEmailError = getEmailError(confirmEmail);
    }

    if (EmailAddress.toLowerCase() === newEmail.toLowerCase()) {
      newEmailError = 'Email address is same as current.';
    }

    if (newEmail.toLowerCase() !== confirmEmail.toLowerCase()) {
      confirmEmailError = 'Emails must match.';
    }

    const hasError = Boolean(newEmailError) || Boolean(confirmEmailError);
    const isSubmitBtnDisabled = !newEmail || !confirmEmail || hasError;
    this.setState({ newEmailError, confirmEmailError, isSubmitBtnDisabled });
  }

  onEmailSubmit = () => {
    const { newEmail } = this.state;

    const emailBody = { Email: newEmail };
    this.setState({ submitting: true });
    this.props.changeEmail(emailBody)
      .then(() => {
        this.logOut();
      })
      .catch(() => this.setState({ submitting: false }));
  }

  onModalClose = () => {
    // close modal and reset the form
    this.setState({
      showModal: false,
      newEmail: '',
      confirmEmail: '',
      isSubmitBtnDisabled: true,
    });
  }

  logOut = () => {
    const { token } = this.props;
    this.props.clearStore();
    this.props.userLogout({ token })
      .then(() => this.props.notificationShow('You have logged out.', 'success'))
      .catch(() => null);
  }

  render() {
    const { userDetails: { EmailAddress } } = this.props;
    const {
      newEmail, confirmEmail, submitting, showModal,
      newEmailError, confirmEmailError, isSubmitBtnDisabled
    } = this.state;

    return (
      <>
        <form
          className={styles.myInfo_right_container}
          onSubmit={(e) => {
            e.preventDefault();
            this.setState({ showModal: true });
          }}
        >

          <div className={styles.myInfo_textInput}>
            <TextField
              label='Current Email'
              name='currentEmailAddress'
              value={EmailAddress}
              disabled={true}
              fullWidth
              inputProps={{ 'data-testid': 'auto-MyInfo-Email-currentEmail-input' }}
            />
          </div>

          <div className={styles.myInfo_textInput}>
            <TextField
              autoComplete='new-password' // the only way to disable autocomplete
              label='New Email'
              name='newEmailAddress'
              value={newEmail}
              onChange={(e) => this.onEmailChange('newEmail', e.target.value)}
              inputProps={{ 'data-testid': 'auto-MyInfo-Email-newEmail-input' }}
              error={Boolean(newEmailError)}
              helperText={newEmailError}
              disabled={submitting}
              fullWidth
            />
          </div>

          <div className={styles.myInfo_textInput}>
            <TextField
              autoComplete='new-password' // the only way to disable autocomplete
              label='Confirm Email'
              name='confirmEmailAddress'
              value={confirmEmail}
              onChange={(e) => this.onEmailChange('confirmEmail', e.target.value)}
              inputProps={{ 'data-testid': 'auto-MyInfo-Email-confirmEmail-input' }}
              error={Boolean(confirmEmailError)}
              helperText={confirmEmailError}
              disabled={submitting}
              fullWidth
            />
          </div>

          <div className={styles.myInfo_button}>
            <Button
              type='submit'
              variant='contained'
              disabled={isSubmitBtnDisabled}
              data-testid='auto-MyInfo-Email-submit-button'
            >
              Change Email
            </Button>
          </div>
        </form>

        <Modal
          actionButtons={[
            {
              label: 'Cancel',
              action: this.onModalClose,
              disabled: submitting,
            },
            {
              action: this.onEmailSubmit,
              label: 'Change your email and log out',
              disabled: submitting,
              loading: submitting
            }
          ]}
          onCloseModal={this.onModalClose}
          show={showModal}
          title='Change your email'
        >
          <div>
            Changed Email (Username) activation link will be sent to <a href={`mailto:${newEmail}`}>{newEmail}</a>. Please go to your email inbox and click on the activation link. You will be able to login with your old email until you activate your new email address.
          </div>
        </Modal>
      </>

    );
  }
}

export default connect(select, {
  changeEmail,
  userLogout,
  clearStore,
  notificationShow
})(Email);
