import { POSTrequest, GETrequest, PUTrequest } from 'utils/helpers/api_handler';
import {
  USER_AUTHENTICATE_REQUEST,
  USER_INFO_GET,
  USER_INFO_UPDATE,
  EMAIL_CHANGE,
  INITIALIZE_STORE,
  BROWSER_WARNING,
  MOBILE_WARNING,
  TOGGLE_SPLASH_HAS_DISPLAYED,
  RESET_CLAIMS,
} from './constants';

////////////////////////////////// LOGIN //////////////////////////////////
export function userLogin(params) {
  const response = POSTrequest('/authentication/login', params);
  return {
    type: USER_AUTHENTICATE_REQUEST,
    payload: response
  };
}

////////////////////////////////// LOGOUT //////////////////////////////////
export function userLogout(params) {
  const response = POSTrequest('/authentication/logout', params);
  return {
    type: INITIALIZE_STORE,
    payload: response
  };
}

////////////////////////////////// USER INFO //////////////////////////////////
export function getUserInfo() {
  const response = GETrequest('/Users');
  return {
    type: USER_INFO_GET,
    payload: response
  };
}

export function updateUserInfo(body) {
  const response = PUTrequest('/Users', body);
  return {
    type: USER_INFO_UPDATE,
    payload: response
  };
}

export function changeEmail(body) {
  const response = POSTrequest('/authentication/ChangeEmail', body);
  return {
    type: EMAIL_CHANGE,
    payload: response
  };
}

////////////////////////////////// SESSION MGMT //////////////////////////////////
export function setBrowserWarning() {
  return {
    type: BROWSER_WARNING,
  };
}

export function setMobileWarning() {
  return {
    type: MOBILE_WARNING,
  };
}

export function clearStore() {
  return {
    type: INITIALIZE_STORE
  };
}

export function toggleSplashHasDisplayed() {
  return {
    type: TOGGLE_SPLASH_HAS_DISPLAYED,
  };
}

export function resetClaims() {
  const response = GETrequest('/authentication/claims');
  return {
    type: RESET_CLAIMS,
    payload: response,
  };
}