import {
  GETrequest,
  POSTrequest
} from 'utils/helpers/api_handler';

import {
  WITHDRAWAL_PENDING,
  WITHDRAWAL_HISTORY,
  WITHDRAWAL_APPROVE,
  WITHDRAWAL_REJECT,
  WITHDRAWAL_SAVE_ADDRESS
} from './constants';

export function getPending() {
  const response = GETrequest('/withdrawals/pending');
  return {
    type: WITHDRAWAL_PENDING,
    payload: response
  };
}

export function getHistory() {
  const response = GETrequest('/withdrawals/history');
  return {
    type: WITHDRAWAL_HISTORY,
    payload: response
  };
}

export function witdrawalApprove(id) {
  const response = POSTrequest(`/withdrawals/${id}/Approve`);
  return {
    type: WITHDRAWAL_APPROVE,
    payload: response
  };
}

export function witdrawalReject(id, params) {
  const response = POSTrequest(`/withdrawals/${id}/Reject`, params);
  return {
    type: WITHDRAWAL_REJECT,
    payload: response
  };
}

export function witdrawalSaveAddress(id, params) {
  const response = POSTrequest(`/withdrawals/${id}/Address`, params);
  return {
    type: WITHDRAWAL_SAVE_ADDRESS,
    payload: response
  };
}