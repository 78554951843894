import { POSTrequest } from 'utils/helpers/api_handler';
import {
  PASSWORD_RESET
} from './constants';


export function resetPassword(params) {
  const response = POSTrequest('/authentication/resetpassword', params);
  return {
    type: PASSWORD_RESET,
    payload: response
  };
}
