/*
*
* TransferDetails Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
} from '@mui/material';
import {
  CardTitle,
  IconBtnTooltip,
  TableDataTypes,
  TableColumnHideOptions,
  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';

import styles from './styles.module.css';

export class TransferDetails extends React.Component {

  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    transfer: PropTypes.arrayOf(
      PropTypes.shape({
        AccountNumber: PropTypes.string,
        BeneficiaryName: PropTypes.string,
        AccountType: PropTypes.string,
        Amount: PropTypes.number,
        Milestone: PropTypes.string
      })
    ).isRequired,
  };


  render() {
    const { open, onClose, transfer, } = this.props;

    return (
      <Drawer
        anchor='right'
        open={open}
        onClose={onClose}
        data-testid='auto-TransferDetails-drawerClose'
      >
        <div
          tabIndex={0}
          style={{ width: 'auto' }}
          className={styles.TransferDetails_container}
        >
          <div className={styles.TransferDetails_navigation}>
            <div className={styles.TransferDetails_title}>
              <CardTitle>
                Transfer Details
              </CardTitle>
            </div>
            <div className={styles.TransferDetails_closeIcon}>
              <IconBtnTooltip
                icon='close'
                onClick={onClose}
                buttonProps={{
                  'data-testid': 'auto-TrasferDetails-drawerClose-button'
                }}
              />
            </div>
          </div>
          <SmartTable
            idKey='AccountNumber'
            rows={transfer}
            columns={[
              {
                key: 'AccountNumber',
                title: 'Account Number',
                type: TableDataTypes.STRING,
              },
              {
                key: 'BeneficiaryName',
                title: 'Beneficiary',
                type: TableDataTypes.STRING,
                hideOn: [TableColumnHideOptions.PHONE, TableColumnHideOptions.TABLET],
              },
              {
                key: 'AccountType',
                title: 'Type',
                type: TableDataTypes.STRING,
                hideOn: [TableColumnHideOptions.PHONE, TableColumnHideOptions.TABLET],
              },
              {
                key: 'Amount',
                title: 'Amount',
                type: TableDataTypes.CURRENCY,
              },
              {
                key: 'Milestone',
                title: 'Milestone',
                type: TableDataTypes.STRING,
              },
            ]}
          >
            <TableToolbar />
            <TableContainer maxHeight='100%' minWidth='100%'>
              <TableHeader />
              <TableRows />
            </TableContainer>
            <TablePagination />
          </SmartTable>
        </div>
      </Drawer >
    );
  }
}

export default TransferDetails;
