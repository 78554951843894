import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { debounce } from 'lodash';

import { Paper } from '@mui/material';

import { 
  Chip,
  FloatingActionButton,
  SearchIcon,
  notificationShow,
  filterBy,
  LoadingOverlay,
} from '@frontend/common';

import { getTag, getTags } from 'components/Features/protected/ManageTags/actions';

import CreateTagsModal from './CreateTagsModal';
import DeleteTagsModal from './DeleteTagsModal';
import RenameTagsModal from './RenameTagsModal';
import ViewAccountsModal from './ViewAccountsModal';

import styles from './styles.module.css';

const select = (state) => ({
  tagList: state.tags.tagList,
  tag: state.tags.tag,
});

export class ManageTags extends React.Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    getTag: PropTypes.func.isRequired,
    getTags: PropTypes.func.isRequired,
    tag: PropTypes.array.isRequired,
    tagList: PropTypes.array,
  };

  state = {
    accountsViewModalOpen: false,
    accountsViewModalLoading: false,
    filteredResults: [],
    loading: false,
    searchTerm: '',
    tagSelected: {
      accountsAffected: [],
      id: 0,
      name: '',
    },
    tagsCreateModalOpen: false,
    tagsDeleteModalOpen: false,
    tagsDeleteModalLoading: false,
    tagsRenameModalLoading: false,
    tagsRenameModalOpen: false,
  };

  onSearch(term) {
    const { tagList } = this.props;
    this.setState({
      filteredResults: term === '' ? tagList : filterBy(term, tagList, ['Tag']),
      searchTerm: term,
    });
  }

  contentRender() {
    if (this.state.loading) {
      return (
        <div className={styles.ManageTags_loadingContainer}>
          <LoadingOverlay show width='100%' indicatorHeight='15px' />
        </div>
      );
    }
    else if (this.props.tagList.length === 0) {
      return (
        <h1 className={styles.ManageTags_emptyMessage}>
          No existing tags to display. Click the + button to create some.
        </h1>
      );
    }
    else if (this.state.filteredResults.length === 0) {
      return (
        <h1 className={styles.ManageTags_emptyMessage}>
          No tags to display.
        </h1>
      );
    }
    else {
      return this.tagsCompose();
    }
  }

  tagsCompose() {
    return this.state.filteredResults.map(tag => {
      return (
        <div className={styles.ManageTags_tag} key={tag.TaxableEntityTagId}>
          <Chip
            actions={[
              {
                icon: 'visibility',
                tooltip: 'View Accounts',
                action: () => this.tagSelectedGet(tag, 'accountsViewModal'),
              },{
                icon: 'mode_edit',
                tooltip: 'Rename',
                action: () => this.tagSelectedGet(tag, 'tagsRenameModal'),
              },{
                icon: 'close',
                tooltip: 'Delete',
                action: () => this.tagSelectedGet(tag, 'tagsDeleteModal'),
              }
            ]}
            name={tag.Tag}
          />
        </div>
      );
    });
  }

  tagSelectedGet(tag, modalName) {
    const modalToLoad = `${modalName}Loading`;
    const modalToOpen = `${modalName}Open`;

    this.setState({
      [modalToLoad]: true,
      [modalToOpen]: true,
    });

    this.props.getTag(tag.TaxableEntityTagId)
      .then(() => {
        this.setState({
          tagSelected: {
            accountsAffected: this.props.tag,
            id: tag.TaxableEntityTagId,
            name: tag.Tag,
          },
        });
      })
      .catch(() => null)
      .finally(() => this.setState({ [modalToLoad]: false }));
  }

  tagsGet() { 
    this.setState({ loading: true });
    this.props.getTags()
      .then(() => {
        this.setState({
          filteredResults: filterBy(this.state.searchTerm, this.props.tagList, ['Tag'])
        });
      })
      .catch(() => null)
      .finally(() => this.setState({ loading: false }));
  }

  handleCloseModals(modal) {
    this.setState({ 
      tagToCreate: '',
      tagsToCreate: [],
      [modal]: false,
    });
    this.tagsGet();
  }

  componentDidMount() {
    this.tagsGet();
  }

  render() {
    const debouncedSearchSubmitHandle = debounce((term) => this.onSearch(term), 300);

    return (
      <div className={styles.ManageTags_manageTagsContainer}>
        <Paper
          elevation={1}
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            margin: '10px',
            height: '100%',
          }}
        >
          <div className={styles.ManageTags_searchContainer}>
            <SearchIcon onSearchSubmit={debouncedSearchSubmitHandle} showSearchTips={false} />
          </div>
          <div style={{ width: '100%' }}>
            <div className={this.state.filteredResults.length > 5 ? styles.ManageTags_tagContainer : styles.ManageTags_tagContainerSmall}>
              { this.contentRender() }
            </div>
          </div>
        </Paper>

        <FloatingActionButton
          hasMenu={false}
          mainButtonAction={() => this.setState({ tagsCreateModalOpen: true })}
          mainButtonTitle='Create Tag'
          mainIcon='add'
        />

        <CreateTagsModal
          availableTags={this.props.tagList}
          onClose={() => this.handleCloseModals('tagsCreateModalOpen')}
          open={this.state.tagsCreateModalOpen}
        />

        <DeleteTagsModal
          loadingOnMount={this.state.tagsDeleteModalLoading}
          onClose={() => this.handleCloseModals('tagsDeleteModalOpen')}
          open={this.state.tagsDeleteModalOpen}
          tagSelected={this.state.tagSelected}
        />

        <RenameTagsModal
          availableTags={this.props.tagList}
          loadingOnMount={this.state.tagsRenameModalLoading}
          onClose={() => this.handleCloseModals('tagsRenameModalOpen')}
          open={this.state.tagsRenameModalOpen}
          tagSelected={this.state.tagSelected}
          tagsGet={() => this.tagsGet()}
        />

        <ViewAccountsModal
          loadingOnMount={this.state.accountsViewModalLoading}
          onClose={() => this.handleCloseModals('accountsViewModalOpen')}
          open={this.state.accountsViewModalOpen}
          tagSelected={this.state.tagSelected}
        />
      </div>
    );
  }
}

export default withRouter(connect(select, { getTag, getTags, notificationShow })(ManageTags));
