import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  Paper,
  Icon,
} from '@mui/material';

import {
  LoadingOverlay,
  properCase,
  AutoComplete,
} from '@frontend/common';

import { getAccounts } from 'components/Features/protected/Accounts/actions';

import styles from './styles.module.css';

const select = (state) => ({
  accountsForAutoComplete: state.accounts.accountList.map(account => ({
    value: account.AccountId,
    display: `${properCase(account.BeneficiaryName)} (${account.AccountNumber})`,
  })),
});


export class AccountsSearchBar extends React.Component {

  static propTypes = {
    getAccounts: PropTypes.func.isRequired,
    accountsForAutoComplete: PropTypes.array.isRequired,
  };

  state = {
    loading: false,
  };

  componentDidMount() {
    if (this.props.accountsForAutoComplete.length === 0) {
      this.setState({ loading: true });
      this.props.getAccounts()
        .catch(() => null)
        .finally(() => this.setState({ loading: false }));
    }
  }

  render() {
    return (
      <Paper elevation={1}>
        <LoadingOverlay show={this.state.loading}>
          <div className={styles.AccountsSearchBar_container}>
            <div className={styles.AccountsSearchBar_searchIcon}>
              <Icon style={{ fontSize: '32px' }}>search</Icon>
            </div>
            <div className={styles.AccountsSearchBar_searchInputContainer}>
              <AutoComplete
                onSelect={selection => this.props.history.push(`/accounts/${selection.value}`)}
                options={this.props.accountsForAutoComplete}
                TextFieldProps={{
                  placeholder: 'Jump to account...',
                }}
              />
            </div>
          </div>
        </LoadingOverlay>
      </Paper>
    );
  }
}

export default withRouter(connect(select, { getAccounts })(AccountsSearchBar));
