import { cloneDeep } from 'lodash';
import {
  TAG_GET,
  TAGS_GET,
  TAG_CREATE,
} from './constants';

const initialState = {
  tagList: [],
  tag: [],
};

function TagReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case TAG_GET: {
      newState.tag = action.payload.data;
      return newState;
    }

    case TAGS_GET: {
      newState.tagList = action.payload.data;
      return newState;
    }

    case TAG_CREATE: {
      newState.tagList.push(action.payload.data[0]);
      return newState;
    }

    default:
      return state;
  }
  
}

export default TagReducer;