// import { cloneDeep } from 'lodash';

const initialState = {};

function ChangePasswordReducer(state = initialState, action = {}) {

  switch (action.type) {
    default: {
      return state;
    }
  }
}

export default ChangePasswordReducer;
