import { cloneDeep } from 'lodash';
import {
  ENTITY_GET,
} from './constants';

const initialState = {
  programList: [],
  taxableEntityId: -1
};

function DashboardReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case ENTITY_GET: { // GET PROGRAMS
      newState.programList = action.payload.data.Programs;
      newState.taxableEntityId = action.payload.data.TaxableEntityId;
      return newState;
    }

    default:
      return state;
  }

}

export default DashboardReducer;