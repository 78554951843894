import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GifAuthy from '../../GifAuthy.gif';

import { Button } from '@mui/material';

import {
  notificationShow,
  allNotificationsHide,
  LoadingOverlay,
} from '@frontend/common';

import {
  getTwoFactorPushVerification,
  getTwoFactorPush
} from '../../actions';

import styles from '../../styles.module.css';

let pushVerificationTimeout;

const select = () => ({});

export class OneTouch extends Component {

  static propTypes = {
    getTwoFactorPushVerification: PropTypes.func.isRequired,
    getTwoFactorPush: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    onVerificationSuccess: PropTypes.func.isRequired,
  };

  state = {
    isPushing: false,
    isPending: true,
  };

  sendNewPush() {
    // remove all notification toasts
    this.props.allNotificationsHide();
    this.setState({ isPushing: true });
    this.props.getTwoFactorPush()
      .then((response) => {
        const { Message, Sent } = response.payload.data;
        if (Sent) {
          this.setState({ isPushing: false });
          this.verifyPush();
        }
        else {
          this.setState({ isPushing: false });
          this.props.notificationShow(Message, 'error');
        }
      });
  }

  verifyPush() {
    this.setState({ isPending: true });
    this.props.getTwoFactorPushVerification()
      .then((response) => {
        const { Verified, Message } = response.payload.data;
        if (Verified) {
          this.props.onVerificationSuccess();
        }
        else if (!Verified && Message !== 'Pending') {
          this.setState({ isPending: false });
          this.props.notificationShow('Login Expired. Send New Push or Use Another Method.', 'warning');
        }
        else {
          pushVerificationTimeout = setTimeout(() => {
            this.verifyPush();
          }, 5000);
        }
      })
      .catch(() => {
        this.setState({ isPending: false });
      });
  }

  componentDidMount() {
    this.sendNewPush();
  }

  componentWillUnmount() {
    // remove all notification toasts
    this.props.allNotificationsHide();
    // remove the timer
    clearTimeout(pushVerificationTimeout);
  }

  render() {
    const { isPushing, isPending } = this.state;
    return (
      <div style={{ textAlign: 'center', width: '60%', margin: 'auto' }}>
        <h3>We pushed a login request to the device you have registered with Authy.</h3>
        <img src={GifAuthy} alt='Powered By Authy' /><br />
        <div className={styles.buttons}>
          <LoadingOverlay show={isPushing} width='100%'>
            <Button
              color='secondary'
              variant='text'
              style={{ marginTop: '20px' }}
              onClick={() => this.sendNewPush()}
              disabled={isPending}
              fullWidth
            >
              send new push
            </Button>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

export default connect(select, {
  getTwoFactorPushVerification,
  getTwoFactorPush,
  notificationShow,
  allNotificationsHide
})(OneTouch);