import React from 'react';
import PropTypes from 'prop-types';

import {
  Paper,
} from '@mui/material';

import {
  LoadingOverlay,
  IconBtnTooltip,
} from '@frontend/common';

import styles from './styles.module.css';


export class AccountDetailsCard extends React.Component {

  static propTypes = {
    containerStyle: PropTypes.object,
    canEdit: PropTypes.bool,
    isSaveEnabled: PropTypes.bool,
    onSave: PropTypes.func,
    editingChildren: PropTypes.element,
    displayChildren: PropTypes.element,
    label: PropTypes.string.isRequired,
    onRevert: PropTypes.func,
    onEdit: PropTypes.func,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    canEdit: false,
    loading: false,
  };

  state = {
    editing: false,
  };

  editingIconsCompose = () => {
    const { label } = this.props;
    const { editing } = this.state;

    if (this.props.canEdit) {
      return (
        <div>
          <div className={styles.AccountDetailsCard_iconContainer}>
            {!editing ? (
              <div
                className={styles.AccountDetailsCard_iconEnabled}
                onClick={this.editClickHandle}
                style={{ visibility: editing ? 'hidden' : 'visible' }}
                data-testid={`auto-AccountDetailsCard-edit-${label}-div`}
              >
                <IconBtnTooltip
                  title='Edit'
                  placement='top'
                  icon='edit'
                  onClick={() => null}
                />
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <div
                  className={this.props.isSaveEnabled ? styles.AccountDetailsCard_iconEnabled : styles.AccountDetailsCard_iconDisabled}
                  onClick={this.props.isSaveEnabled ? this.saveClickHandle : null}
                  data-testid={`auto-AccountDetailsCard-save-${label}-div`}
                >
                  <IconBtnTooltip
                    title='Save Changes'
                    placement='top'
                    buttonProps={{ disabled: !this.props.isSaveEnabled }}
                    onClick={() => null}
                    icon='save'
                  />
                </div>

                <div
                  className={styles.AccountDetailsCard_iconEnabled}
                  onClick={this.revertClickHandle}
                  data-testid={`auto-AccountDetailsCard-revert-${label}-div`}
                >
                  <IconBtnTooltip
                    title={this.props.isSaveEnabled ? 'Discard Changes' : 'Close'}
                    placement='top'
                    icon='close'
                    onClick={() => null}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
    else {
      return null;
    }
  }

  editClickHandle = () => {
    this.setState({
      editing: true,
    }, () => {
      this.props.onEdit();
    });
  }

  revertClickHandle = () => {
    this.setState({
      editing: false,
    }, () => {
      this.props.onRevert();
    });
  }

  saveClickHandle = () => {
    this.setState({
      editing: false,
    }, () => {
      this.props.onSave();
    });
  }

  stylesGet() {
    const styles = {
      container: {
        margin: '10px',
        minHeight: '150px',
        position: 'relative',
      },
    };

    styles.container = Object.assign({}, styles.container, this.props.containerStyle);

    return styles;
  }

  render() {
    const inlineStyles = this.stylesGet();

    return (
      <Paper
        elevation={1}
        style={inlineStyles.container}
      >
        <LoadingOverlay
          show={this.props.loading}
          width='100%'
          height='100%'
          indicatorHeight='15px'
        >
          {this.editingIconsCompose()}
          <div className={styles.AccountDetailsCard_body}>
            <div className={styles.AccountDetailsCard_label}>{this.props.label}</div>
            <div className={styles.AccountDetailsCard_content}>
              {this.state.editing
                ? this.props.editingChildren
                : this.props.displayChildren
              }
            </div>
          </div>
        </LoadingOverlay>
      </Paper>
    );
  }
}

export default AccountDetailsCard;
