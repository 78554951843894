import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SubmitToken from '../../Shared/SubmitToken';

import {
  Button
} from '@mui/material';

import {
  LoadingOverlay,
} from '@frontend/common';

import styles from '../../styles.module.css';

export class VerifyAuthy extends Component {

  static propTypes = {
    onVerificationSuccess: PropTypes.func.isRequired,
    onVerificationFail: PropTypes.func.isRequired,
    onOneTouchClick: PropTypes.func.isRequired,
    showInstructions: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
  };

  state = {
    isSubmitting: false,
    isRequestingPush: false,
  };

  render() {
    const { title, showInstructions, onOneTouchClick, onVerificationSuccess, onVerificationFail } = this.props;
    const { isSubmitting, isRequestingPush } = this.state;

    return (
      <div style={{ textAlign: 'center' }}>
        <h2>{title}</h2>

        {showInstructions &&
          <div className={styles.intructionSteps_container}>
            <div className={styles.intructionSteps_body}>Step 1: Download the Authy App</div>
            <div className={styles.intructionSteps_body} style={{ marginTop: '15px' }}>Step 2: Enter a security code from the Authy App or request a OneTouch approval.</div>
          </div>
        }

        <div className={styles.buttons}>
          <LoadingOverlay show={isRequestingPush} width='100%'>
            <Button
              variant='contained'
              style={{ marginTop: '20px' }}
              onClick={onOneTouchClick}
              fullWidth
              disabled={isSubmitting}
            >
              Request Authy OneTouch Approval
            </Button>
          </LoadingOverlay>
        </div>

        <span><h1>or</h1></span>

        <SubmitToken
          disabled={isRequestingPush}
          getIsSubmitting={isSubmitting => this.setState({ isSubmitting })}
          onVerificationSuccess={onVerificationSuccess}
          onVerificationFail={onVerificationFail}
        />

      </div >
    );
  }
}

export default VerifyAuthy;