import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Chip from '@mui/material/Chip';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutlineSharp';

const ALL_OPTION_VALUE = -1;

const checkedIcon = <CheckBoxIcon fontSize='small' />;
const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const renderChips = (selections, onRemove) => selections.map((option) => {
  const label = option.display || option;
  return (
    <Chip
      key={label}
      label={label}
      deleteIcon={<RemoveIcon />}
      onDelete={() => {
        onRemove(selections.filter(entry => entry.value !== option.value));
      }}
    />
  );
});

export class MultiSelectorCheckboxes extends Component {
  static propTypes = {
    selections: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.number.isRequired,
      display: PropTypes.string.isRequired
    })),
    defaultSelections: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.number.isRequired,
      display: PropTypes.string.isRequired
    })),
    defaultOptions: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.number.isRequired,
      display: PropTypes.string.isRequired
    })).isRequired,
    label: PropTypes.string,
    onCheckboxSelection: PropTypes.func.isRequired,
    errorText: PropTypes.string,
    inlineTags: PropTypes.bool,

  }

  static defaultProps = {
    selections: [],
    defaultSelections: [],
    errorText: '',
    inlineTags: true,
  }

  state = {
    options: [],
    selections: [],
    wasAllItemSelected: false
  }

  onCheckboxSelectionHandler = (newSelections) => {
    const { wasAllItemSelected } = this.state;
    const selectionsWithoutAllItem = newSelections.filter(selection => selection.value !== ALL_OPTION_VALUE);
    let selections = newSelections;
    let isAllItemSelected = selections.filter(selection => selection.value === ALL_OPTION_VALUE).length === 1;
    const areAllOptionsSelected = selectionsWithoutAllItem.length === this.props.defaultOptions.length;

    // on toggling "All" on from off
    if (!wasAllItemSelected && isAllItemSelected) {
      selections = [{ value: ALL_OPTION_VALUE, display: 'All' }, ...this.props.defaultOptions];
    }
    // on toggling "All" off from on
    else if (wasAllItemSelected && !isAllItemSelected) {
      selections = this.props.defaultSelections;
    }
    // when all options are selected
    else if (areAllOptionsSelected) {
      selections = [{ value: ALL_OPTION_VALUE, display: 'All' }, ...this.props.defaultOptions];
      isAllItemSelected = true;
    }
    // when not all options are selected
    else {
      selections = newSelections.filter(selection => selection.value !== ALL_OPTION_VALUE);
      isAllItemSelected = false;
    }

    this.props.onCheckboxSelection(selections.filter(selection => selection.value !== ALL_OPTION_VALUE));
    this.setState({ selections, wasAllItemSelected: isAllItemSelected });
  }

  componentDidMount() {
    this.setState({
      options: [{ value: ALL_OPTION_VALUE, display: 'All' }, ...this.props.defaultOptions],
    });
  }

  componentDidUpdate(prevProps) {
    const { selections, defaultSelections, defaultOptions } = this.props;
    if (!isEqual(prevProps.defaultSelections, defaultSelections)) {
      this.setState({ selections: defaultSelections });
    }

    if (!isEqual(prevProps.selections, selections)) {
      const areAllOptionsSelected = selections.length === defaultOptions.length;
      const updatedSelections = areAllOptionsSelected ? [{ value: ALL_OPTION_VALUE, display: 'All' }, ...this.props.defaultOptions] : selections;
      this.setState({ selections: updatedSelections });
    }
  }

  render() {
    const {
      label,
      inlineTags,
      errorText
    } = this.props;

    const {
      options,
      selections,
    } = this.state;

    return (
      <div style={{ width: '100%' }}>
        <Autocomplete
          multiple
          disableCloseOnSelect
          fullWidth
          {...(inlineTags ? {} : { renderTags: () => { } })} // conditional prop
          value={selections}
          options={options}
          getOptionLabel={(option) => option.display || option}
          isOptionEqualToValue={(option, value) => (option.display || option) === (value.display || value)}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.display || option}
            </li>
          )}
          onChange={(event, newSelections) => {
            this.onCheckboxSelectionHandler(newSelections);
          }}
          renderInput={(params) => (
            <TextField {...params} label={label} error={Boolean(errorText)} helperText={errorText} />
          )}
        />
        <div>{!inlineTags && renderChips(selections, this.onCheckboxSelectionHandler)}</div>
      </div>

    );
  }
}

export default MultiSelectorCheckboxes;