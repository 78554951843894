import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intersectionBy } from 'lodash';

import {
  Chip,
  Modal,
  notificationShow,
  SmartTable,
  TableDataTypes,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
} from '@frontend/common';

import { tagsUnassign } from 'components/Features/protected/ManageTags/actions';

import styles from './styles.module.css';

const select = (state) => ({
  accountList: state.dashboard.accountList,
});

export class UntagModal extends React.Component {

  static propTypes = {
    accountsGet: PropTypes.func.isRequired,
    accountList: PropTypes.array,
    tagsUnassign: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedAccounts: PropTypes.array,
  };

  state = {
    currentTags: [],
    loading: false,
    tagsToRemove: [],
  };

  accountsUntag() {
    const params = {
      AccountIds: this.props.selectedAccounts.map(account => account.id),
      TagIds: this.state.tagsToRemove.map(tag => tag.id),
    };

    this.setState({ loading: true });

    this.props.tagsUnassign(params)
      .then(() => {
        this.props.onClose();
        this.props.accountsGet();
        this.props.notificationShow('Accounts untagged.', 'success');
      })
      .catch(() => null)
      .finally(() => this.setState({ loading: false }));
  }

  currentTagsCompose() {
    return this.state.currentTags.map(tag => {
      return (
        <div className={styles.UntagModal_tag} key={tag.id}>
          <Chip
            actions={[{
              icon: 'close',
              tooltip: 'Untag',
              action: () => this.moveTagTo('tagsToRemove', tag),
            }]}
            name={tag.tag}
          />
        </div>
      );
    });
  }

  moveTagTo(list, tag) {
    const { currentTags, tagsToRemove } = this.state;
    if (list === 'tagsToRemove') {
      this.setState({
        currentTags: currentTags.filter(currentTag => currentTag.id !== tag.id),
        tagsToRemove: [...tagsToRemove, tag],
      });
    }
    else if (list === 'currentTags') {
      this.setState({
        currentTags: [...currentTags, tag],
        tagsToRemove: tagsToRemove.filter(removedTag => removedTag.id !== tag.id),
      });
    }
  }

  tagsToRemoveCompose() {
    return this.state.tagsToRemove.map(tag => {
      return (
        <div className={styles.UntagModal_tag} key={tag.id}>
          <Chip
            actions={[
              {
                icon: 'undo',
                tooltip: 'Retag',
                action: () => this.moveTagTo('currentTags', tag),
              }
            ]}
            key={tag.id}
            name={tag.tag}
          />
        </div>
      );
    });
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props;
    if (open && open !== prevProps.open) {
      const allTags = this.props.selectedAccounts.map(account => account.tags);
      this.setState({
        currentTags: intersectionBy(...allTags, 'id'),
        tagsToRemove: []
      });
    }
  }

  render() {
    /* eslint-disable indent */
    const { loading } = this.state;
    return (
      <Modal
        actionButtons={[
          {
            label: 'Cancel',
            action: () => this.props.onClose(),
            disabled: loading,
          },
          {
            action: () => this.accountsUntag(),
            disabled: this.state.tagsToRemove.length < 1,
            label: 'Untag Accounts',
            loading,
            buttonType: 'contained',
          },
        ]}
        onCloseModal={this.props.onClose}
        show={this.props.open}
        title='Untag Accounts'
        DialogActionsProps={{ 'data-testid': 'auto-UntagModal-actionButtons' }}
      >
        {this.state.currentTags.length === 0 && this.state.tagsToRemove.length === 0
          ? <div className={styles.UntagModal_untag_text}>The selected accounts do not share a common tag.</div>
          : <div className={styles.UntagModal_untag_modal_container}>
            <div className={styles.UntagModal_untag_column}>
              <div className={styles.UntagModal_untag_text}>Current Tags</div>
              <div className={styles.UntagModal_tag_container}>{this.currentTagsCompose()}</div>
            </div>
            <div className={styles.UntagModal_untag_column}>
              <div className={styles.UntagModal_untag_text}>Tags To Remove</div>
              <div className={styles.UntagModal_tag_container}>{this.tagsToRemoveCompose()}</div>
            </div>
          </div>
        }
        <div className={styles.UntagModal_table_container}>
          <SmartTable
            idKey='id'
            emptyMessage='No accounts selected.'
            rows={this.props.selectedAccounts}
            columns={[
              {
                key: 'accountNumber',
                title: 'Account',
                type: TableDataTypes.NUMBER,
              },
              {
                key: 'beneName',
                title: 'Beneficiary',
                type: TableDataTypes.STRING,
              },
            ]}
          >
            <TableContainer minWidth='100%' maxHeight='100%'>
              <TableHeader />
              <TableRows />
            </TableContainer>
            <TablePagination />
          </SmartTable>
        </div>
      </Modal>
    );
    /* eslint-enable indent */
  }
}

export default connect(select, { notificationShow, tagsUnassign })(UntagModal);
