import { POSTrequest } from 'utils/helpers/api_handler';
import { EMAIL_ACTIVATE } from './constants';


export function activateEmail(params) {
  const response = POSTrequest('/authentication/ActivateEmail', params);
  return {
    type: EMAIL_ACTIVATE,
    payload: response
  };
}