import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  TextField,
  Button
} from '@mui/material';

import {
  FormWrapper,
  LoadingOverlay,
  notificationShow
} from '@frontend/common';

import { forgotPassword } from 'components/Features/public/ForgotPassword/actions';

import styles from './styles.module.css';

export class ForgotPassword extends Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    forgotPassword: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    username: '',
    inputError: '',
  };

  formValidate = () => {
    let formIsValid = false;
    let inputError = '';

    if (this.state.username.length < 1) {
      inputError = 'Username is required.';
    }
    else {
      formIsValid = true;
    }

    this.setState({
      inputError,
    });

    return formIsValid;
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    this.props.forgotPassword({ email: this.state.username })
      .then(() => {
        this.props.history.push('/login');
        this.props.notificationShow('Request submitted. Check your email.', 'success');
      })
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    const { loading, username, inputError } = this.state;
    return (
      <FormWrapper
        title='Forgot Password'
        instructions='If the provided email is in our system, we will send password reset instructions to the matching email address.'
      >
        <form
          className={styles.formContainer}
          onSubmit={this.onFormSubmit}
          data-testid='auto-ForgotPassword-submit-form'
        >
          <TextField
            label='Email'
            name='username'
            onChange={(e) => this.setState({ username: e.target.value, inputError: '' })}
            inputProps={{ 'data-testid': 'auto-ForgotPassword-userNameEmail-input' }}
            value={username}
            disabled={loading}
            error={Boolean(inputError)}
            helperText={inputError}
            fullWidth
            autoFocus
          />
          <div className={styles.ForgotPassword_buttons}>
            <Button
              onClick={() => this.props.history.push('/login')}
              disabled={loading}
              style={{ width: '48%' }}
              inputProps={{ 'data-testid': 'auto-ForgotPassword-goBackToLogin-button' }}
            >
              Cancel
            </Button>
            <LoadingOverlay show={loading} width='48%'>
              <Button
                type='submit'
                variant='contained'
                disabled={loading || !username}
                fullWidth
                inputProps={{ 'data-testid': 'auto-ForgotPassword-submit-button' }}
              >
                Send
              </Button>
            </LoadingOverlay>
          </div>
        </form>
      </FormWrapper>
    );
  }
}

export default withRouter(connect(null, {
  notificationShow,
  forgotPassword,
})(ForgotPassword));
