import React from 'react';
import PropTypes from 'prop-types';

import {
  Checkbox,
  InfoIcon,
} from '@frontend/common';

import styles from './styles.module.css';

const UserPermissions = ({ canAccessReports, canAccessTransfers, canApproveWithdrawals, updatePermission }) => (
  <div className={styles.permissionsContainer}>
    <div className={styles.title}>Set User Access</div>
    <hr />
    <div className={styles.userAccessCheckboxes}>
      <Checkbox
        label={<div className={styles.toggleLabel}><span>Reports</span><InfoIcon message='Gives the user access to view, run, and download reports for the program(s) he/she has access to.' /></div>}
        checked={canAccessReports}
        onChange={event => updatePermission('canAccessReports', event.target.checked)}
      />
      <Checkbox
        label={<div className={styles.toggleLabel}><span>Transfers</span><InfoIcon message='Gives the user access to create, edit, and delete transfers for the program(s) he/she has access to.' /></div>}
        checked={canAccessTransfers}
        onChange={event => updatePermission('canAccessTransfers', event.target.checked)}
      />
      <Checkbox
        label={<div className={styles.toggleLabel}><span>Manage Withdrawals</span><InfoIcon message='Gives the user the ability to approve or reject withdrawal request for the program(s) they has access to and gives the user the ability to grant withdrawal request abilities to interested party users on scholarship accounts.' /></div>}
        checked={canApproveWithdrawals}
        onChange={event => updatePermission('canApproveWithdrawals', event.target.checked)}
      />
    </div>
  </div>
);

UserPermissions.propTypes = {
  canAccessReports: PropTypes.bool.isRequired,
  canAccessTransfers: PropTypes.bool.isRequired,
  canApproveWithdrawals: PropTypes.bool.isRequired,
  updatePermission: PropTypes.func.isRequired,
};

export default UserPermissions;