import { POSTrequest } from './api_handler';
import { store } from 'src/index';

export function persistClientErrors(error) {
  
  const state = store.getState();
  const params = {
    StackTrace: JSON.stringify(error),
    StateData: JSON.stringify(state),
  };

  if (state.session.token) {
    POSTrequest('/error', params)
      .catch(() => {
        console.warn('Client Error:', params); //eslint-disable-line no-console
      });
  }
}