import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import {
  notificationShow,
  Modal,
} from '@frontend/common';
import { TextField } from '@mui/material';

import { witdrawalSaveAddress } from 'components/Features/protected/Withdrawals/actions';


export class EditWithdrawalAddress extends React.Component {

  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    notificationShow: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    tableDataGet: PropTypes.func.isRequired,
    withdrawalRecordGet: PropTypes.func.isRequired,
    witdrawalSaveAddress: PropTypes.func.isRequired,
  };

  state = {
    editableAddress: {
      AddressId: 0,
      StreetAddress1: '',
      StreetAddress2: '',
      StreetAddress3: '',
      SchoolDepartment: '',
      City: '',
      State: '',
      Zip: '',
      Country: '',
    },
    loading: false,
  };

  addressInputChange = (e) => {
    const editableAddress = cloneDeep(this.state.editableAddress);
    editableAddress[e.target.name] = e.target.value;
    this.setState({ editableAddress });
  }

  onWithdrawalAddressSave = () => {
    this.setState({ loading: true });
    this.props.witdrawalSaveAddress(this.props.id, this.state.editableAddress)
      .then(() => {
        this.props.tableDataGet();
        this.props.onCloseModal();
        this.props.notificationShow('Withdrawal address saved.', 'success');
      })
      .catch(() => null)
      .finally(() => this.setState({ loading: false }));
  }

  componentDidUpdate(prevProps) {
    const { withdrawalRecordGet, show } = this.props;

    if (!show && show !== prevProps.show) {
      this.setState({
        editableAddress: {
          AddressId: 0,
          StreetAddress1: '',
          StreetAddress2: '',
          StreetAddress3: '',
          SchoolDepartment: '',
          City: '',
          State: '',
          Zip: '',
          Country: '',
        },
      });
    }
    else if (show && show !== prevProps.show) {
      const address = withdrawalRecordGet().SchoolAddress;
      this.setState({
        editableAddress: {
          AddressId: address.AddressId,
          StreetAddress1: address.StreetAddress1,
          StreetAddress2: address.StreetAddress2,
          StreetAddress3: address.StreetAddress3,
          City: address.City,
          State: address.State,
          Zip: address.Zip,
          Country: address.Country,
          SchoolDepartment: address.SchoolDepartment
        }
      });
    }
  }

  render() {
    const address = this.state.editableAddress;

    return (
      <div>
        <Modal
          actionButtons={[
            {
              label: 'Cancel',
              action: this.props.onCloseModal,
              disabled: this.state.loading,
            },
            {
              label: 'Save Address',
              action: this.onWithdrawalAddressSave,
              loading: this.state.loading,
              buttonType: 'contained',
            },
          ]}
          onCloseModal={this.props.onCloseModal}
          show={this.props.show}
          title='Edit Payable To Address'
        >
          <form data-testid='auto-EditWithdralAddress-submit-form'>
            <TextField
              name='StreetAddress1'
              label='For The Benefit Of'
              value={address.StreetAddress1}
              onChange={() => null}
              inputProps={{ maxLength: 40, 'data-testid': 'auto-EditWithdrawalAddress-ForTheBenefitOf-input' }}
              fullWidth
              disabled
            />
            <TextField
              name='SchoolDepartment'
              label='Department'
              value={address.SchoolDepartment}
              onChange={() => null}
              inputProps={{ maxLength: 40, 'data-testid': 'auto-EditWithdrawalAddress-SchoolDepartment-input' }}
              fullWidth
              disabled
            />
            <TextField
              name='StreetAddress2'
              label='Street Address 1'
              value={address.StreetAddress2}
              onChange={this.addressInputChange}
              inputProps={{ maxLength: 40, 'data-testid': 'auto-EditWithdrawalAddress-StreetAddress1-input' }}
              fullWidth
            />
            <TextField
              name='City'
              label='City'
              value={address.City}
              onChange={this.addressInputChange}
              inputProps={{ maxLength: 26, 'data-testid': 'auto-EditWithdrawalAddress-City-input' }}
              fullWidth
            />
            <TextField
              name='State'
              label='State'
              value={address.State}
              onChange={this.addressInputChange}
              inputProps={{ maxLength: 2, 'data-testid': 'auto-EditWithdrawalAddress-State-input' }}
              fullWidth
            />
            <TextField
              name='Zip'
              label='Zip'
              value={address.Zip}
              onChange={this.addressInputChange}
              inputProps={{ 'data-testid': 'auto-EditWithdrawalAddress-Zip-input' }}
              fullWidth
            />
          </form>
        </Modal>
      </div>
    );
  }
}

export default connect(null, { notificationShow, witdrawalSaveAddress })(EditWithdrawalAddress);