import { combineReducers } from '@reduxjs/toolkit';

import { INITIALIZE_STORE } from 'components/AppRoot/Navigation/constants';

import Notifications from '@frontend/common/build/components/Notifications/reducer';

import Session from 'components/AppRoot/Navigation/reducer';
import StaticResources from 'components/AppRoot/StaticResources/reducer';
import ChangePassword from 'components/Features/protected/MyInfo/ChangePassword/reducer';
import Dashboard from 'components/Features/protected/Dashboard/reducer';
import Accounts from 'components/Features/protected/Accounts/reducer';
import Tags from 'components/Features/protected/ManageTags/reducer';
import Users from 'components/Features/protected/ManageUsers/reducer';
import Withdrawals from 'components/Features/protected/Withdrawals/reducer';
import Reports from 'components/Features/protected/Reports/reducer';
import Programs from 'components/Features/protected/Programs/reducer';
import Transfer from 'components/Features/protected/Transfers/reducer';
import Multifactor from 'components/Features/protected/Multifactor/reducer';

/*
Ability to reinitialize the entire store.
This action type is used by /logout
*/
const rootReducer = (state, action) => {
  if (action.type === INITIALIZE_STORE) {
    clearInterval(state.session.tokenValidateIntervalId);
    state = {
      staticResources: {
        webMessages: state.staticResources.webMessages,
        passRequirements: state.staticResources.passRequirements,
        milestones: [],
      }
    };
  }

  return appReducer(state, action);
};

const appReducer = combineReducers({
  session: Session,
  staticResources: StaticResources,
  changePassword: ChangePassword,
  dashboard: Dashboard,
  accounts: Accounts,
  tags: Tags,
  users: Users,
  withdrawals: Withdrawals,
  reports: Reports,
  notifications: Notifications,
  programs: Programs,
  transfer: Transfer,
  multifactor: Multifactor,
});

export default rootReducer;
