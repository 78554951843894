import { cloneDeep, find } from 'lodash';

import { properCase } from '@frontend/common';

import {
  USER_AUTHENTICATE_REQUEST,
  BROWSER_WARNING,
  MOBILE_WARNING,
  TOGGLE_SPLASH_HAS_DISPLAYED,
  USER_INFO_GET,
  USER_INFO_UPDATE,
  TWO_FACTOR_STATUS,
  RESET_CLAIMS,
} from './constants';

import { USER_ROLES } from 'components/Features/protected/ManageUsers/constants';

const initialState = {
  isValid: false,
  token: '',
  accountBlocked: false,
  reasonBlocked: '',
  is2FABypassed: true,
  showMobileWarning: true,
  showBrowserWarning: true,
  splashHasDisplayed: false,
  userDetails: {
    Login: '',
    LastLogin: '',
    UserId: '',
    EmailAddress: '',
    FirstName: '',
    LastName: '',
    PhoneNumber: '',
    TaxableEntityName: '',
    IsAdmin: false,
    CanAccessReports: false,
    CanAccessTransfers: false,
    CanApproveWithdrawals: false,
  },
};

const updateStateFromClaims = (state, claims) => {
  const userDetails = {
    TaxableEntityName: properCase(find(claims, { 'm_type': 'TaxableEntityName' }).m_value),
    LastLogin: find(claims, { 'm_type': 'LastLogin' }).m_value,
    UserId: find(claims, { 'm_type': 'UserId' }).m_value,
    IsAdmin: find(claims, { 'm_type': 'IsAdmin' }).m_value === 'True',
    IsEntityAgent: find(claims, { 'm_type': 'IsEntityAgent' }).m_value === 'True',
    CanAccessReports: find(claims, { 'm_type': 'CanAccessReports' }).m_value === 'True',
    CanApproveWithdrawals: find(claims, { 'm_type': 'CanApproveWithdrawals' }).m_value === 'True',
    CanAccessTransfers: find(claims, { 'm_type': 'CanAccessTransfers' }).m_value === 'True',
    EmailAddress: find(claims, { 'm_type': 'EmailAddress' }).m_value,
  };
  if (userDetails.IsAdmin) {
    userDetails.userRole = userDetails.IsEntityAgent ? USER_ROLES.ENTITY_AGENT : USER_ROLES.ADMINISTRATOR;
  }
  else {
    userDetails.userRole = USER_ROLES.PROGRAM_MANAGER;
  }

  state.userDetails = userDetails;

  state.accountBlocked = find(claims, { 'm_type': 'AccountBlocked' }).m_value === 'True';
  // when AccountBlocked is false AccountBlock is missing from api
  if (state.accountBlocked) {
    state.reasonBlocked = find(claims, { 'm_type': 'AccountBlock' }).m_value;
  }
  else {
    state.reasonBlocked = '';
  }

  const TwoFactorAuthentication = find(claims, { 'm_type': 'TwoFactorAuthentication' }).m_value;
  state.is2FABypassed = TwoFactorAuthentication === TWO_FACTOR_STATUS.BYPASSED;
  return state;
};

export default function SessionReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case USER_AUTHENTICATE_REQUEST: {
      const session = action.payload.data;
      updateStateFromClaims(newState, session.Claims);
      newState.token = session.Token;
      newState.isValid = true;
      return newState;
    }

    case RESET_CLAIMS: {
      const claims = action.payload.data;
      updateStateFromClaims(newState, claims);
      return newState;
    }

    case USER_INFO_GET:
    case USER_INFO_UPDATE: {
      const user = action.payload.data;
      newState.userDetails = {
        ...state.userDetails,
        ...user,
      };
      return newState;
    }

    case BROWSER_WARNING: {
      newState.showBrowserWarning = !state.showBrowserWarning;
      return newState;
    }

    case MOBILE_WARNING: {
      newState.showMobileWarning = !state.showMobileWarning;
      return newState;
    }

    case TOGGLE_SPLASH_HAS_DISPLAYED: {
      newState.splashHasDisplayed = true;
      return newState;
    }

    default: {
      return state;
    }

  }
}
