export const PASSWORD_REQUIREMENTS_GET = 'src/components/AppRoot/StaticResources/PASSWORD_REQUIREMENTS_GET';
export const MILESTONES_GET = 'src/components/AppRoot/StaticResources/MILESTONES_GET';
export const WEB_MESSAGES_GET = 'src/components/AppRoot/StaticResources/WEB_MESSAGES_GET';

// hardcoded banners - the value has to match exactly `${URL}/${Page}` from WebsiteMessage api 
// add/remove when needed
export const BANNER_LOCATIONS = {
  LOGIN: '/login/Login Page',
  HOME: '/Home Page',
};

