import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import {
  LoadingOverlay,
  notificationShow,
  allNotificationsHide,
} from '@frontend/common';

import {
  Button,
} from '@mui/material';

import UserDetails from './UserDetails';
import UserPermissions from '../UserPermissions';

import MultiSelectorCheckboxes from '../MultiSelectorCheckboxes';

import {
  userGet,
  updateUser,
} from '../actions';

import styles from './styles.module.css';

const mapUserFromApi = (userFromApi) => ({
  canAccessReports: userFromApi.CanAccessReports,
  canAccessTransfers: userFromApi.CanAccessTransfers,
  canApproveWithdrawals: userFromApi.CanApproveWithdrawals,
  programs: userFromApi.Programs.map(program => ({ value: program.TaxableEntityId, display: program.Name })),
});

const select = (state) => ({
  programs: state.users.programs,
});

export class EditUser extends React.Component {
  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    userGet: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    programs: PropTypes.array.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        userId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
  };

  state = {
    loading_userLoad: false,
    loading_userSave: false,
    userFromApi: {
      Email: '',
      Name: '',
      UserType: '',
      CanAccessReports: false,
      CanAccessTransfers: false,
      CanApproveWithdrawals: false,
      Programs: [],
      Pin: ''
    },
    editedUser: {
      canAccessReports: false,
      canAccessTransfers: false,
      canApproveWithdrawals: false,
      programs: [],
    },
    missingProgramError: ''
  };

  wasUserEdited = (userFromApi, editedUser) => {
    const userInitial = mapUserFromApi(userFromApi);
    return !isEqual(userInitial, editedUser);
  }

  onSubProgramSelection = (selectedSubPrograms) => {
    this.setState({
      editedUser: {
        ...this.state.editedUser,
        programs: selectedSubPrograms,
      },
      missingProgramError: ''
    });
  }

  updateUserHandler = () => {
    const { userFromApi, editedUser } = this.state;
    const {
      updateUser, notificationShow,
      match: { params: { userId } }
    } = this.props;

    if (this.wasUserEdited(userFromApi, editedUser)) {
      if (editedUser.programs.length < 1) {
        this.setState({ missingProgramError: 'This field is required.' });
      }
      else {
        this.setState({ loading_userSave: true });
        const userBody = {
          CanAccessReports: editedUser.canAccessReports,
          CanApproveWithdrawals: editedUser.canApproveWithdrawals,
          CanAccessTransfers: editedUser.canAccessTransfers,
          ProgramIds: editedUser.programs.map(program => program.value)
        };

        return updateUser(userId, userBody)
          .then(() => {
            notificationShow('User updated.', 'success');
            return this.loadUser();
          })
          .finally(() => this.setState({ loading_userSave: false }));
      }
    }
    else {
      notificationShow('Please make any changes to the user first.', 'error');
    }
  };

  resetUser = () => {
    this.props.allNotificationsHide();
    this.setState({
      editedUser: mapUserFromApi(this.state.userFromApi),
      missingProgramError: ''
    });
  }

  loadUser = () => {
    const {
      match: { params: { userId } },
      userGet
    } = this.props;

    this.setState({ loading_userLoad: true });
    return userGet(userId)
      .then(response => {
        const userFromApi = response.payload.data;
        this.setState({
          userFromApi,
          editedUser: mapUserFromApi(userFromApi) // initial user values for editing
        });
      })
      .finally(() => this.setState({ loading_userLoad: false }));
  }

  componentDidMount() {
    this.loadUser();
  }

  render() {
    const {
      userFromApi, editedUser, loading_userLoad,
      loading_userSave, missingProgramError
    } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.titleBar}>
          <div>
            <Button
              variant='contained'
              onClick={() => this.props.history.push('/users')}
              disabled={loading_userSave}
            >
              &lt; User List
            </Button>
          </div>
          <div className={styles.title}>Edit User</div>
          <div>
            <Button
              onClick={this.resetUser}
              disabled={loading_userSave}
              style={{ marginRight: '5px' }}
            >
              Reset
            </Button>
            <LoadingOverlay show={loading_userSave}>
              <Button
                variant='contained'
                onClick={this.updateUserHandler}
              >
                Save
              </Button>
            </LoadingOverlay>
          </div>
        </div>
        <LoadingOverlay
          show={loading_userLoad || loading_userSave}
          width='100%'
          height='100%'
        >
          <div className={styles.formContainer}>
            <div className={styles.userDetails}>
              <UserDetails
                name={userFromApi.Name}
                email={userFromApi.Email}
                userType={userFromApi.UserType}
                pin={userFromApi.Pin}
              />
            </div>

            <MultiSelectorCheckboxes
              selections={editedUser.programs}
              defaultSelections={userFromApi.Programs.map(program => ({ value: program.TaxableEntityId, display: program.Name }))}
              defaultOptions={this.props.programs.map(program => ({ value: program.id, display: program.name }))}
              onCheckboxSelection={this.onSubProgramSelection}
              label='Select sub-program(s)...'
              errorText={missingProgramError}
            />

            <div className={styles.permissionsContainer}>
              <UserPermissions
                canAccessReports={editedUser.canAccessReports}
                canAccessTransfers={editedUser.canAccessTransfers}
                canApproveWithdrawals={editedUser.canApproveWithdrawals}
                updatePermission={(permissionName, permission) => this.setState({
                  editedUser: {
                    ...editedUser,
                    [permissionName]: permission
                  }
                })}
              />
            </div>

          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default connect(select, {
  userGet,
  updateUser,
  notificationShow,
  allNotificationsHide,
})(EditUser);
