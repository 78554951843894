import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import PwddByAuthy from '../PwddByAuthy.jpg';
import TwoFAGraphic from '../2FAGraphic.png';

import { Button } from '@mui/material';

import styles from '../styles.module.css';

export class Welcome extends Component {

  state = {
    learnMoreOpen: false,
  };

  render() {
    return (
      <>
        <img src={TwoFAGraphic} alt='Two Factor Authentication' width='50%' />
        <h3>my529 is now requiring two factor authentication</h3>

        <div style={{ fontWeight: 'bold' }}>
          Two Factor Authentication (2FA) adds a layer of protection to your account. It combines something you know, such as a username and password, with something you have, such as a device through which you can access a security code.
        </div>

        <div>
          <Button
            variant='contained'
            onClick={() => this.props.history.push('/multifactor/verify-identity')}
            className={styles.buttons}
            style={{ marginTop: '20px' }}
          >
            Get Started
          </Button>
        </div>

        <div>
          <Button
            color='secondary'
            variant='outlined'
            className={styles.buttons}
            style={{ marginTop: '10px' }}
            onClick={() => window.open('https://my529.org/faq-two-factor-authentication/', '_blank', 'noopener noreferrer')}
          >
            Learn More
          </Button>

        </div>

        <div>
          <img src={PwddByAuthy} alt='Powered By Authy' />
        </div>

      </>
    );
  }
}

export default withRouter(Welcome);