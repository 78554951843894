import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { errors } from 'utils/helpers/formValidators';

import {
  Chip,
  Modal,
  notificationShow,
} from '@frontend/common';
import {
  Icon,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';

import { createTag } from '../actions';

import styles from './styles.module.css';


export class CreateTagsModal extends React.Component {

  static propTypes = {
    availableTags: PropTypes.arrayOf(PropTypes.object).isRequired,
    createTag: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };

  state = {
    loading: false,
    tagToCreate: '',
    tagsToCreate: [],
    inputError: '',
  };

  onModalClose = () => {
    this.setState({
      tagToCreate: '',
      tagsToCreate: [],
      inputError: '',
    });
    this.props.onClose();
  }

  tagIsDuplicate(tag) {
    const tagToCreate = tag.toLowerCase();
    return this.props.availableTags.some(tag => tag.Tag.toLowerCase() === tagToCreate)
      || this.state.tagsToCreate.some(tag => tag.toLowerCase() === tagToCreate);
  }

  tagsCompose() {
    return this.state.tagsToCreate.map(tag => {
      return (
        <div className={styles.CreateTag_tag} key={tag}>
          <Chip
            actions={[
              {
                icon: 'close',
                tooltip: 'Delete',
                action: () => this.tagsToCreateRemove(tag),
              }
            ]}
            name={tag}
          />
        </div>
      );
    });
  }

  tagsCreate() {
    if (this.state.tagsToCreate.length === 0) {
      this.setState({ inputError: 'Add tag first.' });
    }
    else {
      this.setState({ loading: true });
      this.props.createTag(this.state.tagsToCreate)
        .then(() => {
          this.onModalClose();
          this.props.notificationShow(`${this.state.tagsToCreate.length === 1 ? 'Tag created.' : 'Tags created.'}`, 'success');
        })
        .catch(() => null)
        .finally(() => this.setState({ loading: false }));
    }
  }

  tagsToCreateAdd() {
    const tag = this.state.tagToCreate.trim();
    if (!tag) {
      this.setState({ inputError: errors.required_field });
    }
    else if (this.tagIsDuplicate(tag)) {
      this.setState({ inputError: 'Tag already exists.' });
    }
    else {
      this.setState({
        tagsToCreate: [...this.state.tagsToCreate, tag],
        tagToCreate: '',
        inputError: '',
      });
    }
  }

  tagsToCreateRemove(tag) {
    const tagsToCreate = [...this.state.tagsToCreate];
    tagsToCreate.splice(tagsToCreate.indexOf(tag), 1);
    this.setState({ tagsToCreate });
  }

  render() {
    const { inputError } = this.state;
    return (
      <Modal
        actionButtons={[
          {
            label: 'Cancel',
            action: this.onModalClose,
            disabled: this.state.loading,
          },
          {
            action: () => this.tagsCreate(),
            loading: this.state.loading,
            label: this.state.tagsToCreate.length > 1 ? 'Create Tags' : 'Create Tag',
            buttonType: 'contained',
          }
        ]}
        onCloseModal={this.onModalClose}
        show={this.props.open}
        title='Create Tag'
      >
        <div className={styles.CreateTag_tagsContainer}>
          {this.tagsCompose()}
        </div>
        <span className={styles.CreateTag_createTagsForm}>
          <TextField
            label='New Tag Name'
            value={this.state.tagToCreate}
            onChange={(e) => this.setState({ tagToCreate: e.target.value, inputError: '' })}
            inputProps={{ maxLength: 50, 'data-testid': 'auto-CreateTagsModal-tagToCreate-input' }}
            error={Boolean(inputError)}
            helperText={inputError}
            autoFocus
          />
          <Tooltip
            title='Add Tag'
            placement='top'
          >
            <div>
              <IconButton
                onClick={() => this.tagsToCreateAdd()}
              >
                <Icon>add</Icon>
              </IconButton>
            </div>
          </Tooltip>
        </span>
      </Modal>
    );
  }
}

export default connect(null, { createTag, notificationShow })(CreateTagsModal);
