import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '@frontend/common';

import styles from './styles.module.css';

export class ViewWithdrawalModal extends React.Component {

  static propTypes = {
    onCloseModal: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    withdrawalInfo: PropTypes.array.isRequired,
  };
  
  render() {
    return (
      <div>
        <Modal
          actionButtons={[{
            label: 'Ok',
            action: this.props.onCloseModal,
            disabled: false,
          }]}
          onCloseModal={this.props.onCloseModal}
          show={this.props.show}
          title='Withdrawal Details'
        >
          <div className={styles.ViewWithdrawalModal_tableContainer}>
            <table className={styles.ViewWithdrawalModal_tableContent}>
              { this.props.withdrawalInfo }
            </table>
          </div>
        </Modal>
      </div>
    );
  }

}

export default ViewWithdrawalModal;
