import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { witdrawalApprove } from 'components/Features/protected/Withdrawals/actions';

import { 
  Modal,
  notificationShow
} from '@frontend/common';

import styles from './styles.module.css';

export class ApproveWithdrawalModal extends React.Component {

  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    notificationShow: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    tableDataGet: PropTypes.func.isRequired,
    withdrawalInfo: PropTypes.array.isRequired,
    witdrawalApprove: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
  };

  onWithdrawalApprove = () => {
    this.setState({ loading: true });

    this.props.witdrawalApprove(this.props.id, {})
      .then(() => {  
        this.props.onCloseModal();
        this.props.notificationShow('Withdrawal approved.', 'success');
        this.props.tableDataGet();
      })
      .catch(() => null)
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    return (
      <Modal
        actionButtons={[
          {
            label: 'Cancel',
            action: this.props.onCloseModal,
            disabled: this.state.loading,
          },
          {
            label: 'Approve',
            action: this.onWithdrawalApprove,
            loading: this.state.loading,
            buttonType: 'contained',
          },
        ]}
        onCloseModal={this.props.onCloseModal}
        show={this.props.show}
        title='Approve Withdrawal'
        modal={true}
      >
        <div className={styles.ApproveWithdrawalModal_tableContainer}>
          <table className={styles.ApproveWithdrawalModal_tableContent}>
            { this.props.withdrawalInfo }
          </table>
        </div>
      </Modal>
    );
  }
}

export default connect(null, { notificationShow, witdrawalApprove })(ApproveWithdrawalModal);
