/*
*
* Transfers Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  notificationShow,
  Modal,
} from '@frontend/common';
import {
  getEntity,
} from 'components/Features/protected/Dashboard/actions';
import {
  getAccounts
} from 'components/Features/protected/Accounts/actions';
import {
  getMilestones,
} from 'components/AppRoot/StaticResources/actions';
import {
  createTransfer,
  updateTransfer,
  getTransfer
} from './actions';
import {
  MINIMUM_AMOUNT_ERROR,
  MISSING_ACCOUNT_ERROR,
  MISSING_PROGRAM_ERROR,
  OVERDRAFT_ERROR,
  NO_PER_PROGRAM_ACCOUNTS,
  REQUEST_TYPE
} from './constants';
import {
  TransferUI
} from './TransferUI';

const select = (state) => ({
  programs: state.dashboard.programList,
  accounts: state.accounts.accountList,
  milestones: state.staticResources.milestones,
  account: state.accounts.account,
  transfer: state.transfer.transfer
});

export class Transfers extends React.Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    getAccounts: PropTypes.func.isRequired,
    getEntity: PropTypes.func.isRequired,
    getMilestones: PropTypes.func.isRequired,
    createTransfer: PropTypes.func.isRequired,
    updateTransfer: PropTypes.func.isRequired,
    getTransfer: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
    programs: PropTypes.array.isRequired,
    accounts: PropTypes.array.isRequired,
    milestones: PropTypes.array.isRequired,
    transfer: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    wasNextClicked: false,
    isTransferIdividual: false,
    milestones: [], // set on mount from static props
    error: {
      Total: '',
      Program: '',
      targets: [{
        AccountId: '',
        Amount: ''
      }]
    },
    showDuplicatesModal: false,
    proceedWithDuplicates: false,
    requestType: REQUEST_TYPE.CREATE, // set on mount from navigation props
    stepIndex: -1, // set on mount from navigation props
    loading: true,
    requesting: false,
    selectedProgramId: '',
    accountsByProgram: [],
    transferTargets: [{ AccountId: -1, AccountNumber: -1, Amount: 0, Milestone: '', BeneficiaryDisplayName: '', AccountType: '' }],
  };

  setStepIndex = (stepIndex) => {
    this.setState({ stepIndex });
  }

  getAccountsByProgram = (programId) => {
    // filter out all closed accounts
    const program = this.props.programs.find(program => program.AccountId === programId);
    return this.props.accounts
      .filter(account => account.TaxableEntityProgramAccountId === program.TaxableEntityProgramId && account.Status !== 'Closed')
      .map(account => ({
        AccountId: account.AccountId,
        BeneficiaryDisplayName: account.BeneficiaryDisplayName,
        AccountType: account.AccountType,
        AccountNumber: account.AccountNumber
      }));
  }

  areDuplicateTransfers = () => {
    const { transferTargets } = this.state;

    for (let i = 0; i < transferTargets.length; i++)
      // eslint-disable-next-line id-length
      for (let j = i + 1; j < transferTargets.length; j++)
        if (
          transferTargets[i].AccountNumber === transferTargets[j].AccountNumber &&
          transferTargets[i].Milestone === transferTargets[j].Milestone &&
          transferTargets[i].Amount === transferTargets[j].Amount
        ) {
          return true;
        }

    return false;
  }

  validateTransfer = (errorType = '', rowIndex = -1) => {
    const { transferTargets, wasNextClicked, error, accountsByProgram, selectedProgramId, stepIndex } = this.state;
    const { programs } = this.props;
    const program = programs.find(program => program.AccountId === selectedProgramId);
    let updatedError = { ...error };

    switch (stepIndex) {
      case 0:
        // always reset duplicates allowed
        this.setState({ proceedWithDuplicates: false });
        // always reset error
        updatedError = {
          Total: '',
          Program: '',
          targets: transferTargets.map(() => ({
            AccountId: '',
            Amount: ''
          })),
        };
        if (errorType === 'Program' || wasNextClicked) {
          if (selectedProgramId === -1)
            updatedError.Program = MISSING_PROGRAM_ERROR;
          else if (accountsByProgram.length < 1)
            updatedError.Program = NO_PER_PROGRAM_ACCOUNTS;
          else
            updatedError.Program = '';
        }
        break;
      case 1: {
        if (errorType === 'Total' || wasNextClicked)
          updatedError.Total = (program.MasterValue < transferTargets.reduce((total, target) => total + target.Amount, 0)) ? OVERDRAFT_ERROR(program.MasterValue) : '';

        updatedError.targets = transferTargets
          .map((target, index) => {
            const targetError = { ...updatedError.targets[index] };
            if ((rowIndex > -1 && index === rowIndex && errorType === 'Amount') || wasNextClicked) {
              targetError.Amount = target.Amount < 1 ? MINIMUM_AMOUNT_ERROR : '';
            }
            if ((rowIndex > -1 && index === rowIndex && errorType === 'AccountId') || wasNextClicked) {
              targetError.AccountId = target.AccountId === -1 || !target.BeneficiaryDisplayName || !target.AccountType ? MISSING_ACCOUNT_ERROR : '';
            }
            return targetError;
          });

        break;
      }

      default:
      // do nothing
    }
    this.setState({ error: updatedError });
    const hasNoErrors =
      (updatedError.Total === '') &&
      (updatedError.Program === '') &&
      (updatedError.targets.filter(target => target.AccountId !== '' || target.Amount !== '').length === 0);
    return hasNoErrors;
  };

  onProgramChange = programId => {
    programId = parseInt(programId);
    // recalculate accounts by program, reset targets
    this.setState({
      selectedProgramId: programId,
      accountsByProgram: this.getAccountsByProgram(programId),
      transferTargets: [{ AccountId: -1, AccountNumber: -1, Amount: 0, Milestone: '', AccountType: '' }],
    }, () => {
      this.validateTransfer('Program');
    });
  };

  onAccountChange = (rowIndex, value) => {
    // always reset duplicates
    this.setState({ proceedWithDuplicates: false });
    value = parseInt(value);
    const selectedAccount = this.state.accountsByProgram.find(account => account.AccountId === value);
    const updatedTransferTargets = [...this.state.transferTargets];
    updatedTransferTargets[rowIndex].AccountId = value;
    updatedTransferTargets[rowIndex].AccountNumber = selectedAccount.AccountNumber;
    updatedTransferTargets[rowIndex].BeneficiaryDisplayName = selectedAccount.BeneficiaryDisplayName;
    updatedTransferTargets[rowIndex].AccountType = selectedAccount.AccountType;
    this.setState({ transferTargets: updatedTransferTargets }, () => {
      this.validateTransfer('AccountId', rowIndex);
    });
  };

  onMilestoneChange = (rowIndex, value) => {
    // always reset duplicates
    this.setState({ proceedWithDuplicates: false });
    const updatedTransferTargets = [...this.state.transferTargets];
    updatedTransferTargets[rowIndex].Milestone = value;
    this.setState({
      transferTargets: updatedTransferTargets
    });
  };

  onAmountChange = (rowIndex, objValue) => {
    // always reset duplicates
    this.setState({ proceedWithDuplicates: false });
    const updatedTransferTargets = [...this.state.transferTargets];
    updatedTransferTargets[rowIndex].Amount = objValue.floatValue;
    this.setState({ transferTargets: updatedTransferTargets }, () => {
      this.validateTransfer('Amount', rowIndex);
    });
  };

  onAddRow = () => {
    const { transferTargets, error } = this.state;
    // always reset duplicates
    this.setState({ proceedWithDuplicates: false });
    const updatedError = { ...error };
    updatedError.targets = [...updatedError.targets, { AccountId: '', Amount: '' }];
    this.setState({
      transferTargets: [...transferTargets, { AccountId: -1, AccountNumber: -1, Amount: 0, Milestone: '', AccountType: '' }],
      error: updatedError
    });
  };

  onRemoveRow = rowIndex => {
    const { transferTargets, error } = this.state;
    // always reset duplicates
    this.setState({ proceedWithDuplicates: false });
    // make sure there is at least one tranfer
    if (transferTargets.length <= 1) {
      this.props.notificationShow('There must be at least one transfer to proceed.', 'error');
    }
    else {
      const updatedTransferTargets = [...transferTargets];
      const updatedError = { ...error };
      updatedTransferTargets.splice(rowIndex, 1);
      updatedError.targets.splice(rowIndex, 1);
      this.setState({
        transferTargets: updatedTransferTargets,
        error: updatedError
      });
    }
  }

  addNewMilestone = (newMilestone) => {
    const newMilestones = [...this.state.milestones];
    // push only when milestone is unique
    if (!newMilestones.includes(newMilestone)) {
      newMilestones.push(newMilestone);
      // reorder alphabeticaly
      newMilestones.sort();
      return this.setState({
        milestones: newMilestones,
        showNewMilestoneMode: false
      });
    }
    else {
      return this.setState({
        showNewMilestoneMode: false
      });
    }
  };

  onBackButtonClick = () => {
    this.setState({
      stepIndex: this.state.stepIndex - 1
    });
  }

  onNextButtonClick = () => {
    const { stepIndex, proceedWithDuplicates } = this.state;
    this.setState({ wasNextClicked: true }, () => {
      if (this.validateTransfer()) {
        // check for duplicates
        if (this.areDuplicateTransfers() && !proceedWithDuplicates && stepIndex === 1)
          this.setState({ showDuplicatesModal: true });
        else
          this.setState({ stepIndex: stepIndex + 1 });
      }
      this.setState({ wasNextClicked: false });
    });
  }

  cancelTransfer = () => {
    this.props.history.goBack();
  }

  transferRequest = () => {
    const { programs, notificationShow } = this.props;
    const { selectedProgramId, transferTargets, requestType } = this.state;
    const { transferId } = this.props.match.params;
    const program = programs.find(program => program.AccountId === selectedProgramId);
    const transfer = {
      MasterAccountId: selectedProgramId,
      TotalAmount: transferTargets.reduce((sum, target) => sum + target.Amount, 0),
      TransferDetails: transferTargets.map(
        ({ AccountId, Amount, Milestone }) => {
          if (Milestone) {
            return {
              AccountId,
              Amount,
              Milestone,
            };
          }
          return {
            AccountId,
            Amount
          };
        }
      )
    };
    this.setState({ requesting: true });
    switch (requestType) {
      case REQUEST_TYPE.CREATE:
        this.props.createTransfer(transfer)
          .then(() => {
            notificationShow('Transfer created.', 'success');
            // update milestones in case they were added
            return this.props.getMilestones();
          })
          .then(() => this.setState({
            milestones: this.props.milestones,
            requesting: false
          }))
          .then(() => this.props.history.push(`/programs/${program.AccountId}?programId=${program.TaxableEntityProgramId}`))
          .catch(() => this.setState({ requesting: false }));
        break;

      case REQUEST_TYPE.UPDATE:
        this.props.updateTransfer(transferId, transfer)
          .then(() => {
            notificationShow('Transfer updated.', 'success');
            // update milestones in case they were added
            return this.props.getMilestones();
          })
          .then(() => this.setState({
            milestones: this.props.milestones,
            requesting: false
          }))
          .then(() => this.props.history.push(`/programs/${program.AccountId}?programId=${program.TaxableEntityProgramId}`))
          .catch(() => this.setState({ requesting: false }));
        break;

      default:
        notificationShow('Unknown request type', 'error');
    }
  };

  componentDidMount() {
    this.setState({ loading: true });
    const transferId = parseInt(this.props.match.params.transferId);
    const {
      programs, accounts, milestones, account,
      getEntity, getAccounts, getMilestones, getTransfer
    } = this.props;
    const requestType = transferId ? REQUEST_TYPE.UPDATE : REQUEST_TYPE.CREATE;
    const ProgramId = account.AccountId;

    const programsPromise = !programs || programs.length === 0 ? getEntity() : null;
    const accountsPromise = !accounts || accounts.length === 0 ? getAccounts() : null;
    const milestonesPromise = !milestones || milestones.length === 0 ? getMilestones() : null;
    const transferPromise = requestType === REQUEST_TYPE.UPDATE ? getTransfer(transferId) : null;

    Promise.all([
      programsPromise,
      accountsPromise,
      milestonesPromise,
      transferPromise
    ])
      .then(() => {
        const { programs, milestones } = this.props;
        if (programs.length === 1) {
          this.onProgramChange(programs[0].AccountId);
        }
        if (requestType === REQUEST_TYPE.UPDATE) {
          const { transfer } = this.props;
          const { accountsByProgram } = this.state;
          let refreshedAccountsByProgram = accountsByProgram;
          if (refreshedAccountsByProgram.length === 0)
            refreshedAccountsByProgram = this.getAccountsByProgram(ProgramId);

          this.setState({
            requestType,
            loading: false,
            milestones,
            accountsByProgram: refreshedAccountsByProgram,
            stepIndex: 1,
            selectedProgramId: account.AccountId,
            error: {
              Total: '',
              Program: '',
              targets: transfer.TransferDetails.map(() => ({
                AccountId: '',
                Amount: ''
              }))
            },
            transferTargets: transfer.TransferDetails.map(detail => {
              const selectedAccount = refreshedAccountsByProgram.find(account => account.AccountId === detail.AccountId);
              return ({
                AccountId: detail.AccountId,
                BeneficiaryDisplayName: selectedAccount.BeneficiaryDisplayName,
                AccountType: selectedAccount.AccountType,
                AccountNumber: selectedAccount.AccountNumber,
                Amount: detail.Amount,
                Milestone: detail.Milestone
              });
            }),
          });
        }
        else {
          this.setState({
            requestType,
            loading: false,
            milestones,
            stepIndex: programs.length === 1 ? 1 : 0
          });
        }
      });
  }

  render() {
    const { showDuplicatesModal } = this.state;
    return (
      <>
        <TransferUI
          requestType={this.state.requestType}
          initializeState={this.initializeState}
          notificationShow={this.props.notificationShow}
          stepIndex={this.state.stepIndex}
          setStepIndex={this.setStepIndex}
          loading={this.state.loading}
          requesting={this.state.requesting}
          programs={this.props.programs}
          accountsByProgram={this.state.accountsByProgram}
          milestones={this.state.milestones}
          selectedProgramId={this.state.selectedProgramId}
          transferTargets={this.state.transferTargets}
          onProgramChange={this.onProgramChange}
          onTransferSubmit={this.transferRequest}
          onBackButtonClick={this.onBackButtonClick}
          onNextButtonClick={this.onNextButtonClick}
          onAccountChange={this.onAccountChange}
          onMilestoneChange={this.onMilestoneChange}
          onAmountChange={this.onAmountChange}
          onAddRow={this.onAddRow}
          onRemoveRow={this.onRemoveRow}
          addNewMilestone={this.addNewMilestone}
          cancelTransfer={this.cancelTransfer}
          error={this.state.error}
        />
        <Modal
          show={Boolean(showDuplicatesModal)}
          title={'Duplicates'}
          onCloseModal={() => this.setState({ showDuplicatesModal: false, proceedWithDuplicates: false })}
          actionButtons={[
            {
              label: 'Back',
              action: () => this.setState({ showDuplicatesModal: false, proceedWithDuplicates: false }),
            },
            {
              label: 'OK',
              action: () => this.setState({ showDuplicatesModal: false, proceedWithDuplicates: true, stepIndex: this.state.stepIndex + 1 }),
            }
          ]}
        >
          You have entered a transfer request to an account with an existing transfer. Please review the information by clicking BACK to edit or OK to proceed.
        </Modal>
      </>

    );
  }
}

export default connect(select, {
  getEntity,
  getAccounts,
  getMilestones,
  createTransfer,
  updateTransfer,
  getTransfer,
  notificationShow
})(Transfers);
