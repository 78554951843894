import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  LoadingOverlay,
} from '@frontend/common';

import {
  Button,
  Icon,
  Menu,
  MenuItem,
} from '@mui/material';

import { getUserInfo } from '../../actions';

import styles from './styles.module.css';

const select = (state) => ({
  userDetails: state.session.userDetails,
  taxableEntityId: state.dashboard.taxableEntityId,
});

export class NameMenu extends Component {

  static propTypes = {
    onLogoutClick: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    taxableEntityId: PropTypes.number.isRequired,
    history: PropTypes.object.isRequired,
    getUserInfo: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    anchorEl: '',
  };

  goToMyInfoHandle = () => {
    this.menuClose();
    this.props.history.push('/my-info');
  }

  logoutClickHandle = () => {
    this.menuClose();
    this.props.onLogoutClick();
  }

  menuClose = () => {
    this.setState({ anchorEl: '' });
  }

  componentDidMount() {
    // get remaining userDetails info here
    this.setState({ loading: true });
    this.props.getUserInfo()
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const { userDetails, taxableEntityId } = this.props;
    const { anchorEl, loading } = this.state;

    return (
      <LoadingOverlay show={loading}>
        <Button
          aria-owns={anchorEl ? styles['name-menu'] : undefined}
          aria-haspopup='true'
          onClick={event => this.setState({ anchorEl: event.currentTarget })}
          style={{ marginRight: '5px' }}
          data-testid='auto-NameMenu-select-button'
        >
          <div className={styles.NameMenu_name}>{userDetails.FirstName} {userDetails.MiddleName} {userDetails.LastName}</div>
          <Icon className={styles.NameMenu_icon}>{anchorEl ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
        </Button>

        <Menu
          id={styles['name-menu']}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.menuClose}
          style={{ top: '44px' }}
        >
          <MenuItem
            onClick={this.goToMyInfoHandle} data-testid='auto-NameMenu-MyInfoItem'
          >
            <span>My Info</span>
          </MenuItem>
          <MenuItem
            onClick={this.logoutClickHandle} data-testid='auto-NameMenu-LogOutItem'
          >
            Log out
          </MenuItem>
          <div className={styles.NameMenu_userDetails}>
            <hr />
            <div className={styles.NameMenu_detailRow}>
              <span>Last Login: {userDetails.LastLogin}</span>
            </div>
            <div className={styles.NameMenu_detailRow}>
              <span>CSA #: {taxableEntityId}</span>
            </div>
            <div className={styles.NameMenu_detailRow}>
              <span>PIN #: {userDetails.Pin}</span>
            </div>
          </div>
        </Menu>
      </LoadingOverlay>
    );
  }
}

export default withRouter(connect(select, {
  getUserInfo
})(NameMenu));
