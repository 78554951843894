import { cloneDeep } from 'lodash';
import {
  PASSWORD_REQUIREMENTS_GET,
  MILESTONES_GET,
  WEB_MESSAGES_GET,
  BANNER_LOCATIONS,
} from './constants';

const initialState = {
  passRequirements: [],
  milestones: [],
  // needs to be initialized to empty strings
  webMessages: Object.values(BANNER_LOCATIONS).reduce((obj, bannerLocation) => ({ ...obj, [bannerLocation]: '' }), {}),
};

function StaticResourcesReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case PASSWORD_REQUIREMENTS_GET: {
      newState.passRequirements = action.payload.data;
      return newState;
    }

    case MILESTONES_GET: {
      newState.milestones = action.payload.data;
      return newState;
    }

    case WEB_MESSAGES_GET: {
      const webMessageTypes = action.payload.data;
      // get only website message type messages
      const webMessageType = Boolean(webMessageTypes) && webMessageTypes.length > 0 && webMessageTypes.find(messageType => messageType.Name === 'WEBSITE MESSAGES');
      const webMessages = Boolean(webMessageType.WebsiteMessages) && webMessageType.WebsiteMessages.length > 0 && webMessageType.WebsiteMessages;
      // initialize all banners to empty strings each time api is called
      newState.webMessages = Object.values(BANNER_LOCATIONS).reduce((obj, bannerLocation) => ({ ...obj, [bannerLocation]: '' }), {});

      // distribute messages between banner locations (if exists will overwrite empty string)
      webMessages && webMessages.forEach(webMessage => {
        webMessage.WebsiteMessageURLs.forEach(websiteMessageURL => {
          newState.webMessages[`${websiteMessageURL.URL}/${websiteMessageURL.Page}`] = webMessage.Body;
        });
      });

      return newState;
    }

    default: {
      return state;
    }
  }

}

export default StaticResourcesReducer;
