/*
 * Programs Actions
 */
import {
  UPCOMING_TRANSACTIONS_GET,
  SCHEDULED_TRANSACTION_GET,
  TRANSACTION_DELETE
} from './constants';
import {
  GETrequest,
  DELETErequest,
} from 'utils/helpers/api_handler';

export function getUpcomingTransactions(masterAccountId) {
  const response = GETrequest(`/transactions/${masterAccountId}`);
  return {
    type: UPCOMING_TRANSACTIONS_GET,
    payload: response,
    meta: { accountId: masterAccountId },
  };
}

export function cancelTransaction(transaction) {
  const response = DELETErequest(transaction.url);
  return {
    type: TRANSACTION_DELETE,
    payload: response,
    meta: { accountId: transaction.accountId, transactionId: transaction.id, },
  };
}

export function getScheduledTransaction(transaction, accountId) {
  const response = GETrequest(transaction.url);
  return {
    type: SCHEDULED_TRANSACTION_GET,
    payload: response,
    meta: { type: transaction.typeName, accountId }
  };
}
