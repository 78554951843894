import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Bowser from 'bowser';

import { Icon } from '@mui/material';

import NameMenu from './NameMenu';
import { My529Logo } from '@frontend/common';

import {
  setBrowserWarning,
  setMobileWarning
} from 'components/AppRoot/Navigation/actions';

import styles from './styles.module.css';

const bowser = Bowser.getParser(window.navigator.userAgent).parsedResult;

const select = (state) => ({
  userDetails: state.session.userDetails,
  isValid: state.session.isValid,
  showBrowserWarning: state.session.showBrowserWarning,
  showMobileWarning: state.session.showMobileWarning,
  all: state
});

export class Header extends Component {

  static propTypes = {
    handleLogout: PropTypes.func.isRequired,
    openSideNav: PropTypes.func.isRequired,
    pageTitle: PropTypes.string,
    userDetails: PropTypes.object.isRequired,
    isValid: PropTypes.bool.isRequired,
    showBrowserWarning: PropTypes.bool.isRequired,
    showMobileWarning: PropTypes.bool.isRequired,
    setBrowserWarning: PropTypes.func.isRequired,
    setMobileWarning: PropTypes.func.isRequired,
  };

  warningsCompile() {
    if (this.props.showBrowserWarning && (bowser.browser.name === 'Internet Explorer')) {
      return this.warningMessageCompose('browser', 'You are currently using a browser that is not fully supported. For the best experience, please use Chrome or Firefox.');
    }
    else if (this.props.showMobileWarning && (bowser.mobile || bowser.tablet)) {
      return this.warningMessageCompose('mobile', 'Mobile browsing of this website is currently in beta. For full functionality, please use a desktop browser.');
    }
    else {
      return null;
    }
  }

  warningMessageCompose(warningType, message) {
    return (
      <div className={styles.Header_browserWarningContainer}>
        <span>{message}</span>
        <div
          className={styles.Header_browserWarningClose}
          onClick={() => this.dismissWarning(warningType)}
        >
          <Icon>close</Icon>
        </div>
      </div>
    );
  }

  dismissWarning(warningType) {
    switch (warningType) {
      case 'browser': {
        this.props.setBrowserWarning();
        break;
      }
      case 'mobile': {
        this.props.setMobileWarning();
        break;
      }
      default: {
        return null;
      }
    }
  }

  render() {

    if (this.props.isValid) {
      return (
        <div className={styles.Header_navContainer}>
          <header className={styles.Header_protectedNav}>
            <div className={styles.Header_left}>
              <div className={styles.Header_lighterBackground}>
                <div
                  className={styles.Header_menuIcon}
                  onClick={this.props.openSideNav}
                  data-testid='auto-Header-OpenSideNav'
                >
                  <Icon style={{ color: '#fff' }}>menu</Icon>
                </div>
                <div className={styles.Header_firmName}>
                  {this.props.userDetails.TaxableEntityName}
                </div>
              </div>
              <div className={styles.Header_arrow} />
              <div className={styles.Header_pageTitle}>
                {this.props.pageTitle}
              </div>
            </div>
            <div className={styles.Header_nameMenu}>
              <NameMenu
                onLogoutClick={this.props.handleLogout}
              />
            </div>
          </header>
          {this.warningsCompile()}
        </div>
      );
    }
    else {
      return (
        <div className={styles.Header_navContainer}>
          <header className={styles.Header_publicNav}>
            <a
              href='https://www.my529.org/'
              target='_self'
              rel='noopener noreferrer'
              style={{ paddingTop: '3px' }}
            >
              <My529Logo color='#fff' />
            </a>
          </header>
          {this.warningsCompile()}
        </div>
      );
    }
  }
}

export default connect(select, {
  setBrowserWarning,
  setMobileWarning
})(Header);
