import { cloneDeep } from 'lodash';

export function getTitle(path) {

  // Priority is placed to titles placed LOWER on this list
  const titles = [
    { matcher: /^\/$/, title: 'Home' },
    { matcher: '/accounts', title: 'Accounts' },
    { matcher: '/programs', title: 'Program Overview' },
    { matcher: '/withdrawals', title: 'Withdrawals' },
    { matcher: '/transfers', title: 'Transfers' },
    { matcher: '/reports', title: 'Reports' },
    { matcher: '/users', title: 'Manage Users' },
    { matcher: '/tags', title: 'Manage Tags' },
  ];

  let currentTitle;
  cloneDeep(titles).reverse().some(title => { // eslint-disable-line newline-per-chained-call
    if (path.match(title.matcher)) {
      currentTitle = title.title;
      return true;
    }

    return false;
  });

  const rootTitle = 'my529 CSA Portal';

  document.title = currentTitle ? `${currentTitle} | ${rootTitle}` : rootTitle;
  return currentTitle || rootTitle;
}
