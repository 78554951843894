import { cloneDeep } from 'lodash';
import {
  USERS_GET,
  PROGRAMS_GET,
} from './constants';

const initialState = {
  userList: [],
  programs: [],
};

function UserReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {
    case USERS_GET: {
      newState.userList = action.payload.data;
      return newState;
    }

    case PROGRAMS_GET: {
      newState.programs = action.payload.data.Programs.map(program => ({
        id: program.TaxableEntityProgramId,
        name: program.Name,
      }));
      return newState;
    }

    default: {
      return newState;
    }
  }

}

export default UserReducer;
