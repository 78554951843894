/*
*
* Transfers Constants
*
*/
import { currencyFormatter } from '@frontend/common';

export const TRANSFER_CREATE = 'src/components/Features/protected/Transfers/CreateTransfers/TRANSFER_CREATE';
export const TRANSFER_EDIT = 'src/components/Features/protected/Transfers/EditTransfers/TRANSFER_EDIT';
export const TRANSFER_GET = 'src/components/Features/protected/Transfers/EditTransfers/TRANSFER_GET';

/* Error Constants */
export const MINIMUM_AMOUNT_ERROR = 'Amount must be at least $1';
export const OVERDRAFT_ERROR = (masterValue) => `Total Amount must be less than Master Account Value of ${currencyFormatter(masterValue)}`;
export const MISSING_ACCOUNT_ERROR = 'Account must be selected';
export const MISSING_PROGRAM_ERROR = 'Program must be selected';
export const NO_PER_PROGRAM_ACCOUNTS = 'The selected program has no accounts';
export const REQUEST_TYPE = {
  CREATE: 'create',
  UPDATE: 'update'
};