import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep, isEqual } from 'lodash';
import dayjs from 'dayjs';

import {
  currencyFormatter,
  properCase,
  dateFormat,
  timeFormat,
  TableDataTypes,
  TableColumnHideOptions,
  TableRowActionTypes,
  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';

import ApproveWithdrawalModal from './ApproveWithdrawalModal';
import RejectWithdrawalModal from './RejectWithdrawalModal';
import EditWithdrawalAddress from './EditWithdrawalAddress';
import ViewWithdrawalModal from '../ViewWithdrawalModal';

import styles from './styles.module.css';

const viewDetailsMapping = {
  id: 'WithdrawalId',
  headers: {
    'Account': 'AccountNumber',
    'Beneficiary': 'BeneficiaryName',
    'Payable To': 'SchoolName',
    'Address': {
      id: '$id',
      headers: {
        'For The Benefit Of': 'StreetAddress1',
        'Street Address 1': 'StreetAddress2',
        'Street Address 2': 'StreetAddress3',
        'City, State, Zip': 'cityStateZip',
      }
    },
    'Account Balance': 'formattedAccountBalance',
    'Withdrawal Amount': 'WithdrawalAmount',
    'Requested On': 'requestedDate',
    'Department': 'SchoolDepartment',
  }
};

const select = (state) => ({
  userDetails: state.session.userDetails,
});


export class WithdrawalsPending extends React.Component {

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    tableData: PropTypes.array.isRequired,
    tableDataGet: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
  };

  state = {
    approveOpen: false,
    editAddressOpen: false,
    id: '',
    rejectOpen: false,
    viewOpen: false,
    tableData: this.tableDataFormat(cloneDeep(this.props.tableData)),
  };

  addressInfoCompose() {
    const headers = viewDetailsMapping.headers.Address.headers;
    const info = [];
    const record = this.withdrawalRecordGet();
    let uniqueId = 1;

    for (const header in headers) {
      for (const prop in record.SchoolAddress) {
        if (prop === headers[header]) {

          info.push(
            <tbody key={uniqueId}>
              {record.SchoolAddress[headers[header]] ?
                <tr>
                  <th className={styles.WithdrawlsPending_withdrawalInfoHeader}>{header}</th>
                  <td className={styles.WithdrawlsPending_withdrawalInfoRow} style={{ color: 'var(--text)' }}>{`${record.SchoolAddress[headers[header]]}`}</td>
                </tr>
                :
                null
              }
            </tbody>
          );
        }
        uniqueId++;
      }
    }
    return info;
  }

  tableDataFormat(tableData) {

    tableData.forEach(data => {
      data.BeneficiaryName = properCase(data.BeneficiaryName);
      data.SchoolName = properCase(data.SchoolName);
      data.ProgramName = properCase(data.ProgramName);

      // Address formatting
      data.SchoolAddress.StreetAddress1 = data.SchoolAddress.StreetAddress1.includes('FBO')
        ? `${data.SchoolAddress.StreetAddress1.split(' ')[0]} ${properCase(data.SchoolAddress.StreetAddress1.split('FBO ')[1])}`
        : properCase(data.SchoolAddress.StreetAddress1);
      data.SchoolAddress.StreetAddress2 = properCase(data.SchoolAddress.StreetAddress2);
      data.SchoolAddress.SchoolDepartment = properCase(data.SchoolDepartment);
      data.SchoolAddress.StreetAddress3 = properCase(data.SchoolAddress.StreetAddress3);
      data.SchoolAddress.City = properCase(data.SchoolAddress.City);
      data.SchoolAddress.cityStateZip = `${data.SchoolAddress.City}, ${data.SchoolAddress.State} ${data.SchoolAddress.Zip}`;

      // Misc formatting
      data.formattedAccountBalance = currencyFormatter(data.AccountBalance);
      if (!data.WithdrawalAmount === 'full balance') {
        data.WithdrawalAmount = currencyFormatter(data.WithdrawalAmount);
      }
    });

    return tableData;
  }

  withdrawalRecordGet = () => {
    return this.state.tableData.find(data => data.WithdrawalId === parseInt(this.state.id)) || {};
  }

  withdrawalInfoCompose = () => {
    const headers = viewDetailsMapping.headers;
    const info = [];
    const record = this.withdrawalRecordGet();
    let uniqueId = 1;

    for (const header in headers) {
      for (const prop in record) {
        if (prop === headers[header]) {
          let dataValue = record[headers[header]];
          if (record[headers[header]] instanceof Date) {
            dataValue = `${dateFormat(record[headers[header]])} ${timeFormat(record[headers[header]])}`;
          }

          info.push(
            <tbody key={uniqueId}>
              <tr>
                <th className={styles.WithdrawlsPending_withdrawalInfoHeader}>{header}:</th>
                <td className={styles.WithdrawlsPending_withdrawalInfoRow} style={{ color: 'var(--text)' }}>{dataValue}</td>
              </tr>
            </tbody>
          );
        }
        uniqueId++;
      }
    }
    return [info, this.addressInfoCompose()];
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.tableData, prevProps.tableData)) {
      this.setState({ tableData: this.tableDataFormat(cloneDeep(this.props.tableData)) });
    }
  }

  render() {
    const { CanApproveWithdrawals } = this.props.userDetails;
    return (
      <div>
        <SmartTable
          idKey='WithdrawalId'
          emptyMessage='No pending withdrawals to display.'
          loading={this.props.loading}
          rows={this.state.tableData}
          actions={[
            {
              displayName: 'Approve',
              type: TableRowActionTypes.ROW_MENU,
              onSelect: ({ WithdrawalId: id }) => this.setState({ approveOpen: true, id }),
              showIf: () => CanApproveWithdrawals,
            },
            {
              displayName: 'Reject',
              type: TableRowActionTypes.ROW_MENU,
              onSelect: ({ WithdrawalId: id }) => this.setState({ rejectOpen: true, id }),
              showIf: () => CanApproveWithdrawals,
            },
            {
              displayName: 'View Details',
              type: TableRowActionTypes.ROW_MENU,
              onSelect: ({ WithdrawalId: id }) => this.setState({ viewOpen: true, id }),
            },
            {
              displayName: 'Edit Address',
              type: TableRowActionTypes.ROW_MENU,
              onSelect: ({ WithdrawalId: id }) => this.setState({ editAddressOpen: true, id }),
              showIf: () => CanApproveWithdrawals,
            },
          ]}
          columns={[
            {
              key: 'AccountNumber',
              title: 'Account Number',
              type: TableDataTypes.NUMBER,
              hideOn: [TableColumnHideOptions.PHONE, TableColumnHideOptions.TABLET],
            },
            {
              key: 'BeneficiaryName',
              title: 'Beneficiary',
              type: TableDataTypes.STRING,
            },
            {
              key: 'SchoolName',
              title: 'Payable To',
              type: TableDataTypes.STRING,
              hideOn: [TableColumnHideOptions.PHONE, TableColumnHideOptions.TABLET],
            },
            {
              key: 'AccountBalance',
              title: 'Account Balance',
              type: TableDataTypes.CURRENCY,
              hideOn: [TableColumnHideOptions.PHONE],
            },
            {
              key: 'WithdrawalAmount',
              title: 'Withdrawal Amount',
              type: TableDataTypes.STRING,
            },
            {
              key: 'CreatedDate',
              title: 'Requested On',
              type: TableDataTypes.DATE_STRING,
              format: date => dayjs(date).format('MM/DD/YYYY HH:mm A'),
              hideOn: [TableColumnHideOptions.PHONE],
            },
            {
              key: 'ProgramName',
              title: 'Program',
              type: TableDataTypes.STRING,
              hideOn: [TableColumnHideOptions.PHONE],
            },
          ]}
        >
          <TableToolbar />
          <TableContainer maxHeight='100%'>
            <TableHeader />
            <TableRows />
          </TableContainer>
          <TablePagination />
        </SmartTable>

        <ApproveWithdrawalModal
          id={this.state.id}
          onCloseModal={() => this.setState({ approveOpen: false })}
          show={this.state.approveOpen}
          tableDataGet={this.props.tableDataGet}
          withdrawalInfo={this.withdrawalInfoCompose()}
        />
        <RejectWithdrawalModal
          id={this.state.id}
          onCloseModal={() => this.setState({ rejectOpen: false })}
          show={this.state.rejectOpen}
          tableDataGet={this.props.tableDataGet}
          withdrawalInfo={this.withdrawalInfoCompose()}
        />
        <EditWithdrawalAddress
          id={this.state.id}
          onCloseModal={() => this.setState({ editAddressOpen: false })}
          show={this.state.editAddressOpen}
          tableDataGet={this.props.tableDataGet}
          withdrawalRecordGet={this.withdrawalRecordGet}
        />
        <ViewWithdrawalModal
          show={this.state.viewOpen}
          onCloseModal={() => this.setState({ viewOpen: false })}
          withdrawalInfo={this.withdrawalInfoCompose()}
          id={this.state.id}
        />
      </div>
    );
  }
}

export default connect(select, {})(WithdrawalsPending);
