export const USER_GET = 'app/Features/protected/ManageUsers/USER_GET';
export const USERS_GET = 'app/Features/protected/ManageUsers/USERS_GET';
export const USER_CREATE = 'app/Features/protected/ManageUsers/USER_CREATE';
export const USER_UPDATE = 'app/Features/protected/ManageUsers/USER_UPDATE';
export const USER_DELETE = 'app/Features/protected/ManageUsers/USER_DELETE';

export const TOGGLE_ADMIN = 'app/Features/protected/ManageUsers/TOGGLE_ADMIN';

export const RESEND_EMAIL = 'app/Features/protected/ManageUsers/RESEND_EMAIL';
export const PROGRAMS_GET = 'app/Features/protected/ManageUsers/PROGRAMS_GET';

export const USER_ROLES = {
  PROGRAM_MANAGER: 'Program Manager',
  ADMINISTRATOR: 'Administrator',
  ENTITY_AGENT: 'Entity Account Agent',
};

