import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {
  TextField,
  Button,
  InputAdornment,
} from '@mui/material';

import {
  PasswordRequirements,
  notificationShow,
  LoadingOverlay,
  IconBtnTooltip
} from '@frontend/common';

import {
  changePassword,
} from './actions';
import { passwordRequirementsGet } from 'components/AppRoot/StaticResources/actions';

import styles from './styles.module.css';

const select = (state) => ({
  passwordRequirements: state.staticResources.passRequirements,
});

export class ChangePassword extends Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
    passwordRequirements: PropTypes.array,
    passwordRequirementsGet: PropTypes.func.isRequired,
  };

  state = {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
    errors: [],
    formIsValid: false,
    passwordPassesValidation: false,
    loading: false,
    initialLoading: false,
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  };

  clearInputs = () => {
    this.setState({
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    }, () => {
      this.passwordFormValidate();
    });
  }

  onPasswordInputChange = (inputName, newValue) => {
    this.setState({
      [inputName]: newValue
    }, () => {
      this.passwordFormValidate();
    });
  }

  passwordChangeSubmit = () => {
    const newPasswordData = {
      oldpassword: this.state.currentPassword,
      newpassword1: this.state.newPassword,
      newpassword2: this.state.newPasswordConfirm,
    };

    this.setState({ loading: true });
    this.props.changePassword(newPasswordData)
      .then(() => {
        this.props.notificationShow('Password changed.', 'success');
        this.clearInputs();
      })
      .catch(() => null)
      .finally(() => this.setState({ loading: false }));
  }

  passwordErrorTextGet = (inputName) => {
    const error = this.state.errors.find(error => error.field === inputName);
    return error ? error.message : '';
  }

  passwordFormValidate = () => {
    const errors = [];
    let isValid = false;

    if (this.state.newPasswordConfirm && this.state.newPassword !== this.state.newPasswordConfirm) {
      errors.push({ field: 'newPasswordConfirm', message: 'Passwords must match.' });
      isValid = false;
    }
    if (this.state.newPassword && !this.state.passwordPassesValidation) {
      errors.push({ field: 'newPassword', message: 'Password does not meet below criteria.' });
      isValid = false;
    }
    /* eslint-disable indent */
    if (
      this.state.currentPassword
      && this.state.newPassword
      && this.state.newPasswordConfirm
      && this.state.passwordPassesValidation
      && this.state.newPassword === this.state.newPasswordConfirm) {
      isValid = true;
    }
    /* eslint-enable indent */

    this.setState({
      formIsValid: isValid,
      errors,
    });
  }

  handleClickShowPassword = (keyName) => {
    this.setState({ [keyName]: !this.state[keyName] });
  }

  componentDidMount() {
    const { passwordRequirements } = this.props;
    if (passwordRequirements.length === 0) {
      this.setState({ initialLoading: true });
      this.props.passwordRequirementsGet()
        .catch(() => null)
        .finally(() => this.setState({ initialLoading: false }));
    }
  }

  render() {
    const {
      loading, initialLoading, showCurrentPassword, showNewPassword, showConfirmPassword,
      formIsValid, currentPassword, newPassword, newPasswordConfirm
    } = this.state;
    const formErrors = {
      currentPassword: this.passwordErrorTextGet('currentPassword'),
      newPassword: this.passwordErrorTextGet('newPassword'),
      newPasswordConfirm: this.passwordErrorTextGet('newPasswordConfirm'),
    };

    return (
      <LoadingOverlay
        show={initialLoading}
        indicatorHeight='10px'
        width='100%'
      >
        <div className={styles.ChangePassword_passwordForm}>
          <TextField
            name='currentPassword'
            type={showCurrentPassword ? 'text' : 'password'}
            label='Current Password'
            value={currentPassword}
            disabled={loading}
            onChange={(e) => this.onPasswordInputChange('currentPassword', e.target.value)}
            error={Boolean(formErrors.currentPassword)}
            helperText={formErrors.currentPassword}
            fullWidth
            autoFocus
            inputProps={{ 'data-testid': 'auto-MyInfo-ChangePassword-currentPassword-input' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconBtnTooltip
                    icon={showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                    onClick={() => this.handleClickShowPassword('showCurrentPassword')}
                    title={'Toggle password visibility'}
                    buttonProps={{ 'data-testid': 'auto-MyInfo-ChangePassword-togglePasswordVisibility-button' }}
                  />
                </InputAdornment>
              )
            }}
          />
          <TextField
            name='newPassword'
            type={showNewPassword ? 'text' : 'password'}
            label='New Password'
            value={newPassword}
            disabled={loading}
            onChange={(e) => this.onPasswordInputChange('newPassword', e.target.value)}
            error={Boolean(formErrors.newPassword)}
            helperText={formErrors.newPassword}
            fullWidth
            inputProps={{ 'data-testid': 'auto-MyInfo-ChangePassword-newPassword-input' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconBtnTooltip
                    icon={showNewPassword ? <Visibility /> : <VisibilityOff />}
                    onClick={() => this.handleClickShowPassword('showNewPassword')}
                    title={'Toggle password visibility'}
                    buttonProps={{ 'data-testid': 'auto-MyInfo-ChangePassword-toggleNewPasswordVisibility-toolTipButton' }}
                  />
                </InputAdornment>
              )
            }}
          />
          <TextField
            name='newPasswordConfirm'
            type={showConfirmPassword ? 'text' : 'password'}
            label='Confirm New Password'
            value={newPasswordConfirm}
            disabled={loading}
            onChange={(e) => this.onPasswordInputChange('newPasswordConfirm', e.target.value)}
            error={Boolean(formErrors.newPasswordConfirm)}
            helperText={formErrors.newPasswordConfirm}
            fullWidth
            inputProps={{ 'data-testid': 'auto-MyInfo-ChangePassword-confirmPassword-input' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconBtnTooltip
                    icon={showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    onClick={() => this.handleClickShowPassword('showConfirmPassword')}
                    title={'Toggle password visibility'}
                    buttonProps={{ 'data-testid': 'auto-MyInfo-ChangePassword-toggleConfirmPasswordVisibility-toolTipButton' }}
                  />
                </InputAdornment>
              )
            }}
          />
          <div className={styles.ChangePassword_passwordRequirements}>
            <PasswordRequirements
              password={newPassword}
              passwordRequirements={this.props.passwordRequirements}
              onPasswordCheck={passes => this.onPasswordInputChange('passwordPassesValidation', passes)}
            />
          </div>
          <div className={styles.ChangePassword_button}>
            <LoadingOverlay show={loading}>
              <Button
                type='submit'
                variant='contained'
                onClick={this.passwordChangeSubmit}
                disabled={!formIsValid || loading || initialLoading}
                data-testid='auto-MyInfo-ChangePassword-submit-button'
              >
                Change Password
              </Button>
            </LoadingOverlay>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default connect(select, {
  changePassword,
  notificationShow,
  passwordRequirementsGet,
})(ChangePassword);
