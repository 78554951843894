
export const ACCOUNT_ADDRESS_CREATE = 'app/Features/protected/Accounts/ACCOUNT_ADDRESS_CREATE';
export const ACCOUNT_ADDRESS_UPDATE = 'app/Features/protected/Accounts/ACCOUNT_ADDRESS_UPDATE';
export const ACCOUNT_ADDRESS_DELETE = 'app/Features/protected/Accounts/ACCOUNT_ADDRESS_DELETE';

export const ACCOUNTS_GET = 'app/Features/protected/Accounts/ACCOUNTS_GET';
export const ACCOUNT_GET = 'app/Features/protected/Accounts/ACCOUNT_GET';
export const ACCOUNTS_BY_PROGRAM_GET = 'app/Features/protected/Accounts/ACCOUNTS_BY_PROGRAM_GET';

export const INTERESTED_PARTY_ADD = 'app/Features/protected/Accounts/INTERESTED_PARTY_ADD';
export const INTERESTED_PARTY_REMOVE = 'app/Features/protected/Accounts/INTERESTED_PARTY_REMOVE';
export const INTERESTED_PARTY_RESEND = 'app/Features/protected/Accounts/INTERESTED_PARTY_RESEND';

export const STUDENT_NUMBER_UPDATE = 'app/Features/protected/Accounts/STUDENT_NUMBER_UPDATE';

export const TRANSFER_DETAILS_GET = 'app/Features/protected/Accounts/TRANSFER_DETAILS_GET';

export const addressTypes = {
  MAILING: 'Mailing',
  PHYSICAL: 'Physical',
  OTHER: 'Other',
};

export const personTypes = {
  BENE: 'beneficiary',
  AGENT: 'agent',
};