import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import {
  Modal,
  notificationShow,
  LoadingOverlay,
} from '@frontend/common';
import { TextField } from '@mui/material';

import { renameTag } from '../actions';

import styles from './styles.module.css';

export class RenameTagsModal extends React.Component {

  static propTypes = {
    availableTags: PropTypes.arrayOf(PropTypes.object).isRequired,
    loadingOnMount: PropTypes.bool.isRequired,
    notificationShow: PropTypes.func.isRequired,
    renameTag: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    tagsGet: PropTypes.func,
    tagSelected: PropTypes.object.isRequired,
  };

  state = {
    loading: false,
    tagSelected: {},
    tagSelectedRenamed: '',
    error: '',
  };

  isTagDuplicate(tagToUpdate) {
    return this.props.availableTags.some(tag => tag.Tag.toLowerCase() === tagToUpdate.toLowerCase());
  }

  onModalClose() {
    this.setState({ tagSelectedRenamed: '' });
    this.props.onClose();
  }

  tagRename() {
    const { tagSelectedRenamed } = this.state;
    if (this.isTagDuplicate(tagSelectedRenamed)) {
      this.setState({ error: 'A tag with that name already exists.' });
    }
    else {
      this.setState({ loading: true });
      this.props.renameTag([{
        Tag: tagSelectedRenamed,
        TaxableEntityTagId: this.props.tagSelected.id,
      }])
        .then(() => {
          this.onModalClose();
          this.props.notificationShow('Tag renamed.', 'success');
          this.props.tagsGet();
        })
        .catch(() => null)
        .finally(() => this.setState({ loading: false }));
    }
  }

  tagRenameHandle = (e) => {
    this.setState({
      tagSelectedRenamed: e.target.value,
      error: '',
    });
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props;
    if (open && !isEqual(this.props.tagSelected, this.state.tagSelected)) {
      this.setState({
        tagSelected: this.props.tagSelected,
        tagSelectedRenamed: this.props.tagSelected.name,
        error: '',
      });
    }

    if (!open && open !== prevProps.open) {
      this.setState({
        tagSelected: {},
        tagSelectedRenamed: '',
        error: '',
      });
    }
  }

  render() {
    const { error } = this.state;
    return (
      <div >
        <Modal
          actionButtons={[
            {
              label: 'Cancel',
              action: () => this.onModalClose(),
              disabled: this.state.loading,
            },
            {
              action: () => this.tagRename(),
              disabled: this.state.tagSelectedRenamed.length === 0 || this.props.tagSelected.name === this.state.tagSelectedRenamed || Boolean(this.state.error),
              label: 'Rename',
              loading: this.state.loading,
              buttonType: 'contained',
            }
          ]}
          onCloseModal={() => this.onModalClose()}
          show={this.props.open}
          title={'Rename Tag'}
        >
          {this.props.loadingOnMount ?
            <div className={styles.RenameTags_modalBody}>
              <LoadingOverlay
                show
                width='100%'
                indicatorHeight='10px'
              />
            </div>
            :
            <form className={styles.RenameTags_modalBody} data-testid='auto-RenameTagsModal-submit-form'>
              <TextField
                label='Tag Name'
                name={this.props.tagSelected.name}
                value={this.state.tagSelectedRenamed}
                onChange={this.tagRenameHandle}
                error={Boolean(error)}
                helperText={error}
                inputProps={{ maxLength: 50, 'data-testid': 'auto-RenameTagsModal-tagRename-input' }}
                fullWidth
                autoFocus
              />
            </form>
          }
        </Modal>
      </div>
    );
  }
}

export default connect(null, { renameTag, notificationShow })(RenameTagsModal);
