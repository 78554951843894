import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MuiPhoneNumber from 'material-ui-phone-number-2';

import {
  Button,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material';

import {
  InfoIcon,
  LoadingOverlay
} from '@frontend/common';

import { REGISTER_METHODS } from '../../constants.js';

import styles from '../../styles.module.css';

export class RegisterPhoneAndMethod extends Component {

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    defaultMethod: PropTypes.string.isRequired,
    defaultPhoneNumber: PropTypes.string.isRequired,
    defaultCountry: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  state = {
    value: {
      phoneNumber: this.props.defaultPhoneNumber,
      country: this.props.defaultCountry,
      method: this.props.defaultMethod,
    },
    errors: {
      phoneNumber: '',
      method: '',
    },
  };

  onSubmit = () => {
    const { value } = this.state;

    // error checking here
    const errors = {
      phoneNumber: '',
      method: '',
    };

    if (value.phoneNumber === '' || value.phoneNumber === '+1') {
      errors.phoneNumber = 'Phone is required.';
    }

    if (value.method === '') {
      errors.method = '2FA method is required.';
    }

    this.setState({ errors });
    const hasError = Object.values(errors).some(err => err !== '');
    this.props.onSubmit(value, hasError); // for parent
  }

  onPhoneChange = (phoneNumber, country) => {
    const value = { ...this.state.value };
    const errors = { ...this.state.errors };
    errors.phoneNumber = '';
    value.phoneNumber = phoneNumber;
    value.country = country;
    this.setState({ value, errors });
  }

  onMethodChange = (method) => {
    const value = { ...this.state.value };
    const errors = { ...this.state.errors };
    value.method = method;
    errors.method = '';
    this.setState({ value, errors });
  };

  render() {
    const { isLoading } = this.props;
    const { value, errors } = this.state;

    const isDomestic = value.country.dialCode.toString() === '1';

    return (
      <>
        <h3>Register for Two Factor Authentication</h3>

        <div className={styles.intructionSteps_container}>
          <div className={styles.intructionSteps_body}>Step 1: Please enter the phone number you would like to use to register for 2FA.</div>
        </div>

        <MuiPhoneNumber
          disabled={isLoading}
          defaultCountry={'us'}
          onChange={this.onPhoneChange}
          disableAreaCodes={true}
          error={Boolean(errors.phoneNumber)}
          helperText={errors.phoneNumber}
          label='Enter Phone Number'
          variant='filled'
          autoFocus={true}
          style={{ marginBottom: '20px' }}
          value={value.phoneNumber}
        />

        <div className={styles.intructionSteps_container}>
          <div className={styles.intructionSteps_body}>Step 2: Choose a registration method you would like to use in order to register. You will be able to select a different method when you are later prompted to use 2FA.</div>
        </div>

        <div className={styles.methods}>
          <RadioGroup
            disabled={isLoading}
            aria-label='registration method'
            name='method'
            value={value.method}
            onChange={(e) => this.onMethodChange(e.target.value)}
          >
            {isDomestic &&
              <>
                <FormControlLabel value={REGISTER_METHODS.SMS} control={<Radio />} label='Text Me' style={{ marginTop: '5px' }} />
                <FormControlLabel value={REGISTER_METHODS.CALL} control={<Radio />} label='Call Me' style={{ marginTop: '5px' }} />
              </>
            }
            <div style={{ textAlign: 'left' }}>
              <div>
                <FormControlLabel value={REGISTER_METHODS.INAPP} control={<Radio />} label='Authy' />
                <InfoIcon message="To receive push notifications or a security code (token), install the Authy Authenticator app on your phone. Download the app from your phone's app store." />
              </div>
              <div>
                <FormControlLabel value={REGISTER_METHODS.INAPPTOKEN} control={<Radio />} label='Other Authenticator' />
                <InfoIcon message="To receive a security code (token), install an authenticator app such as Google Authenticator or Microsoft Authenticator on your phone. Download an app from your phone's app store." />
              </div>
            </div>

          </RadioGroup>
          <FormHelperText error={Boolean(errors.method)}>{errors.method}</FormHelperText>
        </div>

        <div className={styles.buttons}>
          <LoadingOverlay show={isLoading} width='100%'>
            <Button
              variant='contained'
              onClick={this.onSubmit}
              fullWidth
            >
              Register
            </Button>
          </LoadingOverlay>
        </div>
      </>
    );
  }
}

export default RegisterPhoneAndMethod;