import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AccountSearchBar from './AccountsSearchBar';
import ProgramsList from './ProgramsList';
import Banner from 'components/Features/Banner';
import { BANNER_LOCATIONS } from 'components/AppRoot/StaticResources/constants';

import styles from './styles.module.css';

const select = (state) => ({
  webMessages: state.staticResources.webMessages,
});

export class Dashboard extends Component {

  static propTypes = {
    webMessages: PropTypes.object.isRequired,
  };

  render() {
    const { webMessages } = this.props;

    return (
      <div className={styles.Dashboard_container}>
        <Banner show={Boolean(webMessages[BANNER_LOCATIONS.HOME])} body={webMessages[BANNER_LOCATIONS.HOME]} />
        <div className={styles.Dashboard_accountSearchContainer}>
          <AccountSearchBar />
        </div>
        <ProgramsList />
      </div>
    );
  }
}

export default connect(select, {})(Dashboard);
