import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { notificationShow } from '@frontend/common';
import { Tabs, Tab } from '@mui/material';

import { getPending, getHistory } from './actions';

import WithdrawalPending from './WithdrawalPending';
import WithdrawalHistory from './WithdrawalHistory';


const select = (state) => ({
  pending: state.withdrawals.pending,
  history: state.withdrawals.history,
});

export class Withdrawals extends React.Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    getPending: PropTypes.func.isRequired,
    getHistory: PropTypes.func.isRequired,
    pending: PropTypes.array.isRequired,
    history: PropTypes.array.isRequired,
  };

  state = {
    pendingLoading: false,
    historyLoading: false,
    activeTab: 'pending',
  };

  tableDataGet() {
    this.setState({
      pendingLoading: true,
      historyLoading: true,
    });
    Promise.all([
      this.props.getPending(),
      this.props.getHistory(),
    ])
      .catch(() => null)
      .finally(() => this.setState({ pendingLoading: false, historyLoading: false }));
  }

  componentDidMount() {
    this.tableDataGet();
  }

  render() {
    const { activeTab } = this.state;
    return (
      <div>
        <Tabs
          value={activeTab}
          onChange={(e, tab) => this.setState({ activeTab: tab })}
          variant='fullWidth'
          style={{ marginBottom: '15px' }}
        >
          <Tab
            label='Pending Withdrawals'
            value='pending'
            data-testid='auto-Withdrawals-pendingWithdrawals-tabButton'
          />
          <Tab
            label='Withdrawal History'
            value='history'
            data-testid='auto-Withdrawals-historyWithdrawals-tabButton'
          />
        </Tabs>
        {activeTab === 'pending' && (
          <WithdrawalPending
            tableData={this.props.pending}
            tableDataGet={() => this.tableDataGet()}
            loading={this.state.pendingLoading}
          />
        )}
        {activeTab === 'history' && (
          <WithdrawalHistory
            tableData={this.props.history}
            tableDataGet={() => this.tableDataGet()}
            loading={this.state.historyLoading}
          />
        )}
      </div>
    );
  }
}

export default connect(select, {
  notificationShow,
  getPending,
  getHistory,
})(Withdrawals);