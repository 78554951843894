import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  TextField,
  Button,
} from '@mui/material';

import {
  My529Logo,
  InfoIcon,
  IconBtnTooltip,
  LoadingOverlay,
  notificationShow,
  // properCase,
} from '@frontend/common';

import { getNameError } from 'utils/helpers/formValidators';

import { updateUserInfo } from 'components/AppRoot/Navigation/actions';

import styles from '../styles.module.css';

const select = (state) => ({
  userDetails: state.session.userDetails,
});

export class NameHeaderWithEdit extends React.Component {
  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    updateUserInfo: PropTypes.func.isRequired,
  };

  state = {
    showNameEdit: false,
    firstName: '',
    lastName: '',
    firstNameError: '',
    lastNameError: '',
    submitting: false,
  }

  hasFormErrors = () => {
    const { firstName, lastName } = this.state;
    let firstNameError = '';
    let lastNameError = '';

    firstNameError = getNameError(firstName);
    lastNameError = getNameError(lastName);

    this.setState({ firstNameError, lastNameError });
    return Boolean(firstNameError) || Boolean(lastNameError);
  }

  isSubmitDisabled = () => {
    const { firstName, lastName, firstNameError, lastNameError } = this.state;
    const { userDetails: { FirstName, LastName } } = this.props;

    // disable not only on errors but also if nothing changed
    return (
      Boolean(firstNameError)
      ||
      Boolean(lastNameError)
      ||
      (
        firstName.toLowerCase() === FirstName.toLowerCase() &&
        lastName.toLowerCase() === LastName.toLowerCase()
      )
    );
  }

  onNameSubmit = (e) => {
    e.preventDefault();

    if (!this.hasFormErrors()) {
      const { firstName, lastName } = this.state;
      const { userDetails: { PhoneNumber }, updateUserInfo, notificationShow } = this.props;

      const updatedUser = {
        FirstName: firstName,
        LastName: lastName,
        PhoneNumber
      };
      this.setState({ submitting: true });
      updateUserInfo(updatedUser)
        .then(() => {
          notificationShow('User\'s name updated.', 'success');
        })
        .finally(() => this.setState({ submitting: false }));
    }
  }

  onNameChange = (keyName, value) => {
    // set value and any error
    this.setState({ [keyName]: value }, () => this.hasFormErrors());
  }

  onCancel = () => {
    const { userDetails } = this.props;

    // also clear form errors and set name to the default one
    this.setState({
      showNameEdit: false,
      firstNameError: '',
      lastNameError: '',
      firstName: userDetails.FirstName,
      lastName: userDetails.LastName
    });
  }

  componentDidMount() {
    // set advisor name to its default values if already in Redux
    const { userDetails: { FirstName, LastName } } = this.props;

    this.setState({
      firstName: FirstName,
      lastName: LastName
    });
  }

  componentDidUpdate(prevProps) {
    // once adviser details loads set advisor name to its default values if not already in Redux
    const { userDetails: { FirstName, LastName } } = this.props;

    if (prevProps.userDetails.FirstName !== FirstName || prevProps.userDetails.LastName !== LastName) {
      this.setState({
        firstName: FirstName,
        lastName: LastName
      });
    }
  }

  render() {
    const {
      userDetails: { IsEntityAgent, userRole, FirstName, LastName, MiddleName },
    } = this.props;

    const {
      showNameEdit, firstName, lastName,
      firstNameError, lastNameError, submitting
    } = this.state;

    return (
      <div className={styles.myInfo_header}>
        <div>
          <div>{userRole}</div>
          <div className={styles.myInfo_nameContainer}>
            <h2 className={styles.myInfo_name}>
              {!showNameEdit
                ?
                `${FirstName} ${MiddleName} ${LastName}`
                :
                (
                  <form className={styles.myInfo_nameEdit} onSubmit={this.onNameSubmit} data-testid='auto-MyInfo-NameHeaderWithEdit-submit-form'>
                    <div>
                      <TextField
                        label='First Name'
                        name='firstName'
                        value={firstName}
                        onChange={(e) => this.onNameChange('firstName', e.target.value)}
                        inputProps={{ 'data-testid': 'auto-MyInfo-NameHeaderWithEdit-firstname-input' }}
                        error={Boolean(firstNameError)}
                        helperText={firstNameError}
                        disabled={submitting}
                      />
                    </div>
                    <div>
                      <TextField
                        label='Last Name'
                        name='lastName'
                        value={lastName}
                        onChange={(e) => this.onNameChange('lastName', e.target.value)}
                        inputProps={{ 'data-testid': 'auto-MyInfo-NameHeaderWithEdit-lastName-input' }}
                        error={Boolean(lastNameError)}
                        helperText={lastNameError}
                        disabled={submitting}
                      />
                    </div>
                    <div className={styles.myInfo_nameEditButton}>
                      <LoadingOverlay show={submitting}>
                        <Button
                          type='submit'
                          variant='contained'
                          disabled={this.isSubmitDisabled()}
                          data-testid='auto-MyInfo-NameHeaderWithEdit-update-button'
                        >
                          Update
                        </Button>
                      </LoadingOverlay>
                    </div>
                    <div className={styles.myInfo_nameEditButton}>
                      <Button
                        variant='outlined'
                        onClick={this.onCancel}
                        disabled={submitting}
                        data-testid='auto-MyInfo-NameHeaderWithEdit-cancel-button'
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                )

              }
            </h2>
            {IsEntityAgent
              ?
              <InfoIcon tippyProps={{ interactive: true }} message={<span>Please contact my529 at <a href='mailto:CSA@my529.org'>CSA@my529.org</a> for information about changing the Entity Agent.</span>} />
              :
              <div className={styles.myInfo_nameEditIcon}>
                {!showNameEdit &&
                  <IconBtnTooltip
                    icon='edit'
                    onClick={() => this.setState({ showNameEdit: true })}
                    title='Change user&apos;s name'
                    buttonProps={{ 'data-testid': 'auto-MyInfo-NameHeaderWithEdit-showNameEdit-toolTipButton' }}
                  />
                }

              </div>
            }
          </div>
        </div>
        <div><My529Logo /></div>
      </div>
    );
  }
}

export default connect(select, {
  updateUserInfo,
  notificationShow
})(NameHeaderWithEdit);
