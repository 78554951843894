import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  withRouter,
  Prompt
} from 'react-router-dom';

import {
  cloneDeep,
  isEqual,
  set,
  every,
  pick
} from 'lodash';

import {
  Chip,
  ConfirmModal,
  notificationShow,
  allNotificationsHide,
  currencyFormatter,
  properCase,
  LoadingOverlay,
  SmartTable,
  TableDataTypes,
  TableColumnHideOptions,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';
import {
  Button,
  Icon,
  Tabs,
  Tab,
  TextField,
  Collapse,
} from '@mui/material';

import {
  accountAddressCreate,
  accountAddressUpdate,
  accountAddressDelete,
  getAccount,
  interestedPartyAdd,
  interestedPartyRemove,
  interestedPartyResend,
  studentNumberUpdate,
} from '../actions';
import { tagsUnassign } from 'components/Features/protected/ManageTags/actions';

import AccountDetailsCard from './AccountDetailsCard';
import AddInterestedPartyModal from './AddInterestedPartyModal';
import TagModal from '../TagModal/index';
import { addressTypes, personTypes } from '../constants';

import styles from './styles.module.css';

const select = (state) => ({
  account: state.accounts.account,
  canApproveWithdrawals: state.session.userDetails.CanApproveWithdrawals,
});


export class AccountView extends React.Component {

  static propTypes = {
    accountAddressCreate: PropTypes.func.isRequired,
    accountAddressUpdate: PropTypes.func.isRequired,
    accountAddressDelete: PropTypes.func.isRequired,
    getAccount: PropTypes.func.isRequired,
    interestedPartyAdd: PropTypes.func.isRequired,
    interestedPartyRemove: PropTypes.func.isRequired,
    interestedPartyResend: PropTypes.func.isRequired,
    studentNumberUpdate: PropTypes.func.isRequired,
    tagsUnassign: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
    canApproveWithdrawals: PropTypes.bool.isRequired,
  };

  state = {
    beneficiary: {
      name: '',
      addresses: {
        [addressTypes.MAILING]: {
          AddressId: 0,
          StreetAddress1: '',
          StreetAddress2: '',
          City: '',
          State: '',
          Zip: '',
        },
        [addressTypes.PHYSICAL]: {
          AddressId: 0,
          StreetAddress1: '',
          StreetAddress2: '',
          City: '',
          State: '',
          Zip: '',
        },
        [addressTypes.OTHER]: {
          AddressId: 0,
          StreetAddress1: '',
          StreetAddress2: '',
          City: '',
          State: '',
          Zip: '',
        },
      },
    },
    agent: {
      name: '',
      userIsAgent: false,
      addresses: {
        [addressTypes.MAILING]: {
          AddressId: 0,
          StreetAddress1: '',
          StreetAddress2: '',
          City: '',
          State: '',
          Zip: '',
        },
        [addressTypes.PHYSICAL]: {
          AddressId: 0,
          StreetAddress1: '',
          StreetAddress2: '',
          City: '',
          State: '',
          Zip: '',
        },
        [addressTypes.OTHER]: {
          AddressId: 0,
          StreetAddress1: '',
          StreetAddress2: '',
          City: '',
          State: '',
          Zip: '',
        },
      },
    },
    accountBackup: {},
    accountDetails: {
      accountNumber: '',
      accountType: '',
      accountStatus: '',
      program: '',
      studentRefNum: '',
      marketValue: '',
      netPrincipalContributions: '',
      currentYearContributions: '',
      previousYearContributions: '',
      optionName: '',
    },

    interestedParties: [],
    transactions: [],

    confirmModal_show: false,
    confirmModal_title: '',
    confirmModal_body: '',
    confirmModal_onConfirm: () => null,
    confirmModal_loadingKey: '',

    tags_data: [],
    tags_showAll: false,

    showModals_addInterestedParty: false,
    showModals_tagAccount: false,

    isEditing_benAddr: false,
    isEditing_agentAddr: false,
    isEditing_studentRefNum: false,

    isSaveEnabled_benAddr: false,
    isSaveEnabled_agentAddr: false,
    isSaveEnabled_studentRefNum: false,

    loading_page: false,
    loading_benAddr: false,
    loading_agentAddr: false,
    loading_tags: false,
    loading_studentRefNum: false,
    loading_interestedParties: false,
    loading_tables: false,

    beneAddressTab: addressTypes.MAILING,
    agentAddressTab: addressTypes.MAILING,
  };

  accountGet = (isLoadingOnMount) => {
    isLoadingOnMount
      ? this.setState({ loading_page: true, loading_tables: true })
      : this.setState({ loading_page: true });

    this.props.getAccount(this.props.match.params.id)
      .then(() => this.hydrateAccount(this.props.account))
      .catch(() => null)
      .finally(() => this.setState({ loading_page: false, loading_tables: false }));
  }

  accountUntag(tagId) {
    this.setState({ loading_tags: true });
    const untagAccount = {
      AccountIds: [this.props.match.params.id],
      TagIds: [tagId],
    };

    this.props.tagsUnassign(untagAccount)
      .then(() => {
        this.props.notificationShow('Account untagged.', 'success');
        this.confirmModalCloseHandle();
        this.accountGet(false);
      })
      .catch(() => null)
      .finally(() => this.setState({ loading_tags: false }));
  }

  accountUntagConfirmShow(tagName, tagId) {
    this.setState({
      confirmModal_show: true,
      confirmModal_title: 'Untag Account',
      confirmModal_body: `Are you sure you want to remove tag '${tagName}' from this account?`,
      confirmModal_onConfirm: () => this.accountUntag(tagId),
      confirmModal_loadingKey: 'loading_tags',
    });
  }

  addressesRevert = (personType) => {
    const person = cloneDeep(this.state[personType]);
    const { accountBackup } = this.state;

    Object.keys(person.addresses).forEach(addressType => {
      Object.keys(person.addresses[addressType]).forEach(addressField => {
        const backupValue = accountBackup[`${properCase(personType)}${addressType}Address`][addressField] || '';
        person.addresses[addressType][addressField] =
          addressField === 'StreetAddress1' || addressField === 'StreetAddress2' || addressField === 'City'
            ? properCase(backupValue)
            : backupValue;
      });
    });

    this.setState({
      [personType]: person,
      [personTypes === personTypes.AGENT ? 'isEditing_agentAddr' : 'isEditing_benAddr']: false,
      [personTypes === personTypes.AGENT ? 'isSaveEnabled_agentAddr' : 'isSaveEnabled_benAddr']: false,
    });
  }

  addressUpdate = (personType, addressType, addressField, newValue) => {
    const existingValue = this.state.accountBackup[`${properCase(personType)}${addressType}Address`][addressField];
    if (isEqual(existingValue, newValue)) {
      this.setState({ [personType === personTypes.AGENT ? 'isSaveEnabled_agentAddr' : 'isSaveEnabled_benAddr']: false });
    }
    else {
      this.setState({
        [personType]: set(cloneDeep(this.state[personType]), `addresses[${addressType}][${addressField}]`, newValue),
        [personType === personTypes.AGENT ? 'isSaveEnabled_agentAddr' : 'isSaveEnabled_benAddr']: true,
      });
    }
  }

  addressesSave = (personType) => {
    this.props.allNotificationsHide();
    if (personType !== personTypes.BENE && personType !== personTypes.AGENT) {
      throw new Error('submitted person type does not match "beneficiary" or "agent"');
    }

    const allAddressTypes = Object.values(addressTypes);
    const addressFields = ['StreetAddress1', 'StreetAddress2', 'City', 'State', 'Zip'];
    const changedAddresses = [];

    allAddressTypes.forEach(type => {
      const newAddress = this.state[personType].addresses[type];
      const oldAddress = this.state.accountBackup[`${properCase(personType)}${type}Address`];

      addressFields.some(field => {
        if (!isEqual((newAddress[field] || '').toLowerCase(), (oldAddress[field] || '').toLowerCase())) {
          changedAddresses.push(type);
          return true;
        }

        return false;
      });
    });

    if (changedAddresses.length === 0) {
      personType === personTypes.BENE
        ? this.setState({ isSaveEnabled_benAddr: false })
        : this.setState({ isSaveEnabled_agentAddr: false });
    }
    else {
      personType === personTypes.BENE
        ? this.setState({ loading_benAddr: true })
        : this.setState({ loading_agentAddr: true });
      const apiCalls = [];
      const { match: { params: { id: accountId } } } = this.props;

      changedAddresses.forEach(type => {
        const changedAddress = this.state[personType].addresses[type];
        if (changedAddress && changedAddress.AddressId !== 0) {
          const isAddressEmpty = every(
            pick(changedAddress, ['StreetAddress1', 'StreetAddress2', 'City', 'State', 'Zip']),
            field => field === '',
          );
          if (isAddressEmpty) {
            apiCalls.push(this.props.accountAddressDelete(accountId, personType, changedAddress.AddressId));
          }
          else {
            apiCalls.push(this.props.accountAddressUpdate(accountId, type, personType, pick(changedAddress, [
              'AddressId',
              'StreetAddress1',
              'StreetAddress2',
              'City',
              'State',
              'Zip',
            ])));
          }
        }
        else {
          apiCalls.push(this.props.accountAddressCreate(accountId, type, personType, {
            addressType: type,
            address: pick(changedAddress, ['StreetAddress1', 'StreetAddress2', 'City', 'State', 'Zip'])
          }));
        }
      });

      Promise.all(apiCalls)
        .then(() => this.props.notificationShow(`${properCase(personType)} ${changedAddresses.length > 1 ? 'addresses' : 'address'} updated.`, 'success'))
        .catch(() => this.addressesRevert(personType))
        .finally(() => {
          this.accountGet(false);
          personType === personTypes.BENE
            ? this.setState({ loading_benAddr: false, isEditing_benAddr: false })
            : this.setState({ loading_agentAddr: false, isEditing_agentAddr: false });
        });
    }
  }

  confirmModalCloseHandle = () => {
    this.setState({
      confirmModal_show: false,
      confirmModal_title: '',
      confirmModal_body: '',
      confirmModal_onConfirm: () => null,
      confirmModal_loadingKey: '',
    });
  }

  displayAddressCompose(address) {
    if (!address.StreetAddress1) {
      return <div className={styles.AccountView_address}>None</div>;
    }

    return (
      <div className={styles.AccountView_address}>
        <div>{address.StreetAddress1}</div>
        <div>{address.StreetAddress2}</div>
        <div>{`${address.City}, ${address.State} ${address.Zip}`}</div>
      </div>
    );
  }

  editableAddressCompose(address, addressType, person) {
    const nameStarter = `${person}${addressType}`;
    const updater = (field, value) => this.addressUpdate(person, addressType, field, value);

    return (
      <div className={`${styles.AccountView_address} ${styles.edit}`}>
        <TextField
          name={`${nameStarter}StreetAddress1`}
          label='Street Address 1'
          value={address.StreetAddress1}
          onChange={e => updater('StreetAddress1', e.target.value)}
          inputProps={{ maxLength: 40, 'data-testid': `auto-AccountView-StreetAddress1-${nameStarter}-input` }}
          fullWidth
          autoFocus
        />
        <TextField
          name={`${nameStarter}StreetAddress2`}
          label='Street Address 2'
          value={address.StreetAddress2}
          onChange={e => updater('StreetAddress2', e.target.value)}
          inputProps={{ maxLength: 40, 'data-testid': `auto-AccountView-StreetAddress2-${nameStarter}-input` }}
          fullWidth
        />
        <TextField
          name={`${nameStarter}City`}
          label='City'
          value={address.City}
          onChange={e => updater('City', e.target.value)}
          inputProps={{ maxLength: 26, 'data-testid': `auto-AccountView-City-${nameStarter}-input` }}
          fullWidth
        />
        <div>
          <TextField
            name={`${nameStarter}State`}
            label='State'
            value={address.State}
            onChange={e => updater('State', e.target.value)}
            inputProps={{ maxLength: 2, 'data-testid': `auto-AccountView-State-${nameStarter}-input` }}
            style={{ width: '17%' }}
            fullWidth
          />
          <TextField
            name={`${nameStarter}Zip`}
            label='ZIP'
            value={address.Zip}
            onChange={e => updater('Zip', e.target.value)}
            inputProps={{ 'data-testid': `auto-AccountView-Zip-${nameStarter}-input` }}
            style={{ width: '80%', marginLeft: '10px' }}
            fullWidth
          />
        </div>
      </div>
    );
  }

  hydrateAccount(curAccount) {
    this.setState({
      beneficiary: {
        name: properCase(curAccount.BeneficiaryName),
        addresses: {
          [addressTypes.MAILING]: {
            AddressId: curAccount.BeneficiaryMailingAddress.AddressId,
            StreetAddress1: properCase(curAccount.BeneficiaryMailingAddress.StreetAddress1),
            StreetAddress2: properCase(curAccount.BeneficiaryMailingAddress.StreetAddress2),
            City: properCase(curAccount.BeneficiaryMailingAddress.City),
            State: curAccount.BeneficiaryMailingAddress.State || '',
            Zip: curAccount.BeneficiaryMailingAddress.Zip || '',
          },
          [addressTypes.PHYSICAL]: {
            AddressId: curAccount.BeneficiaryPhysicalAddress.AddressId,
            StreetAddress1: properCase(curAccount.BeneficiaryPhysicalAddress.StreetAddress1),
            StreetAddress2: properCase(curAccount.BeneficiaryPhysicalAddress.StreetAddress2),
            City: properCase(curAccount.BeneficiaryPhysicalAddress.City),
            State: curAccount.BeneficiaryPhysicalAddress.State || '',
            Zip: curAccount.BeneficiaryPhysicalAddress.Zip || '',
          },
          [addressTypes.OTHER]: {
            AddressId: curAccount.BeneficiaryOtherAddress.AddressId,
            StreetAddress1: properCase(curAccount.BeneficiaryOtherAddress.StreetAddress1),
            StreetAddress2: properCase(curAccount.BeneficiaryOtherAddress.StreetAddress2),
            City: properCase(curAccount.BeneficiaryOtherAddress.City),
            State: curAccount.BeneficiaryOtherAddress.State || '',
            Zip: curAccount.BeneficiaryOtherAddress.Zip || '',
          },
        }
      },
      agent: {
        name: properCase(curAccount.AgentName),
        userIsAgent: curAccount.IsUserTheAgent,
        addresses: {
          [addressTypes.MAILING]: {
            AddressId: curAccount.AgentMailingAddress.AddressId,
            StreetAddress1: properCase(curAccount.AgentMailingAddress.StreetAddress1),
            StreetAddress2: properCase(curAccount.AgentMailingAddress.StreetAddress2),
            City: properCase(curAccount.AgentMailingAddress.City),
            State: curAccount.AgentMailingAddress.State || '',
            Zip: curAccount.AgentMailingAddress.Zip || '',
          },
          [addressTypes.PHYSICAL]: {
            AddressId: curAccount.AgentPhysicalAddress.AddressId,
            StreetAddress1: properCase(curAccount.AgentPhysicalAddress.StreetAddress1),
            StreetAddress2: properCase(curAccount.AgentPhysicalAddress.StreetAddress2),
            City: properCase(curAccount.AgentPhysicalAddress.City),
            State: curAccount.AgentPhysicalAddress.State || '',
            Zip: curAccount.AgentPhysicalAddress.Zip || '',
          },
          [addressTypes.OTHER]: {
            AddressId: curAccount.AgentOtherAddress.AddressId,
            StreetAddress1: properCase(curAccount.AgentOtherAddress.StreetAddress1),
            StreetAddress2: properCase(curAccount.AgentOtherAddress.StreetAddress2),
            City: properCase(curAccount.AgentOtherAddress.City),
            State: curAccount.AgentOtherAddress.State || '',
            Zip: curAccount.AgentOtherAddress.Zip || '',
          },
        }
      },
      accountBackup: curAccount,
      accountDetails: {
        accountNumber: curAccount.AccountNumber,
        accountType: curAccount.AccountType,
        accountStatus: curAccount.AccountStatus,
        program: curAccount.ProgramName,
        studentRefNum: curAccount.StudentReferenceNumber,
        marketValue: currencyFormatter(curAccount.MarketValue),
        netPrincipalContributions: currencyFormatter(curAccount.NetPrincipalContributions),
        currentYearContributions: currencyFormatter(curAccount.CurrentTaxYearContributions),
        previousYearContributions: currencyFormatter(curAccount.PreviousTaxYearContributions),
        optionName: curAccount.InvestmentOption,
      },
      interestedParties: curAccount.InterestedParties,
      transactions: curAccount.Transactions,
      tags_data: curAccount.Tags,
    });
  }

  interestedPartiesCompose() {
    const interestedParties = this.state.interestedParties.map(ip => {
      const ipName = ip.FirstName && ip.LastName ? `${properCase(ip.FirstName)} ${properCase(ip.LastName)}` : ip.EmailAddress;
      const id = ip.InterestedPartyId;

      if (this.state.accountDetails.accountType === 'Individual') {
        return <div key={id}>{ipName}</div>;
      }
      else {
        const actions = [
          {
            icon: 'close',
            tooltip: 'Remove',
            action: () => this.interestedPartyRemoveConfirmShow(ipName, id),
          }
        ];

        if (ip.Status === 'New') {
          actions.unshift({
            icon: 'mail_outline',
            tooltip: 'Resend Activation Email',
            action: () => this.interestedPartyResendEmailConfirmShow(ipName, id),
          });
        }

        return (
          <Chip
            key={id}
            name={ipName}
            actions={actions}
          />
        );
      }
    });

    return (
      <div className={styles.AccountView_interestedParties}>
        {this.state.accountDetails !== null && this.state.accountDetails !== undefined && this.state.accountDetails.accountType !== 'Individual' && (
          <div className={styles.AccountView_addButton}>
            {this.state.accountDetails.accountStatus !== 'Closed' &&
              <Button
                onClick={() => this.setState({ showModals_addInterestedParty: true })}
                variant='text'
                disableRipple
                color='secondary'
                data-testid='auto-AccountView-addInterestedParty-button'
              >
                <Icon>add</Icon>
                Add
              </Button>}
          </div>
        )}
        {interestedParties.length > 0 ? interestedParties : <span>None</span>}
      </div>
    );
  }

  interestedPartyAdd = (email, emailConfirm, ableToRequestScholarshipWithdrawal) => {
    this.setState({ loading_interestedParties: true });
    const data = {
      Email1: email,
      Email2: emailConfirm,
      CanRequestWithdrawal: ableToRequestScholarshipWithdrawal,
    };
    this.props.interestedPartyAdd(this.props.match.params.id, data)
      .then(() => {
        this.setState({ showModals_addInterestedParty: false });
        this.props.notificationShow('Interested Party added.', 'success');
        this.accountGet(false);
      })
      .catch(() => null)
      .finally(() => this.setState({ loading_interestedParties: false }));
  }

  interestedPartyRemoveConfirmShow = (name, id) => {
    this.setState({
      confirmModal_show: true,
      confirmModal_title: 'Remove Interested Party',
      confirmModal_body: `Are you sure you want to remove ${name} as an interested party?`,
      confirmModal_onConfirm: () => this.interestedPartyRemove(id),
      confirmModal_loadingKey: 'loading_interestedParties',
    });
  }

  interestedPartyRemove = (id) => {
    this.setState({ loading_interestedParties: true });

    this.props.interestedPartyRemove(this.props.match.params.id, id)
      .then(() => {
        this.props.notificationShow('Interested Party removed.', 'success');
        this.confirmModalCloseHandle();
        this.accountGet(false);
      })
      .catch(() => null)
      .finally(() => this.setState({ loading_interestedParties: false }));
  }

  interestedPartyResendEmailConfirmShow(name, id) {
    this.setState({
      confirmModal_show: true,
      confirmModal_title: 'Resend Activation Email',
      confirmModal_body: `Are you sure you want to resend an activation email to ${name}?`,
      confirmModal_onConfirm: () => this.interestedPartyResendEmail(id),
      confirmModal_loadingKey: 'loading_interestedParties',
    });
  }

  interestedPartyResendEmail(id) {
    this.setState({ loading_interestedParties: true });
    this.props.interestedPartyResend(this.props.match.params.id, id)
      .then(() => {
        this.props.notificationShow('Activation email resent.', 'success');
        this.confirmModalCloseHandle();
      })
      .catch(() => null)
      .finally(() => this.setState({ loading_interestedParties: false }));
  }

  studentRefNumRevert = () => {
    this.setState({
      accountDetails: {
        ...this.state.accountDetails,
        studentRefNum: this.state.accountBackup.StudentReferenceNumber,
      },
      isEditing_studentRefNum: false,
      isSaveEnabled_studentRefNum: false
    });
  }

  studentRefNumSave = () => {
    this.setState({ loading_studentRefNum: true });
    this.props.studentNumberUpdate(this.props.match.params.id, { studentNumber: this.state.accountDetails.studentRefNum })
      .then(() => this.props.notificationShow('Student reference number updated.', 'success'))
      .catch(() => null)
      .finally(() => {
        this.setState({
          isEditing_studentRefNum: false,
          isSaveEnabled_studentRefNum: false,
          loading_studentRefNum: false
        });
        this.accountGet(false);
      });
  }

  studentRefNumUpdate = (e) => {
    const existingValue = this.state.accountBackup.StudentReferenceNumber || '';
    const accountDetails = cloneDeep(this.state.accountDetails);

    if (existingValue.toLowerCase() === e.target.value.toLowerCase()) {
      this.setState({ isSaveEnabled_studentRefNum: false });
    }
    else {
      accountDetails.studentRefNum = e.target.value;
      this.setState({
        accountDetails,
        isSaveEnabled_studentRefNum: true
      });
    }
  }

  tagsCompose = () => {
    let totalTagCharacterLength = 0;
    let composedTags;
    const initialTags = [];
    const remainingTags = [];

    this.state.tags_data.forEach(tag => {
      const { TaxableEntityTagId: id, Tag: name } = tag;
      const tagElement = (
        <Chip
          actions={[
            {
              icon: 'close',
              tooltip: 'Untag',
              action: () => this.accountUntagConfirmShow(name, id),
            }
          ]}
          key={id}
          name={name}
        />
      );

      totalTagCharacterLength += name.length;

      if (totalTagCharacterLength <= 100) {
        initialTags.push(tagElement);
      }
      else {
        remainingTags.push(tagElement);
      }
    });

    if (initialTags.length === 0) {
      composedTags = <div>None</div>;
    }
    else if (remainingTags.length === 0) {
      composedTags = <div className={styles.AccountView_tagsEditContainer}>{initialTags}</div>;
    }
    else {
      composedTags = (
        <div>
          <Button
            onClick={() => this.setState({ tags_showAll: !this.state.tags_showAll })}
            variant='text'
            data-textid='auto-AccountView-tags-showAll-button'
          >
            <Icon>{this.state.tags_showAll ? 'expand_less' : 'expand_more'}</Icon>
            {this.state.tags_showAll ? 'Show fewer tags' : 'Show all tags'}
          </Button>
          <div className={styles.AccountView_tagsEditContainer}>{initialTags}</div>
          <Collapse in={this.state.tags_showAll}>
            <div className={styles.AccountView_tagsEditContainer}>
              {remainingTags}
            </div>
          </Collapse>
        </div>
      );
    }

    return (
      <div>
        <div className={styles.AccountView_addButton}>
          {this.state.accountDetails.accountStatus !== 'Closed' &&
            <Button
              onClick={() => this.setState({ showModals_tagAccount: true })}
              variant='text'
              disableRipple
              color='secondary'
              data-textid='auto-AccountView-tags-addTag-button'
            >
              <Icon>add</Icon>
              Add
            </Button>}
        </div>
        {composedTags}
      </div>
    );
  }

  componentDidMount() {
    this.accountGet(true);
  }

  render() {
    const { beneAddressTab, agentAddressTab, beneficiary, agent } = this.state;
    /* eslint-disable indent */
    return (
      <div className={styles.AccountView_container}>

        <Prompt
          when={this.state.isEditing_benAddr || this.state.isEditing_agentAddr || this.state.isEditing_studentRefNum}
          message='Are you sure you want to reload page? All unsaved changes will be lost.'
        />

        <div className={styles.AccountView_accountDetails}>
          <div className={styles.AccountView_pageHeader}>
            <Button
              onClick={() => this.props.history.push('/accounts')}
              variant='contained'
              data-testid='auto-AccountView-goBackToAccountList-button'
            >
              <Icon>keyboard_arrow_left</Icon>
              Account List
            </Button>
            {this.state.loading_page && (
              <div className={styles.AccountView_loading}>
                <LoadingOverlay
                  show
                  width='100%'
                  indicatorHeight='15px'
                />
              </div>
            )}
          </div>
          <div className={styles.AccountView_cardRow}>
            <AccountDetailsCard
              containerStyle={{ width: '33%' }}
              label='Account'
              displayChildren={
                <div className={styles.AccountView_detailRowContainer}>
                  <div className={styles.AccountView_detailRow}>
                    <div className={styles.AccountView_detailsRowTitle}>Account Number</div>
                    <div>{this.state.accountDetails.accountNumber}</div>
                  </div>
                  <div className={styles.AccountView_detailRow}>
                    <div className={styles.AccountView_detailsRowTitle}>Account Type</div>
                    <div>{this.state.accountDetails.accountType}</div>
                  </div>
                  <div className={styles.AccountView_detailRow}>
                    <div className={styles.AccountView_detailsRowTitle}>Status</div>
                    <div>{this.state.accountDetails.accountStatus}</div>
                  </div>
                </div>
              }
            />
            <AccountDetailsCard
              containerStyle={{ width: '33%' }}
              label='Beneficiary Name'
              displayChildren={
                <div className={styles.AccountView_beneficiaryName}>{this.state.beneficiary.name}</div>
              }
            />
            <AccountDetailsCard
              containerStyle={{ width: '33%' }}
              label='Program Name'
              displayChildren={
                <div>{this.state.accountDetails.program}</div>
              }
            />
          </div>
          <div className={styles.AccountView_cardRow}>
            <AccountDetailsCard
              containerStyle={{ width: '33%' }}
              label='Investment'
              displayChildren={
                <div className={styles.AccountView_detailRowContainer}>
                  <div className={styles.AccountView_detailRow}>
                    <div className={styles.AccountView_detailsRowTitle}>Market Value</div>
                    <div>{this.state.accountDetails.marketValue}</div>
                  </div>
                  <div className={styles.AccountView_detailRow}>
                    <div className={styles.AccountView_detailsRowTitle}>Net Principal Contributions</div>
                    <div>{this.state.accountDetails.netPrincipalContributions}</div>
                  </div>
                  <div className={styles.AccountView_detailRow}>
                    <div className={styles.AccountView_detailsRowTitle}>Current Tax Year Contributions</div>
                    <div>{this.state.accountDetails.currentYearContributions}</div>
                  </div>
                  <div className={styles.AccountView_detailRow}>
                    <div className={styles.AccountView_detailsRowTitle}>Previous Tax Year Contributions</div>
                    <div>{this.state.accountDetails.previousYearContributions}</div>
                  </div>
                  <div className={styles.AccountView_detailRow}>
                    <div className={styles.AccountView_detailsRowTitle}>Option</div>
                    <div>{this.state.accountDetails.optionName}</div>
                  </div>
                </div>
              }
            />
            <AccountDetailsCard
              containerStyle={{ width: '33%' }}
              canEdit={this.state.accountDetails.accountType !== '' && this.state.accountDetails.accountType !== 'Individual' && this.state.accountDetails.accountStatus !== 'Closed'}
              isSaveEnabled={this.state.isSaveEnabled_benAddr}
              onEdit={() => this.setState({ isEditing_benAddr: true })}
              onSave={() => this.addressesSave(personTypes.BENE)}
              onRevert={() => this.addressesRevert(personTypes.BENE)}
              loading={this.state.loading_benAddr}
              label='Beneficiary Addresses'
              displayChildren={
                <div>
                  <Tabs
                    value={beneAddressTab}
                    onChange={(e, selectedTab) => this.setState({ beneAddressTab: selectedTab })}
                    variant='fullWidth'
                    style={{ color: 'var(--text)' }}
                    centered
                  >
                    <Tab
                      label={addressTypes.MAILING}
                      value={addressTypes.MAILING}
                      style={{ minWidth: '100px' }}
                      data-testid='auto-AccountView-bene-addressDisplay-mailing-tabButton'
                    />
                    <Tab
                      label={addressTypes.PHYSICAL}
                      value={addressTypes.PHYSICAL}
                      style={{ minWidth: '100px' }}
                      data-testid='auto-AccountView-bene-addressDisplay-physical-tabButton'
                    />
                    <Tab
                      label={addressTypes.OTHER}
                      value={addressTypes.OTHER}
                      style={{ minWidth: '100px' }}
                      data-testid='auto-AccountView-bene-addressDisplay-other-tabButton'
                    />
                  </Tabs>
                  {this.displayAddressCompose(beneficiary.addresses[beneAddressTab])}
                </div>
              }
              editingChildren={
                <div>
                  <Tabs
                    value={beneAddressTab}
                    onChange={(e, selectedTab) => this.setState({ beneAddressTab: selectedTab })}
                    variant='fullWidth'
                    style={{ color: 'var(--text)' }}
                    centered
                  >
                    <Tab
                      label={addressTypes.MAILING}
                      value={addressTypes.MAILING}
                      style={{ minWidth: '100px' }}
                      data-testid='auto-AccountView-bene-addressEdit-mailing-tabButton'
                    />
                    <Tab
                      label={addressTypes.PHYSICAL}
                      value={addressTypes.PHYSICAL}
                      style={{ minWidth: '100px' }}
                      data-testid='auto-AccountView-bene-addressEdit-physical-tabButton'
                    />
                    <Tab
                      label={addressTypes.OTHER}
                      value={addressTypes.OTHER}
                      style={{ minWidth: '100px' }}
                      data-testid='auto-AccountView-bene-addressEdit-other-tabButton'
                    />
                  </Tabs>
                  {this.editableAddressCompose(beneficiary.addresses[beneAddressTab], beneAddressTab, personTypes.BENE)}
                </div>
              }
            />
            <AccountDetailsCard
              containerStyle={{ width: '33%', minHeight: '200px' }}
              canEdit={this.state.agent.userIsAgent && this.state.accountDetails.accountStatus !== 'Closed'}
              isSaveEnabled={this.state.isSaveEnabled_agentAddr}
              onEdit={() => this.setState({ isEditing_agentAddr: true })}
              onSave={() => this.addressesSave(personTypes.AGENT)}
              onRevert={() => this.addressesRevert(personTypes.AGENT)}
              loading={this.state.loading_agentAddr}
              label='Account Agent'
              displayChildren={
                <div>
                  <div>{this.state.agent.name}</div>
                  <Tabs
                    value={agentAddressTab}
                    onChange={(e, selectedTab) => this.setState({ agentAddressTab: selectedTab })}
                    variant='fullWidth'
                    style={{ color: 'var(--text)' }}
                    centered
                  >
                    <Tab
                      label={addressTypes.MAILING}
                      value={addressTypes.MAILING}
                      style={{ minWidth: '100px' }}
                      data-testid='auto-AccountView-agent-addressDisplay-mailing-tabButton'
                    />
                    <Tab
                      label={addressTypes.PHYSICAL}
                      value={addressTypes.PHYSICAL}
                      style={{ minWidth: '100px' }}
                      data-testid='auto-AccountView-agent-addressDisplay-physical-tabButton'
                    />
                    <Tab
                      label={addressTypes.OTHER}
                      value={addressTypes.OTHER}
                      style={{ minWidth: '100px' }}
                      data-testid='auto-AccountView-agent-addressDisplay-other-tabButton'
                    />
                  </Tabs>
                  {this.displayAddressCompose(agent.addresses[agentAddressTab])}
                </div>
              }
              editingChildren={
                <div>
                  <div>{this.state.agent.name}</div>
                  <Tabs
                    value={agentAddressTab}
                    onChange={(e, selectedTab) => this.setState({ agentAddressTab: selectedTab })}
                    variant='fullWidth'
                    style={{ color: 'var(--text)' }}
                    centered
                  >
                    <Tab
                      label={addressTypes.MAILING}
                      value={addressTypes.MAILING}
                      style={{ minWidth: '100px' }}
                      data-testid='auto-AccountView-agent-addressEdit-mailing-tabButton'
                    />
                    <Tab
                      label={addressTypes.PHYSICAL}
                      value={addressTypes.PHYSICAL}
                      style={{ minWidth: '100px' }}
                      data-testid='auto-AccountView-agent-addressEdit-physical-tabButton'
                    />
                    <Tab
                      label={addressTypes.OTHER}
                      value={addressTypes.OTHER}
                      style={{ minWidth: '100px' }}
                      data-testid='auto-AccountView-agent-addressEdit-other-tabButton'
                    />
                  </Tabs>
                  {this.editableAddressCompose(agent.addresses[agentAddressTab], agentAddressTab, personTypes.AGENT)}
                </div>
              }
            />
          </div>
          <div className={styles.AccountView_cardRow}>
            <AccountDetailsCard
              containerStyle={{ width: '33%' }}
              canEdit={false}
              onEdit={() => this.setState({ isEditing_tags: true })}
              onSave={() => this.setState({ isEditing_tags: false })}
              onRevert={() => this.setState({ tags: this.state.accountBackup.Tags, isEditing_tags: false })}
              loading={this.state.loading_tags}
              label='Tags'
              displayChildren={this.tagsCompose()}
            />
            <AccountDetailsCard
              containerStyle={{ width: '33%' }}
              canEdit={this.state.accountDetails.accountType !== '' && this.state.accountDetails.accountType !== 'Individual' && this.state.accountDetails.accountStatus !== 'Closed'}
              isSaveEnabled={this.state.isSaveEnabled_studentRefNum}
              onEdit={() => this.setState({ isEditing_studentRefNum: true })}
              onSave={this.studentRefNumSave}
              onRevert={this.studentRefNumRevert}
              loading={this.state.loading_studentRefNum}
              label='Student Reference Number'
              displayChildren={
                <div>{this.state.accountDetails.studentRefNum ? this.state.accountDetails.studentRefNum : 'None'}</div>
              }
              editingChildren={
                <TextField
                  name='studentRefNum'
                  value={this.state.accountDetails.studentRefNum}
                  onChange={this.studentRefNumUpdate}
                  style={{ width: '200px', fontWeight: 'normal' }}
                  inputProps={{ 'data-testid': 'auto-AccountView-studentRefNum-input' }}
                  fullWidth
                  autoFocus
                />
              }
            />
            <AccountDetailsCard
              containerStyle={{ width: '33%', minHeight: '200px' }}
              canEdit={false}
              label='Interested Parties'
              displayChildren={this.interestedPartiesCompose()}
            />
          </div>
          <div className={styles.AccountView_cardRow}>
            <AccountDetailsCard
              containerStyle={{ width: '100%' }}
              label={''}
              displayChildren={
                <div className={styles.AccountView_transactionTableContainer}>
                  <SmartTable
                    idKey='TransactionId'
                    emptyMessage='No transactions to display'
                    loading={this.state.loading_tables}
                    rows={this.state.transactions}
                    title='Transactions'
                    columns={[
                      {
                        key: 'Date',
                        title: 'Date',
                        type: TableDataTypes.DATE_STRING,
                      },
                      {
                        key: 'Type',
                        title: 'Transaction Type',
                        type: TableDataTypes.STRING,
                      },
                      {
                        key: 'Basis',
                        title: 'Basis',
                        type: TableDataTypes.CURRENCY,
                        hideOn: [TableColumnHideOptions.PHONE],
                      },
                      {
                        key: 'Amount',
                        title: 'Amount',
                        type: TableDataTypes.CURRENCY,
                      },
                      {
                        key: 'Milestone',
                        title: 'Milestone',
                        type: TableDataTypes.STRING,
                        hideOn: [TableColumnHideOptions.PHONE],
                      },
                    ]}
                  >
                    <TableToolbar />
                    <TableContainer maxHeight='100%'>
                      <TableHeader />
                      <TableRows />
                    </TableContainer>
                    <TablePagination />
                  </SmartTable>
                </div>
              }
            />
          </div>
        </div>

        <AddInterestedPartyModal
          show={this.state.showModals_addInterestedParty}
          onModalClose={() => this.setState({ showModals_addInterestedParty: false })}
          onAdd={this.interestedPartyAdd}
          loading={this.state.loading_interestedParties}
          canApproveWithdrawals={this.props.canApproveWithdrawals}
        />

        <TagModal
          accountsGet={() => this.accountGet()}
          onClose={() => this.setState({ showModals_tagAccount: false })}
          open={this.state.showModals_tagAccount}
          selectedAccounts={[{
            id: parseInt(this.props.match.params.id),
            accountNumber: this.state.accountDetails.accountNumber,
            beneName: this.state.beneficiary.name,
          }]}
        />

        <ConfirmModal
          show={this.state.confirmModal_show}
          title={this.state.confirmModal_title}
          body={this.state.confirmModal_body}
          onConfirm={this.state.confirmModal_onConfirm}
          onModalClose={this.confirmModalCloseHandle}
          isLoading={this.state[this.state.confirmModal_loadingKey]}
        />
      </div>
    );
    /* eslint-enable indent */
  }
}

export default withRouter(connect(select, {
  accountAddressCreate,
  accountAddressUpdate,
  accountAddressDelete,
  getAccount,
  interestedPartyAdd,
  interestedPartyRemove,
  interestedPartyResend,
  studentNumberUpdate,
  tagsUnassign,
  notificationShow,
  allNotificationsHide,
})(AccountView));
