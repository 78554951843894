import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Icon,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from '@mui/material';

import {
  withStyles,
} from '@mui/styles';

const styles = theme => ({
  listItemText: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '24px',
    color: theme.palette.primary.main,
    lineHeight: 'initial',
  },
  icon: {
    color: theme.palette.primary.main,
  }
});


export class SidenavMenuOption extends Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    link: PropTypes.string,
    onOptionClick: PropTypes.func,
    rootMenuOptionIconName: PropTypes.string,
    classes: PropTypes.object.isRequired,
  };

  state = {
    showMenuOptions: false,
  };

  dropdownMenuOptionCompose() {
    const { showMenuOptions } = this.state;
    const { rootMenuOptionIconName, classes, children, name } = this.props;
    const nestedMenuOptions = this.nestedMenuOptionsCompose(children);

    return (
      <div>
        <ListItem
          onClick={() => this.setState({ showMenuOptions: !showMenuOptions })}
          button
          data-testid='auto-SidenavMenuOption-showMenuOptions'
        >
          <ListItemIcon>
            <Icon className={classes.icon}>{rootMenuOptionIconName}</Icon>
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.listItemText }}>
            {name}
          </ListItemText>
          <Icon className={classes.icon}>{showMenuOptions ? 'expand_less' : 'expand_more'}</Icon>
        </ListItem>
        <Collapse in={showMenuOptions} unmountOnExit>
          <List component='div' >
            {nestedMenuOptions}
          </List>
        </Collapse>
      </div>
    );
  }

  menuOptionClickHandle(link, onMenuOptionClick) {
    if (link) {
      this.props.history.push(link);
    }
    onMenuOptionClick();
  }

  nestedMenuOptionsCompose(options) {
    const optionsArray = options.length ? options : new Array(options);

    return optionsArray.map((option, index) => {
      return (
        <ListItem
          key={index}
          onClick={() => this.menuOptionClickHandle(option.props.link, option.props.onOptionClick)}
          button
          data-testid={`auto-SidenavMenuOption-nestedMenu-item-${option.props.name}`}
        >
          {option.props.name}
        </ListItem>
      );
    });
  }

  normalMenuOptionCompose() {
    const { classes, link, onOptionClick, rootMenuOptionIconName, name } = this.props;
    return (
      <ListItem onClick={() => this.menuOptionClickHandle(link, onOptionClick)} button data-testid={`auto-SidenavMenuOption-normalMenu-item-${name}`}>
        <ListItemIcon>
          <Icon className={classes.icon}>{rootMenuOptionIconName}</Icon>
        </ListItemIcon>
        <ListItemText classes={{ primary: classes.listItemText }}>
          {name}
        </ListItemText>
      </ListItem>
    );
  }

  render() {
    return (
      <div>
        {this.props.children
          ? this.dropdownMenuOptionCompose()
          : this.normalMenuOptionCompose()
        }
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(SidenavMenuOption));
