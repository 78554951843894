import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Modal,
  MultiSelector,
  StyledLink,
  notificationShow,
  SmartTable,
  TableDataTypes,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
} from '@frontend/common';

import {
  getTags,
  tagsAssign
} from 'components/Features/protected/ManageTags/actions';

import styles from './styles.module.css';

const select = (state) => ({
  tags: state.tags.tagList.map(tag => ({ value: tag.TaxableEntityTagId, display: tag.Tag })),
  IsAdmin: state.session.userDetails.IsAdmin,
});

export class TagModal extends React.Component {

  static propTypes = {
    accountsGet: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedAccounts: PropTypes.array,
    getTags: PropTypes.func.isRequired,
    tagsAssign: PropTypes.func.isRequired,
    tags: PropTypes.array,
    IsAdmin: PropTypes.bool,
  };

  state = {
    loading: false,
    selectedTags: [],
  };

  accountsTag() {
    this.setState({ loading: true });
    this.props.tagsAssign({
      AccountIds: this.props.selectedAccounts.map(account => account.id),
      TagIds: this.state.selectedTags.map(tag => tag.value),
    })
      .then(() => {
        this.props.onClose();
        this.props.accountsGet();
        this.props.notificationShow(this.props.selectedAccounts.length === 1 ? 'Account tagged.' : 'Accounts tagged.', 'success');
      })
      .catch(() => null)
      .finally(() => this.setState({ loading: false }));
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.getTags()
      .catch(() => null)
      .finally(() => this.setState({ loading: false }));
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props;
    if (!open && open !== prevProps.open) {
      this.setState({ selectedTags: [] });
    }
  }

  render() {
    /* eslint-disable indent */
    const { loading } = this.state;
    return (
      <Modal
        actionButtons={[
          {
            label: 'Cancel',
            action: () => this.props.onClose(),
            disabled: loading,
          },
          {
            action: () => this.accountsTag(),
            disabled: this.state.selectedTags.length < 1,
            label: this.props.selectedAccounts.length === 1 ? 'Tag Account' : 'Tag Accounts',
            loading,
            buttonType: 'contained',
          }
        ]}
        onCloseModal={this.props.onClose}
        show={this.props.open}
        title={this.props.selectedAccounts.length === 1 ? 'Tag Account' : 'Tag Accounts'}
      >
        {this.props.tags.length > 0
          ?
          <MultiSelector
            hintText='Search for available tags...'
            onSelection={selectedTags => this.setState({ selectedTags })}
            options={this.props.tags}
            selectedOptions={this.state.selectedTags}
          />
          :
          <div className={styles.TagModal_tagText}>
            {this.props.IsAdmin
              ? <span>
                <span>No tags available. Create tags in the </span>
                <span onClick={() => this.props.onClose()} data-testid='auto-TagModal-managetags-close'>
                  <StyledLink to='/tags' data-testid='auto-TagModal-manageTagsSection-link'>Manage Tags section.</StyledLink>
                </span>
              </span>
              : <span>No tags available. Contact the master administrator to create some.</span>
            }
          </div>
        }

        <div className={styles.TagModal_table_container}>
          <SmartTable
            idKey='id'
            emptyMessage='No accounts selected.'
            searchable={false}
            rows={this.props.selectedAccounts}
            columns={[
              {
                key: 'accountNumber',
                title: 'Account',
                type: TableDataTypes.NUMBER,
              },
              {
                key: 'beneName',
                title: 'Beneficiary',
                type: TableDataTypes.STRING,
              },
            ]}
          >
            <TableContainer minWidth='100%' maxHeight='100%'>
              <TableHeader stickyHeader={false} />
              <TableRows />
            </TableContainer>
            <TablePagination />
          </SmartTable>
        </div>
      </Modal>
    );
    /* eslint-enable indent */
  }
}

export default connect(select, { notificationShow, getTags, tagsAssign })(TagModal);
