/*
*
* StaticResources Actions
*
*/
import {
  PASSWORD_REQUIREMENTS_GET,
  MILESTONES_GET,
  WEB_MESSAGES_GET,
} from './constants';

import { GETrequest } from 'utils/helpers/api_handler';


export function passwordRequirementsGet() {
  const response = GETrequest('/authentication/passwordrequirements');
  return {
    type: PASSWORD_REQUIREMENTS_GET,
    payload: response,
  };
}

export function getMilestones() {
  const response = GETrequest('/foundation/milestones');
  return {
    type: MILESTONES_GET,
    payload: response,
  };
}

export function getWebMessages() {
  return {
    type: WEB_MESSAGES_GET,
    payload: GETrequest('/WebsiteMessage'),
  };
}
