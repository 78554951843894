import { cloneDeep } from 'lodash';
import {
  TRANSFER_GET,
} from './constants';

const initialState = {
  transfer: {
    TransferDetails: []
  }
};

function TransferReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case TRANSFER_GET: {
      newState.transfer = action.payload.data;
      return newState;
    }

    default:
      return newState;
  }  
}

export default TransferReducer;