/*
 *
 * Sessions constants
 *
 */
export const INITIALIZE_STORE = 'App_Root/components/Sessions/INITIALIZE_STORE';

export const BROWSER_WARNING = 'App_Root/components/Sessions/BROWSER_WARNING';
export const MOBILE_WARNING = 'App_Root/components/Sessions/MOBILE_WARNING';

export const USER_AUTHENTICATE_REQUEST = 'App_Root/components/Sessions/USER_AUTHENTICATE_REQUEST';

export const TOGGLE_SPLASH_HAS_DISPLAYED = 'App_Root/components/Sessions/TOGGLE_SPLASH_HAS_DISPLAYED';

export const USER_INFO_GET = 'App_Root/components/Sessions/USER_INFO_GET';
export const USER_INFO_UPDATE = 'App_Root/components/Sessions/USER_INFO_UPDATE';
export const EMAIL_CHANGE = 'App_Root/components/Sessions/EMAIL_CHANGE';

export const RESET_CLAIMS = 'App_Root/components/Sessions/RESET_CLAIMS';

export const REASONS_BLOCKED = {
  TWO_FACTOR_REGISTRATION: 'TwoFactorRegistration',
  TWO_FACTOR_AUTHENTICATION: 'TwoFactorAuthentication',
};

export const TWO_FACTOR_STATUS = {
  COMPLETED: 'Completed',
  PENDING: 'Pending',
  BYPASSED: 'Bypassed',
  UNAVAILABLE: 'Unavailable',
  REQUIRED: 'Required'
};

export const TWO_FACTOR_TYPES = {
  REGISTRATION: 'registrations',
  AUTHENTICATION: 'authentication',
  PROTECTED: 'protected',
  UPDATE: 'update'
};