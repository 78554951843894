import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import {
  Modal,
  properCase,
  TableDataTypes,
  TableColumnHideOptions,
  SmartTable,
  TableContainer,
  TableHeader,
  TableRows,
  TableToolbar,
} from '@frontend/common';

import styles from './styles.module.css';

export class ViewAccountsModal extends React.Component {

  static propTypes = {
    loadingOnMount: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    tagSelected: PropTypes.object.isRequired,
  };

  rowMenuHandle(id) {
    this.props.onClose();
    this.props.history.push(`/accounts/${id}`);
  }

  render() {
    return (
      <Modal
        title={'Accounts'}
        onCloseModal={this.props.onClose}
        show={this.props.open}
      >
        <div className={styles.ViewAccountsModal_tableContainer}>
          <div className={styles.ViewAccountsModal_modalTextContainer}>
            Accounts tagged &apos;{this.props.tagSelected.name}&apos;
          </div>
          <SmartTable
            idKey='AccountId'
            emptyMessage='No accounts have this tag.'
            loading={this.props.loadingOnMount}
            rows={this.props.tagSelected.accountsAffected}
            actions={[{
              type: TableColumnHideOptions.ROW_MENU,
              displayName: 'View Account',
              onSelect: row => this.rowMenuHandle(row.AccountId),
            }]}
            columns={[
              {
                key: 'AccountNumber',
                title: 'Account Number',
                type: TableDataTypes.NUMBER
              },
              {
                key: 'BeneficiaryName',
                title: 'Beneficiary Name',
                type: TableDataTypes.STRING,
                format: value => properCase(value),
              },
            ]}
          >
            <TableToolbar />
            <TableContainer maxHeight='100%' minWidth='100%'>
              <TableHeader />
              <TableRows />
            </TableContainer>
          </SmartTable>
        </div>
      </Modal>
    );
  }
}

export default withRouter(ViewAccountsModal);
