import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Switch,
  withRouter,
} from 'react-router-dom';

import { LegalFooter } from '@frontend/common';

import PublicRoute from './PublicRoute';
import ProtectedRoute from './ProtectedRoute';

import Header from './Header';
import Sidenav from './Sidenav';
import Login from 'components/Features/public/Login';
import ActivateUser from 'components/Features/public/ActivateUser';
import ActivateEmail from 'components/Features/public/ActivateEmail';
import ForgotPassword from 'components/Features/public/ForgotPassword';
import ResetPassword from 'components/Features/public/ResetPassword';
import Dashboard from 'components/Features/protected/Dashboard';
import AccountsList from 'components/Features/protected/Accounts/AccountsList';
import Tags from 'components/Features/protected/ManageTags';
import Users from 'components/Features/protected/ManageUsers/UsersList';
import CreateUser from 'components/Features/protected/ManageUsers/CreateUser';
import EditUser from 'components/Features/protected/ManageUsers/EditUser';
import AccountView from 'components/Features/protected/Accounts/AccountView';
import ProgramView from 'components/Features/protected/Dashboard/ProgramView';
import Transfers from 'components/Features/protected/Transfers';
import Withdrawals from 'components/Features/protected/Withdrawals';
import Reports from 'components/Features/protected/Reports/ReportsList';
import ReportView from 'components/Features/protected/Reports/ReportView';
import PageNotFound from 'components/Features/protected/PageNotFound';

// Potentially Blocked Components - see ProtectedRoute
import Multifactor from 'components/Features/protected/Multifactor';

import MyInfo from 'components/Features/protected/MyInfo';

import { getTitle } from 'utils/helpers/title_handler';
import ErrorBoundary from 'utils/helpers/error_boundary';
import { userLogout, clearStore, toggleSplashHasDisplayed, } from './actions';
import { notificationShow, SplashScreen, } from '@frontend/common';

import styles from './styles.module.css';

const select = (state) => ({
  isValid: state.session.isValid,
  accountBlocked: state.session.accountBlocked,
  token: state.session.token,
  isAccountListLoading: state.accounts.accountList.length > 0,
  isProgramListLoading: state.dashboard.programList.length > 0,
  splashHasDisplayed: state.session.splashHasDisplayed,
});

export class Navigation extends Component {

  static propTypes = {
    isValid: PropTypes.bool.isRequired,
    accountBlocked: PropTypes.bool.isRequired,
    userLogout: PropTypes.func.isRequired,
    token: PropTypes.string,
    notificationShow: PropTypes.func.isRequired,
    location: PropTypes.object,
    clearStore: PropTypes.func.isRequired,
    isAccountListLoading: PropTypes.bool.isRequired,
    isProgramListLoading: PropTypes.bool.isRequired,
    splashHasDisplayed: PropTypes.bool.isRequired,
    toggleSplashHasDisplayed: PropTypes.func.isRequired,
  };

  state = {
    showSidenav: false,
  };

  logOut = () => {
    const { token } = this.props;
    this.props.clearStore();
    this.props.userLogout({ token })
      .then(() => this.props.notificationShow('You have logged out.', 'success'))
      .catch(() => null);
  }

  render() {
    const {
      isValid, isAccountListLoading, isProgramListLoading,
      splashHasDisplayed, toggleSplashHasDisplayed, accountBlocked
    } = this.props;

    return (
      <div>
        {!accountBlocked &&
          <Header
            handleLogout={this.logOut}
            openSideNav={() => this.setState({ showSidenav: true })}
            showMenus={this.props.isValid}
            pageTitle={getTitle(this.props.history.location.pathname)}
          />}
        {!accountBlocked &&
          <Sidenav
            showSideNav={this.state.showSidenav}
            onClose={() => this.setState({ showSidenav: false })}
            onOpen={() => this.setState({ showSidenav: true })}
            handleLogout={this.logOut}
          />}
        <div className={styles.Navigation_featuresContainer}>
          <div className={styles.Navigation_featuresChildren}>
            <ErrorBoundary>
              <Switch>
                <PublicRoute path='/login' component={Login} />
                <PublicRoute path='/activate-account' component={ActivateUser} />
                <PublicRoute path='/activate-email' component={ActivateEmail} />
                <PublicRoute path='/forgot-password' component={ForgotPassword} />
                <PublicRoute path='/reset-password' component={ResetPassword} />

                {accountBlocked && <ProtectedRoute path='/multifactor' component={Multifactor} />}
                {!accountBlocked && <ProtectedRoute exact path='/' component={Dashboard} />}
                {!accountBlocked && <ProtectedRoute exact path='/accounts/:id' component={AccountView} />}
                {!accountBlocked && <ProtectedRoute path='/accounts' component={AccountsList} />}
                {!accountBlocked && <ProtectedRoute path='/tags' component={Tags} />}
                {!accountBlocked && <ProtectedRoute exact path='/users/new' component={CreateUser} />}
                {!accountBlocked && <ProtectedRoute exact path='/users/:userId' component={EditUser} />}
                {!accountBlocked && <ProtectedRoute path='/users' component={Users} />}
                {!accountBlocked && <ProtectedRoute path='/reports' component={Reports} />}
                {!accountBlocked && <ProtectedRoute path='/my-info' component={MyInfo} />}
                {!accountBlocked && <ProtectedRoute path='/transfers/:transferId/edit' component={(props) => <Transfers requestType={'Edit'} {...props} />} />}
                {!accountBlocked && <ProtectedRoute path='/transfers' render={(props) => (<Transfers requestType={'Create'} {...props} />)} />}
                {!accountBlocked && <ProtectedRoute path='/withdrawals' component={Withdrawals} />}
                {!accountBlocked && <ProtectedRoute exact path='/report/:id' component={ReportView} />}
                {!accountBlocked && <ProtectedRoute exact path='/programs/:id' component={ProgramView} />}
                <ProtectedRoute component={PageNotFound} />
              </Switch>
            </ErrorBoundary>
          </div>
          <LegalFooter />
        </div>
        {isValid && !accountBlocked && !splashHasDisplayed &&
          <SplashScreen
            isAppLoading={!isAccountListLoading && !isProgramListLoading}
            splashHasDisplayed={splashHasDisplayed}
            toggleSplashHasDisplayed={toggleSplashHasDisplayed}
          />}
      </div>
    );
  }
}

export default withRouter(connect(select, {
  userLogout,
  notificationShow,
  clearStore,
  toggleSplashHasDisplayed,
})(Navigation));
