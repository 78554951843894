import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEqual } from 'lodash';
import dayjs from 'dayjs';

import {
  currencyFormatter,
  properCase,
  dateFormat,
  timeFormat,
  TableDataTypes,
  TableColumnHideOptions,
  TableRowActionTypes,
  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';

import ViewWithdrawalModal from '../ViewWithdrawalModal';

import styles from './styles.module.css';

const viewDetailsMapping = {
  id: 'WithdrawalId',
  headers: {
    'Account': 'AccountNumber',
    'Beneficiary': 'BeneficiaryName',
    'Payable To': 'SchoolName',
    'Address': {
      id: '$id',
      headers: {
        'For The Benefit Of': 'StreetAddress1',
        'Street Address 1': 'StreetAddress2',
        'Street Address 2': 'StreetAddress3',
        'City, State, Zip': 'cityStateZip',
      }
    },
    'Account Balance': 'accountBalanceFormatted',
    'Withdrawal Amount': 'WithdrawalAmount',
    'Requested On': 'requestedDate',
    'Status': 'fullStatus',
    'Program': 'ProgramName',
  }
};


export class WithdrawalHistory extends React.Component {

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    tableData: PropTypes.array.isRequired,
    tableDataGet: PropTypes.func.isRequired,
  };

  state = {
    tableData: this.tableDataFormat(cloneDeep(this.props.tableData)),
    viewOpen: false,
    id: null,
  };

  addressInfoCompose() {
    const headers = viewDetailsMapping.headers.Address.headers;
    const info = [];
    const record = this.withdrawalRecordGet();
    let uniqueId = 1;

    for (const header in headers) {
      for (const prop in record.SchoolAddress) {
        if (prop === headers[header]) {
          info.push(
            <tbody key={uniqueId}>
              {record.SchoolAddress[headers[header]] ?
                <tr>
                  <th className={styles.WithdrawalHistory_withdrawalInfoHeader}>{header}</th>
                  <td className={styles.WithdrawalHistory_withdrawalInfoRow} style={{ color: 'var(--text)' }}>{`${record.SchoolAddress[headers[header]]}`}</td>
                </tr>
                :
                null
              }
            </tbody>
          );
        }
        uniqueId++;
      }
    }
    return info;
  }

  tableDataFormat(tableData) {
    tableData.forEach(data => {
      // General lower case
      data.BeneficiaryName = properCase(data.BeneficiaryName);
      data.SchoolName = properCase(data.SchoolName);
      data.ProgramName = properCase(data.ProgramName);

      // Address formatting
      data.SchoolAddress.StreetAddress1 = data.SchoolAddress.StreetAddress1.includes('FBO')
        ? `${data.SchoolAddress.StreetAddress1.split(' ')[0]} ${properCase(data.SchoolAddress.StreetAddress1.split('FBO ')[1])}`
        : properCase(data.SchoolAddress.StreetAddress1);
      data.SchoolAddress.StreetAddress2 = properCase(data.SchoolAddress.StreetAddress2);
      data.SchoolAddress.StreetAddress3 = properCase(data.SchoolAddress.StreetAddress3);
      data.SchoolAddress.City = properCase(data.SchoolAddress.City);
      data.SchoolAddress.cityStateZip = `${data.SchoolAddress.City}, ${data.SchoolAddress.State} ${data.SchoolAddress.Zip}`;

      // Misc formatting
      data.accountBalanceFormatted = currencyFormatter(data.AccountBalance);
      if (!data.WithdrawalAmount === 'full balance') {
        data.WithdrawalAmount = currencyFormatter(data.WithdrawalAmount);
      }
      if (data.Rejected) {
        data.fullStatus = `Rejected: ${properCase(data.RejectionReason)}`;
      }
      else {
        data.fullStatus = 'Approved';
      }
    });

    return tableData;
  }

  withdrawalInfoCompose() {
    const headers = viewDetailsMapping.headers;
    const info = [];
    const record = this.withdrawalRecordGet();
    let uniqueId = 1;

    for (const header in headers) {
      for (const prop in record) {
        if (prop === headers[header]) {
          let dataValue = record[headers[header]];
          if (record[headers[header]] instanceof Date) {
            dataValue = `${dateFormat(record[headers[header]])} ${timeFormat(record[headers[header]])}`;
          }

          info.push(
            <tbody key={uniqueId}>
              <tr>
                <th className={styles.WithdrawalHistory_withdrawalInfoHeader}>{header}:</th>
                <td className={styles.WithdrawalHistory_withdrawalInfoRow} style={{ color: 'var(--text)' }}>{dataValue}</td>
              </tr>
            </tbody>
          );
        }
        uniqueId++;
      }
    }
    return [info, this.addressInfoCompose(false)];
  }

  withdrawalRecordGet() {
    return this.state.tableData.find(record => record.WithdrawalId === parseInt(this.state.id)) || {};
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.tableData, prevProps.tableData)) {
      this.setState({ tableData: this.tableDataFormat(cloneDeep(this.props.tableData)) });
    }
  }

  render() {
    return (
      <div>
        <SmartTable
          idKey='WithdrawalId'
          emptyMessage='No withdrawal history to display.'
          loading={this.props.loading}
          rows={this.state.tableData}
          actions={[{
            displayName: 'View Details',
            type: TableRowActionTypes.ROW_MENU,
            onSelect: row => this.setState({ viewOpen: true, id: row.WithdrawalId }),
          }]}
          columns={[
            {
              key: 'AccountNumber',
              title: 'Account Number',
              type: TableDataTypes.NUMBER,
              customStyle: { width: '.75fr' },
              hideOn: [TableColumnHideOptions.PHONE, TableColumnHideOptions.TABLET],
            },
            {
              key: 'BeneficiaryName',
              title: 'Beneficiary',
              type: TableDataTypes.STRING,
            },
            {
              key: 'SchoolName',
              title: 'Payable To',
              type: TableDataTypes.STRING,
              hideOn: [TableColumnHideOptions.PHONE, TableColumnHideOptions.TABLET],
            },
            {
              key: 'AccountBalance',
              title: 'Account Balance',
              type: TableDataTypes.CURRENCY,
              hideOn: [TableColumnHideOptions.PHONE, TableColumnHideOptions.TABLET],
            },
            {
              key: 'WithdrawalAmount',
              title: 'Withdrawal Amount',
              type: TableDataTypes.STRING,
            },
            {
              key: 'CreatedDate',
              title: 'Requested On',
              type: TableDataTypes.DATE_STRING,
              format: date => dayjs(date).format('MM/DD/YYYY HH:mm A'),
              hideOn: [TableColumnHideOptions.PHONE],
            },
            {
              key: 'fullStatus',
              title: 'Status',
              type: TableDataTypes.STRING,
              hideOn: [TableColumnHideOptions.PHONE],
            },
            {
              key: 'ProgramName',
              title: 'Program',
              type: TableDataTypes.STRING,
              hideOn: [TableColumnHideOptions.PHONE],
            },
          ]}
        >
          <TableToolbar />
          <TableContainer maxHeight='100%'>
            <TableHeader />
            <TableRows />
          </TableContainer>
          <TablePagination />
        </SmartTable>

        <ViewWithdrawalModal
          show={this.state.viewOpen}
          onCloseModal={() => this.setState({ viewOpen: false })}
          withdrawalInfo={this.withdrawalInfoCompose()}
        />
      </div>
    );
  }
}

export default WithdrawalHistory;
