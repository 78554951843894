import { GETrequest, POSTrequest, PUTrequest } from 'utils/helpers/api_handler';
import {
  TWO_FACTOR_REGISTRATION,
  TWO_FACTOR_REGISTRATION_UPDATE,
  TWO_FACTOR_TOKEN_VERIFICATION,
  TWO_FACTOR_TOKEN,
  TWO_FACTOR_QRCODES,
  TWO_FACTOR_PUSH,
  TWO_FACTOR_PUSH_VERIFICATION,
  TWO_FACTOR_PREFERRED_METHOD,
  TWO_FACTOR_SAVE_PREFERRED_METHOD,
  VERIFY_PIN,
} from './constants';

export function verifyPIN(PIN) {
  const response = POSTrequest('/TwoFactor/SecurityPIN/Answer', { Answer: PIN });
  return {
    type: VERIFY_PIN,
    payload: response
  };
}

export function twoFactorRegistration(regInfo) {
  const response = POSTrequest('/TwoFactor/Registrations', regInfo);
  return {
    type: TWO_FACTOR_REGISTRATION,
    payload: response,
  };
}

export function twoFactorRegistrationUpdate(regInfo) {
  const response = PUTrequest('/TwoFactor/Registrations', regInfo);
  return {
    type: TWO_FACTOR_REGISTRATION_UPDATE,
    payload: response,
  };
}

export function twoFactorTokenVerification(token) {
  const response = GETrequest(`/TwoFactor/TokenVerification/${token}`);
  return {
    type: TWO_FACTOR_TOKEN_VERIFICATION,
    payload: response
  };
}

export function getTwoFactorToken(method) {
  const response = GETrequest(`/TwoFactor/Token/${method}`);
  return {
    type: TWO_FACTOR_TOKEN,
    payload: response
  };
}

// will be used on initial 2FA registration, when security code does NOT need to be validated
export function getInitialTwoFactorQRCode() {
  const response = GETrequest('/TwoFactor/QRCodes/Initial');
  return {
    type: TWO_FACTOR_QRCODES,
    payload: response
  };
}

// will be used on updating 2FA registration, when security code does need to be validated
export function getTwoFactorQRCode() {
  const response = GETrequest('/TwoFactor/QRCodes');
  return {
    type: TWO_FACTOR_QRCODES,
    payload: response
  };
}

export function getTwoFactorPush() {
  const response = GETrequest('/TwoFactor/Push');
  return {
    type: TWO_FACTOR_PUSH,
    payload: response
  };
}

export function getTwoFactorPushVerification() {
  const response = GETrequest('/TwoFactor/PushVerification');
  return {
    type: TWO_FACTOR_PUSH_VERIFICATION,
    payload: response
  };
}

export function getPreferredMethod() {
  const response = GETrequest('/TwoFactor/Users');
  return {
    type: TWO_FACTOR_PREFERRED_METHOD,
    payload: response
  };
}

export function savePreferredMethod(preferredMethod) {
  const response = PUTrequest('/TwoFactor/Users', preferredMethod);
  return {
    type: TWO_FACTOR_SAVE_PREFERRED_METHOD,
    payload: response,
  };
}