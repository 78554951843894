import React from 'react';
import PropTypes from 'prop-types';

import styles from '../styles.module.css';

const UserDetails = ({ name, email, userType, pin }) => (
  <div className={styles.nonEditableContainer}>
    <div className={styles.nonEditableField}>
      <span className={styles.label}>Name: </span>
      <span>{name}</span>
    </div>
    <div className={styles.nonEditableField}>
      <span className={styles.label}>Email: </span>
      <span>{email}</span>
    </div>
    <div className={styles.nonEditableField}>
      <span className={styles.label}>Type: </span>
      <span>{userType}</span>
    </div>
    <div className={styles.nonEditableField}>
      <span className={styles.label}>PIN #: </span>
      <span>{pin}</span>
    </div>
  </div>
);

UserDetails.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  pin: PropTypes.string.isRequired,
};

export default UserDetails;