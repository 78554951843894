import {
  GETrequest,
  POSTrequest,
  DELETErequest,
  PUTrequest
} from 'utils/helpers/api_handler';

import {
  USER_GET,
  USERS_GET,
  USER_CREATE,
  USER_UPDATE,
  USER_DELETE,
  RESEND_EMAIL,
  TOGGLE_ADMIN,
  PROGRAMS_GET,
} from './constants';

export function userGet(id) {
  const response = GETrequest(`/foundation/users/${id}`);
  return {
    type: USER_GET,
    payload: response
  };
}

export function usersGet() {
  const response = GETrequest('/foundation/users');
  return {
    type: USERS_GET,
    payload: response
  };
}

export function userCreate(endPoint, params) {
  const response = POSTrequest(endPoint, params);
  return {
    type: USER_CREATE,
    payload: response
  };
}

export function updateUser(userId, userBody) {
  const response = PUTrequest(`/foundation/users/${userId}`, userBody);
  return {
    type: USER_UPDATE,
    payload: response,
  };
}

export function userDelete(id) {
  const response = DELETErequest(`/foundation/users/${id}`);
  return {
    type: USER_DELETE,
    payload: response
  };
}

export function resendEmailActivation(externalUserId) {
  const response = POSTrequest(`/Foundation/Users/${externalUserId}/ResendActivationEmail`);
  return {
    type: RESEND_EMAIL,
    payload: response
  };
}

export function toggleAdmin(id, params) {
  const response = PUTrequest(`/foundation/users/${id}/admin`, params);
  return {
    type: TOGGLE_ADMIN,
    payload: response
  };
}

export function programsGet() {
  const response = GETrequest('/foundation/taxableentity');
  return {
    type: PROGRAMS_GET,
    payload: response
  };
}
