import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { phoneLastFour } from 'utils/helpers/sanitation';

import SubmitToken from '../../Shared/SubmitToken';

import {
  Button,
} from '@mui/material';

import {
  LoadingOverlay,
} from '@frontend/common';

import {
  getTwoFactorToken,
} from '../../actions';

import { REGISTER_METHODS } from '../../constants';

import styles from '../../styles.module.css';

const msg_security_code = (fourDigits) => `We sent a security code to (***) ***.${fourDigits}`;
const msg_we_called = (fourDigits) => `We called (***) ***.${fourDigits} with a security code`;

export class PhoneAuthenticate extends Component {

  static propTypes = {
    phoneNumber: PropTypes.string.isRequired,
    getTwoFactorToken: PropTypes.func.isRequired,
    selectedMethod: PropTypes.string.isRequired,
    onVerificationSuccess: PropTypes.func.isRequired,
  };

  state = {
    isSubmitting: false,
    attemptWarning: '',
    isResubmitting: false,
  };

  reSubmitPhoneCode() {
    const { selectedMethod } = this.props;
    this.setState({ isResubmitting: true, attemptWarning: '' });
    this.props.getTwoFactorToken(selectedMethod);
    setTimeout(() => {
      this.setState({ isResubmitting: false });
    }, 5000);
  }

  render() {
    const { phoneNumber, selectedMethod, onVerificationSuccess } = this.props;
    const { isResubmitting, isSubmitting } = this.state;
    const sendConfirmMsg = selectedMethod === REGISTER_METHODS.CALL ? msg_we_called(phoneLastFour(phoneNumber)) : msg_security_code(phoneLastFour(phoneNumber));
    let resubmitBtn;
    switch (selectedMethod) {
      case REGISTER_METHODS.CALL:
        resubmitBtn = isResubmitting ? 'calling...' : 'call me again';
        break;
      case REGISTER_METHODS.SMS:
        resubmitBtn = isResubmitting ? 'sending...' : 'resend code';
        break;
      default: break;
    }

    return (
      <div>
        <h2>Security Code</h2>
        <span>{sendConfirmMsg}</span>

        <SubmitToken
          disabled={isResubmitting}
          getIsSubmitting={isSubmitting => this.setState({ isSubmitting })}
          onVerificationSuccess={() => onVerificationSuccess(selectedMethod)}
        />

        <LoadingOverlay show={isResubmitting}>
          <Button
            disabled={isSubmitting}
            color='secondary'
            variant='text'
            className={styles.buttons}
            onClick={() => this.reSubmitPhoneCode()}
          >
            {resubmitBtn}
          </Button>
        </LoadingOverlay>

      </div>
    );
  }
}

export default connect(null, {
  getTwoFactorToken,
})(PhoneAuthenticate);