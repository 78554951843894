import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  TextField,
  Button,
} from '@mui/material';

import {
  FormWrapper,
  LoadingOverlay,
  PasswordRequirements,
  notificationShow
} from '@frontend/common';

import { passwordRequirementsGet } from 'components/AppRoot/StaticResources/actions';

import { resetPassword } from 'components/Features/public/ResetPassword/actions';

import styles from './styles.module.css';

const select = (state) => ({
  resetPassword: state.resetPassword,
  passwordRequirements: state.staticResources.passRequirements,
});

export class ResetPassword extends Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    passwordRequirementsGet: PropTypes.func.isRequired,
    passwordRequirements: PropTypes.array.isRequired,
  };

  state = {
    requestGUID: '',
    loading: false,
    password: '',
    passwordConfirmation: '',
    errors: [],
    formIsValid: false,
    passwordPassesValidation: false,
    submitLoading: false,
  };

  formValidate() {
    let isValid = false;
    let errors = [];
    const { password, passwordConfirmation, passwordPassesValidation } = this.state;

    if (passwordConfirmation && password !== passwordConfirmation) {
      errors.push({ field: 'passwordConfirmation', message: 'Passwords must match.' });
    }
    if (password && !passwordPassesValidation) {
      errors.push({ field: 'password', message: 'Password does not meet criteria below.' });
    }
    /* eslint-disable indent */
    if (
      password
      && passwordConfirmation
      && passwordPassesValidation
      && password === passwordConfirmation) {
      errors = [];
      isValid = true;
      errors = [];
      isValid = true;
    }
    /* eslint-enable indent */

    this.setState({
      formIsValid: isValid,
      errors,
    });
  }

  inputErrorTextGet = (inputName) => {
    let errorMessage = '';

    this.state.errors.forEach(error => {
      if (error.field === inputName) {
        errorMessage = error.message;
      }
    });

    return errorMessage;
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    if (this.state.formIsValid) {
      document.getElementById('password').blur();
      document.getElementById('passwordConfirm').blur();

      const postData = {
        id: this.state.requestGUID,
        password1: this.state.password,
        password2: this.state.passwordConfirmation,
      };

      this.setState({ submitLoading: true });
      this.props.resetPassword(postData)
        .then(() => {
          this.props.history.push('/login');
          this.props.notificationShow('Password changed.', 'success');
        })
        .catch(() => this.setState({ submitLoading: false }));
    }
  }

  onInputChange = (inputName, value) => {
    this.setState({
      [inputName]: value,
    }, () => {
      this.formValidate();
    });
  }

  componentDidMount() {
    const url = window.location.href;
    this.setState({
      requestGUID: url.slice(url.indexOf('request=')).replace('request=', ''),
    });
    if (this.props.passwordRequirements.length === 0) {
      this.setState({ loading: true });
      this.props.passwordRequirementsGet()
        .catch(() => null)
        .finally(() => this.setState({ loading: false }));
    }
  }

  render() {
    const { loading, submitLoading } = this.state;
    return (
      <FormWrapper title={'Reset Password'}>
        <LoadingOverlay
          show={loading}
          width='100%'
        >
          <form
            onSubmit={this.onFormSubmit}
            data-testid='auto-ResetPassword-submit-form'
          >
            <TextField
              id='password'
              name='password'
              label='New password'
              type='password'
              onChange={(e) => this.onInputChange('password', e.target.value)}
              inputProps={{ 'data-testid': 'auto-ResetPassword-newPassword-input' }}
              error={Boolean(this.inputErrorTextGet('password'))}
              helperText={this.inputErrorTextGet('password')}
              disabled={loading || submitLoading}
              fullWidth
            />
            <TextField
              id='passwordConfirm'
              name='passwordConfirmation'
              type='password'
              label='Confirm new password'
              onChange={(e) => this.onInputChange('passwordConfirmation', e.target.value)}
              inputProps={{ 'data-testid': 'auto-ResetPassword-confirmPassword-input' }}
              error={Boolean(this.inputErrorTextGet('passwordConfirmation'))}
              helperText={this.inputErrorTextGet('passwordConfirmation')}
              disabled={loading || submitLoading}
              fullWidth
            />
            <div className={styles.ResetPassword_divPadding}>
              <PasswordRequirements
                passwordRequirements={this.props.passwordRequirements}
                password={this.state.password}
                onPasswordCheck={(isValid) => this.onInputChange('passwordPassesValidation', isValid)}
              />
            </div>
            <div className={styles.ResetPassword_buttons}>
              <Button
                onClick={() => this.props.history.push('/login')}
                disabled={loading || submitLoading}
                style={{ width: '48%' }}
                data-testid='auto-ResetPassword-gotBackToLogin-button'
              >
                Cancel
              </Button>
              <LoadingOverlay show={submitLoading} width='48%'>
                <Button
                  type='submit'
                  variant='contained'
                  disabled={!this.state.formIsValid || loading || submitLoading}
                  fullWidth
                  data-testid='auto-ResetPassword-submit-button'
                >
                  Submit
                </Button>
              </LoadingOverlay>
            </div>
          </form>
        </LoadingOverlay>
      </FormWrapper>
    );
  }
}

export default withRouter(connect(select, {
  notificationShow,
  resetPassword,
  passwordRequirementsGet
})(ResetPassword));
