/*
*
* Programs Reducers
*
*/
import {
  cloneDeep,
  omit,
} from 'lodash';
import {
  TRANSACTION_DELETE,
  UPCOMING_TRANSACTIONS_GET,
  SCHEDULED_TRANSACTION_GET,
} from './constants';

const initialState = {
  upcomingTransactionsByProgram: {},
  transfers: {},
};

export function buildTransfer(data) {
  return {
    TransferId: data.TransferId,
    MasterAccountId: data.MasterAccountId,
    TotalAmount: data.TotalAmount,
    TradeDate: data.TradeDate,
    Status: data.Status,
    TransferDetails: data.TransferDetails.map(target => ({
      AccountId: target.AccountId,
      Amount: target.Amount,
      MilestoneId: target.MilestoneId,
    })),
  };
}

function ProgramsReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case UPCOMING_TRANSACTIONS_GET: {
      newState.upcomingTransactionsByProgram = {
        ...omit(newState.upcomingTransactionsByProgram, `${action.meta.accountId}`),
        [action.meta.accountId]: action.payload.data.map(trans => ({
          id: trans.ScheduleId,
          vdId: `${trans.ScheduleId}-${trans.Frequency}-${trans.Status}`,
          type: trans.TransactionType,
          amount: trans.Amount,
          scheduledDate: trans.ScheduledDate,
          frequency: trans.Frequency,
          status: trans.Status,
          transactions: trans.Transactions,
          url: trans.URL,
          canEdit: trans.CanEdit,
          canDelete: trans.CanDelete,
          accountId: trans.AccountId,
          accountGroupId: trans.AccountGroupId,
          typeName: trans.TransactionType
            .replace(' In', '')
            .replace(' Out', ''),
        })),
      };
      return newState;
    }

    case TRANSACTION_DELETE: {
      newState.upcomingTransactionsByProgram[action.meta.accountId] = newState.upcomingTransactionsByProgram[action.meta.accountId].filter(transaction => transaction.id !== action.meta.transactionId);
      return newState;
    }

    case SCHEDULED_TRANSACTION_GET: {
      let transaction = action.payload.data;
      switch (action.meta.type) {
        case 'Transfer': {
          transaction = buildTransfer(transaction);
          newState.transfers[transaction.TransferId] = transaction;
          break;
        }
        case 'Withdrawal':
        case 'Contribution':
        default:
          break;
      }
      return newState;
    }

    default:
      return state;
  }

}

export default ProgramsReducer;
