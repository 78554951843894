import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { SwipeableDrawer } from '@mui/material';

import SidenavMenuOption from './SidenavMenuOption';

import styles from './styles.module.css';

const appVersion = window.appVersion;

const select = (state) => ({
  userDetails: state.session.userDetails,
});

export class Sidenav extends Component {

  static propTypes = {
    showSideNav: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    handleLogout: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
  };

  onLogoutClick = () => {
    this.props.handleLogout();
    this.props.onClose();
  }

  render() {
    const { showSideNav, onClose, onOpen, userDetails } = this.props;
    return (
      <SwipeableDrawer
        open={showSideNav}
        onClose={onClose}
        onOpen={onOpen}
      >
        <SidenavMenuOption
          name='Home'
          link='/'
          onOptionClick={onClose}
          rootMenuOptionIconName='home'
        />
        <SidenavMenuOption
          name='Accounts'
          link='/accounts'
          onOptionClick={onClose}
          rootMenuOptionIconName='people'
        />

        { userDetails.CanAccessTransfers ?
          <SidenavMenuOption
            name='Transfers'
            link='/transfers'
            onOptionClick={onClose}
            rootMenuOptionIconName='swap_horiz'
          />
          : null
        }

        <SidenavMenuOption
          name='Withdrawals'
          link='/withdrawals'
          onOptionClick={onClose}
          rootMenuOptionIconName='attach_money'
        />
        { userDetails.CanAccessReports
          ? <SidenavMenuOption name='Reports' link='/reports' onOptionClick={onClose} rootMenuOptionIconName='trending_up' />
          : null
        }
        { userDetails.IsAdmin
          ? <SidenavMenuOption name='Admin' rootMenuOptionIconName='settings'>
            <SidenavMenuOption
              name='Manage Users'
              link='/users'
              onOptionClick={onClose}
            />
            <SidenavMenuOption
              name='Manage Tags'
              link='/tags'
              onOptionClick={onClose}
            />
          </SidenavMenuOption>
          : null
        }

        <div className={styles.Sidenav_buildVersion}>
          Version: {appVersion}
        </div>
      </SwipeableDrawer>
    );
  }
}

export default withRouter(connect(select, {})(Sidenav));
