import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { find } from 'lodash';

import {
  FloatingActionButton,
  ConfirmModal,
  notificationShow,
  SmartTable,
  TableDataTypes,
  TableColumnHideOptions,
  TableRowActionTypes,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';

import {
  usersGet,
  userDelete,
  toggleAdmin,
  resendEmailActivation,
  programsGet,
} from 'components/Features/protected/ManageUsers/actions';

import { USER_ROLES } from '../constants';

const mapUserList = (userList) => userList.map(user => ({
  ...user,
  programList: user.Programs.map(program => program.Name).join(', '),
}));

const select = (state) => ({
  userList: state.users.userList,
});

export class UsersList extends React.Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    programsGet: PropTypes.func.isRequired,
    usersGet: PropTypes.func.isRequired,
    userDelete: PropTypes.func.isRequired,
    toggleAdmin: PropTypes.func.isRequired,
    resendEmailActivation: PropTypes.func.isRequired,
    userList: PropTypes.array,
  };

  state = {
    selectedUserID: 0,
    selectedUser: {},
    users: [],
    loading_usersList: false,
    loading_tableAction: false,
    confirmModalParams: {
      show: false,
      title: '',
      body: '',
      onConfirm: () => null,
    }
  };

  deactivateUserHandle(id) {
    this.setState({ loading_tableAction: true });
    this.props.userDelete(id)
      .then(() => {
        this.onConfirmModalClose();
        this.props.usersGet()
          .then(() => this.setState({ users: mapUserList(this.props.userList) }));
        this.props.notificationShow('User deactivated.', 'success');
      })
      .catch(() => null)
      .finally(() => this.setState({ loading_tableAction: false }));
  }

  onDeactivateSelect = (id) => {
    const parsedId = parseInt(id);
    const user = find(this.state.users, (user) => user.ExternalUserId === parsedId);

    this.setState({
      selectedUserID: 0,
      selectedUser: user,
      confirmModalParams: {
        show: true,
        title: 'Deactivate User',
        body: `Are you sure you want to deactivate ${user.Name ? user.Name : user.Email}?`,
        onConfirm: () => this.deactivateUserHandle(parsedId),
      }
    });
  }

  onResendActivationEmailSelect = (id) => {
    const user = find(this.state.users, (user) => user.ExternalUserId === parseInt(id));
    this.setState({
      selectedUserID: parseInt(id),
      selectedUser: user,
      confirmModalParams: {
        show: true,
        title: 'Resend Activation Email',
        body: `Are you sure you want to resend an activation email to ${user.Email}?`,
        onConfirm: () => this.resendActivationEmailHandle(user.ExternalUserId),
      }
    });
  }

  onToggleAdminRightsSelect = (id, willBeAdmin) => {
    const parsedId = parseInt(id);
    const user = find(this.state.users, (user) => user.ExternalUserId === parsedId);

    this.setState({
      selectedUserID: parsedId,
      selectedUser: user,
      confirmModalParams: {
        show: true,
        title: willBeAdmin
          ? 'Grant Admin Rights'
          : 'Remove Admin Rights',
        body: willBeAdmin
          ? `Are you sure? Doing so will give ${user.Name ? user.Name : user.Email} access to all subprogram information and the ability to create, edit, and remove users and tags.`
          : `Are you sure? Doing so will remove ${user.Name ? user.Name : user.Email}'s ability to manage users and tags for this organization.`,
        onConfirm: () => this.toggleAdminRightsHandle(user, willBeAdmin)
      }

    });
  }

  onConfirmModalClose = () => {
    this.setState({
      selectedUserID: 0,
      selectedUser: {},
      confirmModalParams: {
        show: false,
        title: '',
        body: '',
        onConfirm: () => null,
      }
    });
  }


  resendActivationEmailHandle = (externalUserId) => {
    this.setState({ loading_tableAction: true });
    this.props.resendEmailActivation(externalUserId)
      .then(() => {
        this.onConfirmModalClose();
        this.props.notificationShow('Activation email resent.', 'success');
      })
      .catch(() => null)
      .finally(() => this.setState({ loading_tableAction: false }));
  }

  toggleAdminRightsHandle(user, willBeAdmin) {
    this.setState({ loading_tableAction: true });
    const data = {
      canAccess: willBeAdmin,
      taxableEntityProgramId: user.Programs[0].TaxableEntityId,
    };
    this.props.toggleAdmin(user.ExternalUserId, data)
      .then(() => {
        this.onConfirmModalClose();
        this.props.usersGet()
          .then(() => this.setState({ users: mapUserList(this.props.userList) }));
        this.props.notificationShow('User permission updated.', 'success');
      })
      .catch(() => null)
      .finally(() => this.setState({ loading_tableAction: false }));
  }

  componentDidMount() {
    this.setState({ loading_usersList: true });
    Promise.all([
      this.props.programsGet(),
      this.props.usersGet()
        .then(() => this.setState({ users: mapUserList(this.props.userList) })),
    ])
      .finally(() => this.setState({ loading_usersList: false }));
  }

  render() {
    const { history } = this.props;
    const {
      loading_usersList, loading_tableAction,
      users, confirmModalParams
    } = this.state;

    return (
      <div>
        <SmartTable
          idKey='ExternalUserId'
          emptyMessage='No users to display.'
          loading={loading_usersList}
          rows={users}
          actions={[
            {
              type: TableRowActionTypes.ROW_MENU,
              displayName: 'Resend Activation Email',
              onSelect: row => this.onResendActivationEmailSelect(row.ExternalUserId),
              showIf: row => (row.Status === 'Pending') && (row.UserType !== USER_ROLES.ENTITY_AGENT)
            },
            {
              type: TableRowActionTypes.ROW_MENU,
              displayName: 'Edit User',
              onSelect: row => {
                this.setState({ selectedUserID: parseInt(row.ExternalUserId) });
                history.push(`/users/${row.ExternalUserId}`);
              },
              showIf: row => (row.UserType !== 'Administrator') && (row.UserType !== USER_ROLES.ENTITY_AGENT)
            },
            {
              type: TableRowActionTypes.ROW_MENU,
              displayName: 'Remove Admin Rights',
              onSelect: row => this.onToggleAdminRightsSelect(row.ExternalUserId, false),
              showIf: row => (row.UserType === 'Administrator') && (row.UserType !== USER_ROLES.ENTITY_AGENT)
            },
            {
              type: TableRowActionTypes.ROW_MENU,
              displayName: 'Grant Admin Rights',
              onSelect: row => this.onToggleAdminRightsSelect(row.ExternalUserId, true),
              showIf: row => (row.UserType !== 'Administrator') && (row.UserType !== USER_ROLES.ENTITY_AGENT)
            },
            {
              type: TableRowActionTypes.ROW_MENU,
              displayName: 'Deactivate',
              onSelect: row => this.onDeactivateSelect(row.ExternalUserId),
              showIf: row => row.UserType !== USER_ROLES.ENTITY_AGENT
            },
          ]}
          columns={[
            {
              key: 'Name',
              title: 'Name',
              type: TableDataTypes.STRING
            },
            {
              key: 'Pin',
              title: 'PIN #',
              type: TableDataTypes.STRING
            },
            {
              key: 'Email',
              title: 'Email',
              type: TableDataTypes.STRING,
              hideOn: [TableColumnHideOptions.PHONE, TableColumnHideOptions.TABLET],
            },
            {
              key: 'programList',
              title: 'Programs',
              type: TableDataTypes.STRING,
              hideOn: [TableColumnHideOptions.PHONE],
            },
            {
              key: 'UserType',
              title: 'User Type',
              type: TableDataTypes.STRING
            },
            {
              key: 'Status',
              title: 'Status',
              type: TableDataTypes.STRING,
              hideOn: [TableColumnHideOptions.PHONE],
            },
          ]}
        >
          <TableToolbar />
          <TableContainer maxHeight='100%'>
            <TableHeader />
            <TableRows />
          </TableContainer>
          <TablePagination />
        </SmartTable>

        <FloatingActionButton
          mainIcon='add'
          hasMenu={false}
          mainButtonTitle='Add a user'
          mainButtonAction={() => history.push('/users/new')}
        />

        <ConfirmModal
          show={confirmModalParams.show}
          title={confirmModalParams.title}
          body={confirmModalParams.body}
          onModalClose={this.onConfirmModalClose}
          onConfirm={confirmModalParams.onConfirm}
          isLoading={loading_tableAction}
        />
      </div>
    );
  }
}

export default withRouter(connect(select, {
  usersGet,
  userDelete,
  programsGet,
  toggleAdmin,
  resendEmailActivation,
  notificationShow
})(UsersList));