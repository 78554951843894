import React, { Component } from 'react';
import { persistClientErrors } from './error_handler';

export default class ErrorBoundary extends Component {
  
  state = { 
    hasError: false,
    error: '',
    info: '' 
  };

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo
    });
    persistClientErrors({ error });
  }
  
  render() {
    
    const styles = {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    };
    
    
    if (this.state.errorInfo) {
      return (
        <div style={styles}>
          Ooops! Something didn&#39;t go as planned.
        </div>
      );
    }
    return this.props.children;
  }
}