import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Modal,
  LoadingOverlay,
  notificationShow,
  Dropdown,
} from '@frontend/common';

import {
  Button,
  TextField,
} from '@mui/material';

import UserPermissions from '../UserPermissions';
import MultiSelectorCheckboxes from '../MultiSelectorCheckboxes';

import {
  programsGet,
  userCreate
} from 'components/Features/protected/ManageUsers/actions';

import { getEmailError, getNameError, errors } from 'utils/helpers/formValidators';

import { USER_ROLES } from '../constants';

import styles from './styles.module.css';

const select = (state) => ({
  programs: state.users.programs,
});

export class CreateUser extends React.Component {

  static propTypes = {
    programsGet: PropTypes.func.isRequired,
    programs: PropTypes.array,
    userCreate: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
  };

  state = {
    isAdmin: false,
    email: '',
    firstName: '',
    lastName: '',
    selectedSubPrograms: [],
    canAccessReports: true,
    canAccessTransfers: false,
    canApproveWithdrawals: false,
    formErrors: {
      email: '',
      firstName: '',
      lastName: '',
      selectedSubPrograms: ''
    },
    loading_programs: false,
    loading_creatingUser: false,
    showPinModal: false,
    pin: ''
  };

  addUserHandle = () => {
    if (this.formValidate()) {
      this.setState({ loading_creatingUser: true });

      const data = {
        Email: this.state.email,
        FirstName: this.state.firstName,
        LastName: this.state.lastName,
      };
      let newUserAPIPath = '/foundation/users';

      if (this.state.isAdmin) {
        newUserAPIPath = '/foundation/users/admin';
      }
      else {
        data.ProgramIds = this.state.selectedSubPrograms.map(program => program.value);
        data.CanAccessReports = this.state.canAccessReports;
        data.CanApproveWithdrawals = this.state.canApproveWithdrawals;
        data.CanAccessTransfers = this.state.canAccessTransfers;
      }

      this.props.userCreate(newUserAPIPath, data)
        .then((response) => {
          const pin = response.payload.data;
          this.props.notificationShow('User added.', 'success');
          // so far new CSA user is always admin or program manager so we always show the pin popup
          this.setState({ showPinModal: true, pin });
        })
        .catch(() => null)
        .finally(() => this.setState({ loading_creatingUser: false }));
    }
  }

  formValidate = () => {
    const { email, firstName, lastName, selectedSubPrograms, isAdmin, formErrors } = this.state;
    const updatedFormErrors = { ...formErrors };

    if (!email) {
      updatedFormErrors.email = errors.required_field;
    }

    if (!firstName) {
      updatedFormErrors.firstName = errors.required_field;
    }

    if (!lastName) {
      updatedFormErrors.lastName = errors.required_field;
    }

    if (!isAdmin && selectedSubPrograms.length === 0) {
      updatedFormErrors.selectedSubPrograms = errors.required_field;
    }

    this.setState({ formErrors: updatedFormErrors });
    const hasFormErrors = Object.values(updatedFormErrors).findIndex(error => error) !== -1;
    return !hasFormErrors;
  }

  fieldValidate = (key) => {
    const { email, firstName, lastName, formErrors } = this.state;
    const updatedFormErrors = { ...formErrors };

    switch (key) {
      case 'email':
        updatedFormErrors.email = '';
        if (email) {
          updatedFormErrors.email = getEmailError(email);
        }
        break;
      case 'firstName':
        updatedFormErrors.firstName = '';
        if (firstName) {
          updatedFormErrors.firstName = getNameError(firstName);
        }
        break;
      case 'lastName':
        updatedFormErrors.lastName = '';
        if (lastName) {
          updatedFormErrors.lastName = getNameError(lastName);
        }
        break;
      case 'selectedSubPrograms':
        updatedFormErrors.selectedSubPrograms = '';
        break;
      default: // do nothing
    }

    this.setState({ formErrors: updatedFormErrors });
  }

  clearCreateUserState = () => {
    this.setState({
      isAdmin: false,
      email: '',
      firstName: '',
      lastName: '',
      selectedSubPrograms: [],
      canAccessReports: true,
      canAccessTransfers: false,
      canApproveWithdrawals: false,
      formErrors: {},
      pin: ''
    });
  }

  onTextInputChange = (e, key) => {
    this.setState({ [key]: e.target.value }, () => {
      this.fieldValidate(key);
    });
  }

  onSubProgramSelection = (selectedSubPrograms) => {
    this.setState({ selectedSubPrograms }, () => {
      this.fieldValidate('selectedSubPrograms');
    });
  }

  onUserTypeSelect = (value) => {
    this.setState({
      isAdmin: JSON.parse(value),
      selectedSubPrograms: [],
    });
  }

  componentDidUpdate() {
    const { programs } = this.props;
    if (programs === undefined || programs.length === 0) {
      this.setState({ loading_programs: true });
      this.props.programsGet()
        .catch(() => null)
        .finally(() => this.setState({ loading_programs: false }));
    }
  }

  render() {
    const { history } = this.props;
    const { loading_creatingUser, formErrors } = this.state;

    return (
      <>
        <div className={styles.container}>
          <div className={styles.titleBar}>
            <div>
              <Button
                variant='contained'
                onClick={() => history.push('/users')}
                disabled={loading_creatingUser}
              >
                &lt; User List
              </Button>
            </div>
            <div className={styles.title}>Create New User</div>
            <div>
              <Button
                onClick={this.clearCreateUserState}
                disabled={loading_creatingUser}
                style={{ marginRight: '5px' }}
              >
                Clear
              </Button>

              <LoadingOverlay show={loading_creatingUser}>
                <Button variant='contained' onClick={this.addUserHandle}>
                  Done
                </Button>
              </LoadingOverlay>
            </div>
          </div>
          <div className={styles.formContainer}>
            <Dropdown
              label='User Type'
              value={this.state.isAdmin.toString()}
              onChange={this.onUserTypeSelect}
              options={[
                {
                  value: 'true',
                  display: USER_ROLES.ADMINISTRATOR
                },
                {
                  value: 'false',
                  display: USER_ROLES.PROGRAM_MANAGER
                },
              ]}
            />
            <TextField
              label='First Name'
              value={this.state.firstName}
              onChange={(e) => this.onTextInputChange(e, 'firstName')}
              error={Boolean(formErrors.firstName)}
              helperText={formErrors.firstName}
              fullWidth
            />
            <TextField
              label='Last Name'
              value={this.state.lastName}
              onChange={(e) => this.onTextInputChange(e, 'lastName')}
              error={Boolean(formErrors.lastName)}
              helperText={formErrors.lastName}
              fullWidth
            />
            <TextField
              label='Email'
              value={this.state.email}
              onChange={(e) => this.onTextInputChange(e, 'email')}
              error={Boolean(formErrors.email)}
              helperText={formErrors.email}
              fullWidth
            />
            {!this.state.isAdmin && (
              <div style={{ width: '100%' }}>
                <LoadingOverlay
                  show={this.state.loading_programs}
                  width='100%'
                >
                  <MultiSelectorCheckboxes
                    selections={this.state.selectedSubPrograms}
                    defaultOptions={this.props.programs.map(program => ({ value: program.id, display: program.name }))}
                    onCheckboxSelection={this.onSubProgramSelection}
                    label='Select sub-program(s)...'
                    errorText={formErrors.selectedSubPrograms}
                  />
                </LoadingOverlay>
                <div className={styles.permissionsContainer}>
                  <UserPermissions
                    canAccessReports={this.state.canAccessReports}
                    canAccessTransfers={this.state.canAccessTransfers}
                    canApproveWithdrawals={this.state.canApproveWithdrawals}
                    updatePermission={(permissionName, permission) => this.setState({
                      [permissionName]: permission
                    })}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <Modal
          show={this.state.showPinModal}
          title='User PIN'
          onCloseModal={() => {
            this.setState({ showPinModal: false });
            this.clearCreateUserState();
          }}
          actionButtons={[{
            label: 'Ok',
            action: () => {
              this.setState({ showPinModal: false });
              this.clearCreateUserState();
            },
          }]}
        >
          <div>The user whose information you entered has been sent an email requesting their participation. Upon accepting your request, the new user will need to enter the PIN provided below to validate their setup. You are responsible for communicating the following PIN to the new user:</div>
          <div className={styles.pin}>{this.state.pin}</div>
          <div>The activation email will be valid for the next 10 calendar days.</div>
        </Modal>
      </>
    );
  }
}

export default connect(select, {
  userCreate,
  programsGet,
  notificationShow
})(CreateUser);