/*
*
* PageNotFound Component
*
*/

import React from 'react';
import { withRouter } from 'react-router-dom';

import {
  Icon,
  Button,
} from '@mui/material';

import styles from './styles.module.css';

export function PageNotFound({ history }) {
  return (
    <div className={styles.PageNotFound_container}>
      <div className={styles.PageNotFound_shrug}>
        {'¯\\_(ツ)_/¯'}
      </div>
      <p className={styles.PageNotFound_message}>
        Oops! Looks like you reached a page that doesn&apos;t exist.
      </p>
      <Button
        variant='contained'
        onClick={() => history.push('/')}
        data-testid='auto-PageNotFound-goBackHome-button'
      >
        <Icon>keyboard_arrow_left</Icon>
        Back to Home
      </Button>
    </div>
  );
}

export default withRouter(PageNotFound);
