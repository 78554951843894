/*
*
* PhoneNumbers Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
} from '@mui/material';

import {
  notificationShow,
  PhoneNumberInput,
  LoadingOverlay,
} from '@frontend/common';

import { getPhoneHasNo10DigitsError, cleanDigits } from 'utils/helpers/formValidators';

import { updateUserInfo } from 'components/AppRoot/Navigation/actions';

import styles from '../styles.module.css';

const select = (state) => ({
  userDetails: state.session.userDetails,
});

export class PhoneNumbers extends React.Component {
  static propTypes = {
    userDetails: PropTypes.object.isRequired,
    notificationShow: PropTypes.func.isRequired,
    updateUserInfo: PropTypes.func.isRequired,
  }

  state = {
    phoneNumber: this.props.userDetails.PhoneNumber,
    submitting: false,
    isUpdateBtnDisabled: true,
  };

  onPhoneChangeHandler = (phoneNumber) => {
    const { PhoneNumber } = this.props.userDetails;
    const isUpdateBtnDisabled = (
      !phoneNumber ||
      cleanDigits(phoneNumber) === cleanDigits(PhoneNumber) ||
      Boolean(getPhoneHasNo10DigitsError(phoneNumber))
    );
    this.setState({ phoneNumber, isUpdateBtnDisabled });
  }

  onSubmitPhoneNumbers = (e) => {
    e.preventDefault();
    const { phoneNumber } = this.state;
    const { userDetails: { FirstName, LastName }, notificationShow, updateUserInfo } = this.props;

    if (!getPhoneHasNo10DigitsError(phoneNumber)) {
      const updatedUser = {
        FirstName,
        LastName,
        PhoneNumber: cleanDigits(phoneNumber),
      };
      this.setState({ submitting: true });
      updateUserInfo(updatedUser)
        .then(() => {
          notificationShow('User\'s phone was updated.', 'success');
          this.setState({ isUpdateBtnDisabled: true });
        })
        .finally(() => this.setState({ submitting: false }));
    }
  }

  render() {
    const { phoneNumber, submitting, isUpdateBtnDisabled } = this.state;

    return (
      <form
        className={styles.myInfo_right_container}
        onSubmit={this.onSubmitPhoneNumbers}
        data-testid='auto-MyInfo-PhoneNumbers-submit-form'
      >
        <div className={styles.warning}>Warning: Changing a phone number here will not change the phone number you have registered for two factor authentication.</div>
        <div className={styles.myInfo_textInput}>
          <PhoneNumberInput
            label='Phone'
            name='Phone'
            onChange={({ value }) => this.onPhoneChangeHandler(value)}
            value={phoneNumber}
            fullWidth
            disabled={submitting}
            inputProps={{ 'data-testid': 'auto-MyInfo-PhoneNumbers-phone-input' }}
          />
        </div>
        <div className={styles.myInfo_button}>
          <LoadingOverlay show={submitting}>
            <Button
              type='submit'
              variant='contained'
              disabled={isUpdateBtnDisabled}
              data-testid='auto-MyInfo-PhoneNumbers-phone-update-button'
            >
              Update
            </Button>
          </LoadingOverlay>
        </div>
      </form>
    );
  }
}

export default connect(select, {
  notificationShow,
  updateUserInfo,
})(PhoneNumbers);
