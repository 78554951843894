import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  cloneDeep,
  every,
  isEqual,
  isEmpty,
  pick,
} from 'lodash';

import {
  Button,
  Icon,
  Tabs,
  Tab,
  TextField,
} from '@mui/material';

import {
  ConfirmModal,
  currencyFormatter,
  properCase,
  notificationShow,
  LoadingOverlay,
  TableDataTypes,
  TableColumnHideOptions,
  TableRowActionTypes,
  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';

import AccountDetailsCard from 'components/Features/protected/Accounts/AccountView/AccountDetailsCard';
import TransferDetails from './TransferDetails';
import { addressTypes } from 'components/Features/protected/Accounts/constants';

import {
  createAddress,
  updateAddress,
  deleteAddress,
} from 'components/Features/protected/Dashboard/actions';
import {
  getAccount,
  getAccountsByProgram,
  getTransferDetails,
} from 'components/Features/protected/Accounts/actions';
import {
  getUpcomingTransactions,
  getScheduledTransaction,
  cancelTransaction,
} from '../../Programs/actions';


import styles from './styles.module.css';

const select = (state, props) => ({
  account: state.accounts.account,
  programAccounts: state.accounts.accountsByProgramList,
  loadedTransferDetails: state.accounts.loadedTransferDetails,
  selectedTransfer: state.accounts.selectedTransfer,
  upcomingTransactions: state.programs.upcomingTransactionsByProgram[props.match.params.id] || []
});

export class ProgramView extends React.Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    getAccount: PropTypes.func.isRequired,
    getAccountsByProgram: PropTypes.func.isRequired,
    createAddress: PropTypes.func.isRequired,
    updateAddress: PropTypes.func.isRequired,
    deleteAddress: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
    programAccounts: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    // upcoming transactions
    getUpcomingTransactions: PropTypes.func.isRequired,
    upcomingTransactions: PropTypes.array.isRequired,
    cancelTransaction: PropTypes.func.isRequired,
    getScheduledTransaction: PropTypes.func.isRequired,
    getTransferDetails: PropTypes.func.isRequired,
    selectedTransfer: PropTypes.shape({
      transferId: PropTypes.number,
      transfers: PropTypes.arrayOf(
        PropTypes.shape({
          AccountNumber: PropTypes.string,
          BeneficiaryName: PropTypes.string,
          AccountType: PropTypes.string,
          Amount: PropTypes.number,
          Milestone: PropTypes.string
        })
      ),
    }),
    loadedTransferDetails: PropTypes.arrayOf(
      PropTypes.shape({
        transferId: PropTypes.number,
        transfers: PropTypes.arrayOf(
          PropTypes.shape({
            AccountNumber: PropTypes.string,
            BeneficiaryName: PropTypes.string,
            AccountType: PropTypes.string,
            Amount: PropTypes.number,
            Milestone: PropTypes.string
          })
        ),
      })
    ).isRequired,
  };

  state = {
    programName: '',
    accountOwnerName: '',
    accountNumber: '',
    agent: {
      name: '',
      userIsAgent: false,
      addresses: {
        Mailing: {
          AddressId: 0,
          StreetAddress1: '',
          StreetAddress2: '',
          City: '',
          State: '',
          Zip: '',
        },
        Physical: {
          AddressId: 0,
          StreetAddress1: '',
          StreetAddress2: '',
          City: '',
          State: '',
          Zip: '',
        },
        Other: {
          AddressId: 0,
          StreetAddress1: '',
          StreetAddress2: '',
          City: '',
          State: '',
          Zip: '',
        },
      }
    },
    netPrincipalContributions: currencyFormatter(0),
    currentTaxYearContribution: currencyFormatter(0),
    previousTaxYearContribution: currencyFormatter(0),
    investmentOption: '',
    individualAccountsValue: currencyFormatter(0),
    masterAccountValue: currencyFormatter(0),
    scholarshipAccountsValue: currencyFormatter(0),
    totalProgramValue: currencyFormatter(0),
    transactions: [],
    accounts: [],

    editingAddress: false,
    isSaveEnabled: false,
    loading_page: false,
    loading_agentAddress: false,
    loading_tables: false,
    showTransferDetails: false,
    loadingTransaction: false,
    activeAddressTab: addressTypes.MAILING,
    /* State used for canceling a transaction */
    showCancelConfirmationModal: false,
    cancelTransactionLoading: false,
    selectedTransaction: {},
    selectedTransfer: [],
    reloadingUpcomingTransactionsTable: false,
  };

  addressesRevert = () => {
    const agent = cloneDeep(this.state.agent);

    Object.keys(agent.addresses).forEach(addressType => {
      Object.keys(agent.addresses[addressType]).forEach(addressField => {
        const backupValue = this.state.programBackup[`Agent${addressType}Address`][addressField];
        agent.addresses[addressType][addressField] =
          addressField === 'StreetAddress1' || addressField === 'StreetAddress2' || addressField === 'City'
            ? properCase(backupValue || '')
            : (backupValue || '');
      });
    });

    this.setState({
      agent,
      editingAddress: false,
      isSaveEnabled: false,
    });
  };

  addressesSave = () => {
    this.setState({ loading_agentAddress: true });
    const addressFields = ['StreetAddress1', 'StreetAddress2', 'City', 'State', 'Zip'];
    const changedAddresses = [];
    const agent = cloneDeep(this.state.agent);
    const backup = cloneDeep(this.state.programBackup); // eslint-disable-line

    Object.values(addressTypes).forEach(type => {
      addressFields.some(field => {
        const newValue = (agent.addresses[type][field] || '').toLowerCase();
        const oldValue = (backup[`Agent${type}Address`][field] || '').toLowerCase();

        if (!isEqual(newValue, oldValue)) {
          changedAddresses.push(type);
          return true;
        }
        return false;
      });
    });

    if (changedAddresses.length === 0) {
      this.setState({ isSaveEnabled: false });
    }
    else {
      const apiCalls = [];
      const { id: programId } = this.props.match.params;

      changedAddresses.forEach(type => {
        const changedAddress = agent.addresses[type];
        if (changedAddress.AddressId && changedAddress.AddressId !== 0) {
          const isAddressEmpty = every(
            pick(changedAddress, ['StreetAddress1', 'StreetAddress2', 'City', 'State', 'Zip']),
            value => value === ''
          );
          if (isAddressEmpty) {
            apiCalls.push(this.props.deleteAddress(programId, changedAddress.AddressId));
          }
          else {
            apiCalls.push(
              this.props.updateAddress(programId, pick(changedAddress, [
                'AddressId',
                'StreetAddress1',
                'StreetAddress2',
                'City',
                'State',
                'Zip'
              ]))
            );
          }
        }
        else {
          apiCalls.push(
            this.props.createAddress(programId, {
              addressType: type,
              address: pick(changedAddress, [
                'StreetAddress1',
                'StreetAddress2',
                'City',
                'State',
                'Zip'
              ]),
            })
          );
        }
      });

      Promise.all(apiCalls)
        .then(() => {
          this.props.notificationShow('Agent addresses updated.', 'success');
          this.programGet(false);
          this.setState({
            editingAddress: false,
            loading_agentAddress: false,
            isSaveEnabled: false,
          });
        })
        .catch(() => {
          this.addressesRevert();
          this.setState({ loading_agentAddress: false });
        });
    }
  };

  addressUpdate(addressType, addressField, newValue) {
    const { agent, programBackup } = this.state;
    this.setState({
      agent: {
        ...agent,
        addresses: {
          ...agent.addresses,
          [addressType]: {
            ...agent.addresses[addressType],
            [addressField]: newValue,
          }
        }
      },
      isSaveEnabled: programBackup[`Agent${addressType}Address`][addressField] !== newValue,
    });
  }

  displayAddressCompose(address) {
    if (!address.StreetAddress1) {
      return (
        <div className={styles.ProgramView_agentAddress}>None</div>
      );
    }

    return (
      <div className={styles.ProgramView_agentAddress}>
        <div>{address.StreetAddress1}</div>
        <div>{address.StreetAddress2}</div>
        <div>
          <span>{address.City}</span>
          {address.State && <span>{`, ${address.State} `}</span>}
          <span>{address.Zip}</span>
        </div>
      </div>
    );
  }

  editableAddressCompose(type) {
    return (
      <div className={`${styles.ProgramView_agentAddress} ${styles.edit}`}>
        <TextField
          name={`${type.toLowerCase()}StreetAddress1`}
          label='Street Address 1'
          value={this.state.agent.addresses[type].StreetAddress1}
          onChange={(e) => this.addressUpdate(type, 'StreetAddress1', e.target.value)}
          inputProps={{ maxLength: 40, 'data-testid': `auto-ProgramView-agent-StreetAddress1Edit-${type}-input` }}
          fullWidth
        />
        <TextField
          name={`${type.toLowerCase()}StreetAddress2`}
          label='Street Address 2'
          value={this.state.agent.addresses[type].StreetAddress2}
          onChange={(e) => this.addressUpdate(type, 'StreetAddress2', e.target.value)}
          inputProps={{ maxLength: 40, 'data-testid': `auto-ProgramView-agent-StreetAddress2Edit-${type}-input` }}
          fullWidth
        />
        <TextField
          name={`${type.toLowerCase()}City`}
          label='City'
          value={this.state.agent.addresses[type].City}
          onChange={(e) => this.addressUpdate(type, 'City', e.target.value)}
          inputProps={{ maxLength: 26, 'data-testid': `auto-ProgramView-agent-CityEdit-${type}-input` }}
          fullWidth
        />
        <div>
          <TextField
            name={`${type.toLowerCase()}State`}
            label='State'
            value={this.state.agent.addresses[type].State}
            onChange={(e) => this.addressUpdate(type, 'State', e.target.value)}
            inputProps={{ maxLength: 2, 'data-testid': `auto-ProgramView-agent-StateEdit-${type}-input` }}
            style={{ width: '15%' }}
            fullWidth
          />
          <TextField
            name={`${type.toLowerCase()}Zip`}
            label='ZIP'
            value={this.state.agent.addresses[type].Zip}
            onChange={(e) => this.addressUpdate(type, 'Zip', e.target.value)}
            inputProps={{ 'data-testid': `auto-ProgramView-agent-ZipEdit-${type}-input` }}
            style={{ width: '80%', marginLeft: 10 }}
            fullWidth
          />
        </div>
      </div>
    );
  }

  programGet = (firstTimeLoad) => {
    firstTimeLoad
      ? this.setState({
        loading_page: true,
        loading_tables: true
      })
      : this.setState({ loading_page: true });

    const programId = this.props.location.search.split('?programId=')[1];
    Promise.all([
      this.props.getAccount(this.props.match.params.id),
      this.props.getAccountsByProgram(programId),
      this.props.getUpcomingTransactions(this.props.match.params.id),
    ])
      .then(() => {
        const { account: programData, programAccounts: accounts } = this.props;
        this.setState({
          programName: properCase(programData.ProgramName),
          accountOwnerName: properCase(programData.AccountOwnerName),
          accountNumber: programData.AccountNumber,
          agent: {
            name: properCase(programData.AgentName),
            userIsAgent: programData.IsUserTheAgent,
            addresses: {
              Mailing: {
                AddressId: programData.AgentMailingAddress.AddressId,
                StreetAddress1: properCase(programData.AgentMailingAddress.StreetAddress1),
                StreetAddress2: properCase(programData.AgentMailingAddress.StreetAddress2),
                City: properCase(programData.AgentMailingAddress.City),
                State: programData.AgentMailingAddress.State || '',
                Zip: programData.AgentMailingAddress.Zip || '',
              },
              Physical: {
                AddressId: programData.AgentPhysicalAddress.AddressId,
                StreetAddress1: properCase(programData.AgentPhysicalAddress.StreetAddress1),
                StreetAddress2: properCase(programData.AgentPhysicalAddress.StreetAddress2),
                City: properCase(programData.AgentPhysicalAddress.City),
                State: programData.AgentPhysicalAddress.State || '',
                Zip: programData.AgentPhysicalAddress.Zip || '',
              },
              Other: {
                AddressId: programData.AgentOtherAddress.AddressId,
                StreetAddress1: properCase(programData.AgentOtherAddress.StreetAddress1),
                StreetAddress2: properCase(programData.AgentOtherAddress.StreetAddress2),
                City: properCase(programData.AgentOtherAddress.City),
                State: programData.AgentOtherAddress.State || '',
                Zip: programData.AgentOtherAddress.Zip || '',
              },
            },
          },
          netPrincipalContributions: currencyFormatter(programData.NetPrincipalContributions),
          currentTaxYearContribution: currencyFormatter(programData.CurrentTaxYearContributions),
          previousTaxYearContribution: currencyFormatter(programData.PreviousTaxYearContributions),
          investmentOption: programData.InvestmentOption,
          masterAccountValue: currencyFormatter(programData.MasterValue),
          individualAccountsValue: currencyFormatter(programData.IndividualValue),
          scholarshipAccountsValue: currencyFormatter(programData.ScholarshipValue),
          totalProgramValue: currencyFormatter(programData.MasterValue + programData.ScholarshipValue + programData.IndividualValue),
          transactions: programData.Transactions,
          accounts: accounts.map(account => ({ ...account, BeneficiaryName: properCase(account.BeneficiaryName) })),
          programBackup: programData,
          loading_page: false,
          loading_tables: false,
        });
      })
      .catch(() => this.setState({ loading_page: false, loading_tables: false }));
  };

  upcomingTransactionEditHandler = (transaction) => {
    this.props.getScheduledTransaction(transaction).then(() => {
      let type;
      switch (transaction.typeName) {
        case 'Option Change': {
          type = 'option-changes';
          break;
        }
        case 'Transfer': {
          type = 'transfers';
          break;
        }
        case 'Withdrawal': {
          type = 'withdrawals';
          break;
        }
        case 'Contribution': {
          type = 'contributions';
          break;
        }
        default:
          break;
      }
      this.props.history.push(`/${type}/${transaction.id}/edit`);
    });
  };

  upcomingTransactionCancelHandler = (transaction) => {
    this.setState({ cancelTransactionLoading: true });
    this.props.cancelTransaction(transaction).then(() => {
      this.props.notificationShow(`${transaction.typeName} cancelled.`, 'success');
    })
      .catch(() => null)
      .finally(() => {
        this.setState({
          cancelTransactionLoading: false,
          showCancelConfirmationModal: false,
          selectedTransaction: {},
          reloadingUpcomingTransactionsTable: true,
        });
        this.props.getUpcomingTransactions(this.props.match.params.id)
          .then(() => {
            this.setState({ reloadingUpcomingTransactionsTable: false });
          })
          .catch(() => null);
      });
  };

  transferDetailsShow = transferId => {
    // first check if transaction was already loaded if not call api
    const foundTransfer = this.props.loadedTransferDetails.find(transfer => transfer.transferId === transferId);
    const accountId = this.props.match.params.id;

    if (!isEmpty(foundTransfer)) {
      this.setState({ selectedTransfer: foundTransfer.transfers });
      this.toggleDetailsDrawer();
    }
    else {
      this.setState({ loadingTransaction: true });
      this.props.getTransferDetails(accountId, transferId)
        .then(() => {
          this.setState({
            loadingTransaction: false,
            selectedTransfer: this.props.selectedTransfer.transfers,
          });
          this.toggleDetailsDrawer();
        })
        .catch(() => null);
    }
  }

  toggleDetailsDrawer = () => this.setState({ showTransferDetails: !this.state.showTransferDetails });

  componentDidMount() {
    this.programGet(true);
  }

  render() {
    const { upcomingTransactions } = this.props;
    const { activeAddressTab, selectedTransfer, selectedTransaction, loadingTransaction, showTransferDetails, } = this.state;

    return (
      /* eslint-disable indent */
      <div className={styles.ProgramView_container}>
        <div className={styles.ProgramView_detailsContainer}>
          <div className={styles.ProgramView_pageHeader}>
            <Button
              variant='contained'
              onClick={() => this.props.history.push('/')}
              data-testid='auto-ProgramView-goBackHome-button'
            >
              <Icon>keyboard_arrow_left</Icon>
              Back
            </Button>
            {this.state.loading_page && (
              <div className={styles.ProgramView_loading}>
                <LoadingOverlay
                  show={true}
                  width='400px'
                  height='15px'
                  indicatorHeight='15px'
                />
              </div>
            )}
          </div>
          <div className={styles.ProgramView_detailsContainer}>
            <div className={styles.ProgramView_cardRow}>
              <AccountDetailsCard
                containerStyle={{ width: '33%' }}
                canEdit={false}
                label='Account Number'
                displayChildren={
                  <span>{this.state.accountNumber}</span>
                }
              />
              <AccountDetailsCard
                containerStyle={{ width: '33%' }}
                canEdit={false}
                label='Program'
                displayChildren={
                  <span className={styles.ProgramView_programName}>{this.state.programName}</span>
                }
              />
              <AccountDetailsCard
                containerStyle={{ width: '33%' }}
                canEdit={false}
                label='Program Owner'
                displayChildren={
                  <span>{this.state.accountOwnerName}</span>
                }
              />
            </div>
            <div className={styles.ProgramView_cardRow}>
              <AccountDetailsCard
                containerStyle={{ width: '33%', minHeight: '200px' }}
                canEdit={this.state.agent.userIsAgent}
                isSaveEnabled={this.state.isSaveEnabled}
                onEdit={() => this.setState({ editingAddress: true })}
                onSave={() => this.addressesSave()}
                onRevert={this.addressesRevert}
                loading={this.state.loading_agentAddress}
                label='Agent'
                displayChildren={
                  <div>
                    <div className={styles.ProgramView_detailsRowTitle}>{this.state.agent.name}</div>
                    <Tabs
                      value={activeAddressTab}
                      onChange={(e, activeAddressTab) => this.setState({ activeAddressTab })}
                      variant='fullWidth'
                      style={{ color: 'var(--text)' }}
                      centered
                    >
                      <Tab
                        label={addressTypes.MAILING}
                        value={addressTypes.MAILING}
                        style={{ minWidth: '100px' }}
                        data-testid='auto-ProgramView-agent-addressDisplay-mailing-tabButton'
                      />
                      <Tab
                        label={addressTypes.PHYSICAL}
                        value={addressTypes.PHYSICAL}
                        style={{ minWidth: '100px' }}
                        data-testid='auto-ProgramView-agent-addressDisplay-physical-tabButton'
                      />
                      <Tab
                        label={addressTypes.OTHER}
                        value={addressTypes.OTHER}
                        style={{ minWidth: '100px' }}
                        data-testid='auto-ProgramView-agent-addressDisplay-other-tabButton'
                      />
                    </Tabs>
                    {this.displayAddressCompose(this.state.agent.addresses[activeAddressTab])}
                  </div>
                }
                editingChildren={
                  <div>
                    <div className={styles.ProgramView_detailsRowTitle}>{this.state.agent.name}</div>
                    <Tabs
                      value={activeAddressTab}
                      onChange={(e, activeAddressTab) => this.setState({ activeAddressTab })}
                      variant='fullWidth'
                      style={{ color: 'var(--text)' }}
                      centered
                    >
                      <Tab
                        label={addressTypes.MAILING}
                        value={addressTypes.MAILING}
                        style={{ minWidth: '100px' }}
                        data-testid='auto-ProgramView-agent-addressEdit-mailing-tabButton'
                      />
                      <Tab
                        label={addressTypes.PHYSICAL}
                        value={addressTypes.PHYSICAL}
                        style={{ minWidth: '100px' }}
                        data-testid='auto-ProgramView-agent-addressEdit-physical-tabButton'
                      />
                      <Tab
                        label={addressTypes.OTHER}
                        value={addressTypes.OTHER}
                        style={{ minWidth: '100px' }}
                        data-testid='auto-ProgramView-agent-addressEdit-other-tabButton'
                      />
                    </Tabs>
                    {this.editableAddressCompose(activeAddressTab)}
                  </div>
                }
              />
              <AccountDetailsCard
                containerStyle={{ width: '33%' }}
                canEdit={false}
                label='Investment Details'
                displayChildren={
                  <div>
                    <div className={styles.ProgramView_detailsRow}>
                      <span className={styles.ProgramView_detailsRowTitle}>Net Principal Contributions:</span>
                      <span>{this.state.netPrincipalContributions}</span>
                    </div>
                    <div className={styles.ProgramView_detailsRow}>
                      <span className={styles.ProgramView_detailsRowTitle}>Current Tax Year Contributions:</span>
                      <span>{this.state.currentTaxYearContribution}</span>
                    </div>
                    <div className={styles.ProgramView_detailsRow}>
                      <span className={styles.ProgramView_detailsRowTitle}>Previous Tax Year Contributions:</span>
                      <span>{this.state.previousTaxYearContribution}</span>
                    </div>
                    <div className={styles.ProgramView_detailsRow}>
                      <span className={styles.ProgramView_detailsRowTitle}>Investment Option:</span>
                      <span>{this.state.investmentOption}</span>
                    </div>
                  </div>
                }
              />
              <AccountDetailsCard
                containerStyle={{ width: '33%' }}
                canEdit={false}
                label='Financial Totals'
                displayChildren={
                  <div>
                    <div className={styles.ProgramView_detailsRow}>
                      <span className={styles.ProgramView_detailsRowTitle}>Master Account Value:</span>
                      <span>{this.state.masterAccountValue}</span>
                    </div>
                    {parseFloat(this.state.individualAccountsValue.replace(/\$|,/g, '')) > 0 ?
                      <div className={styles.ProgramView_detailsRow}>
                        <span className={styles.ProgramView_detailsRowTitle}>Individual Account Value:</span>
                        <span>{this.state.individualAccountsValue}</span>
                      </div>
                      :
                      null
                    }
                    {parseFloat(this.state.scholarshipAccountsValue.replace(/\$|,/g, '')) > 0 ?
                      <div className={styles.ProgramView_detailsRow}>
                        <span className={styles.ProgramView_detailsRowTitle}>Scholarship Accounts Value:</span>
                        <span>{this.state.scholarshipAccountsValue}</span>
                      </div>
                      :
                      null
                    }
                    <div className={styles.ProgramView_detailsRow}>
                      <span className={styles.ProgramView_detailsRowTitle}>Total Program Value:</span>
                      <span>{this.state.totalProgramValue}</span>
                    </div>
                  </div>
                }
              />
            </div>
            <div className={styles.ProgramView_cardRow}>
              <AccountDetailsCard
                containerStyle={{ width: '100%' }}
                canEdit={false}
                label=''
                displayChildren={
                  <div className={styles.ProgramView_tableContainer}>
                    <SmartTable
                      idKey='vdId'
                      emptyMessage='No upcoming transactions to display'
                      rows={upcomingTransactions}
                      loading={this.state.loading_tables}
                      title='Upcoming Transactions'
                      columns={[
                        {
                          key: 'type',
                          title: 'Transaction Type',
                          type: TableDataTypes.STRING,
                        },
                        {
                          key: 'scheduledDate',
                          title: 'Scheduled Date',
                          type: TableDataTypes.DATE_STRING,
                          hideOn: [TableColumnHideOptions.PHONE],
                        },
                        {
                          key: 'frequency',
                          title: 'Frequency',
                          type: TableDataTypes.STRING,
                          hideOn: [TableColumnHideOptions.PHONE],
                        },
                        {
                          key: 'status',
                          title: 'Status',
                          type: TableDataTypes.STRING,
                          hideOn: [TableColumnHideOptions.PHONE],
                        },
                        {
                          key: 'transactions',
                          title: 'Transactions',
                          type: TableDataTypes.NUMBER,
                          hideOn: [TableColumnHideOptions.PHONE],
                        },
                        {
                          key: 'amount',
                          title: 'Amount',
                          type: TableDataTypes.CURRENCY,
                        },
                      ]}
                      actions={[
                        {
                          displayName: 'Edit',
                          type: TableRowActionTypes.ROW_MENU,
                          onSelect: row => this.upcomingTransactionEditHandler(row),
                          showIf: row => row.canEdit,
                        },
                        {
                          displayName: 'Cancel',
                          type: TableRowActionTypes.ROW_MENU,
                          onSelect: row => this.setState({
                            showCancelConfirmationModal: true,
                            selectedTransaction: row,
                          }),
                          showIf: row => row.canDelete,
                        },
                      ]}
                    >
                      <TableToolbar canSearch={false} />
                      <TableContainer maxHeight='100%'>
                        <TableHeader />
                        <TableRows />
                      </TableContainer>
                    </SmartTable>
                  </div>
                }
              />
            </div>
            <div className={styles.ProgramView_cardRow}>
              <AccountDetailsCard
                containerStyle={{ width: '100%' }}
                canEdit={false}
                label=''
                displayChildren={
                  <div className={styles.ProgramView_tableContainer}>
                    <SmartTable
                      idKey='TransactionId'
                      emptyMessage='No transactions to display.'
                      loading={this.state.loading_tables || loadingTransaction}
                      rows={this.state.transactions}
                      title='Transactions'
                      columns={[
                        {
                          key: 'Date',
                          title: 'Date',
                          type: TableDataTypes.DATE_STRING,
                        },
                        {
                          key: 'Type',
                          title: 'Type',
                          type: TableDataTypes.STRING,
                        },
                        {
                          key: 'Basis',
                          title: 'Basis',
                          type: TableDataTypes.CURRENCY,
                          hideOn: [TableColumnHideOptions.PHONE],
                        },
                        {
                          key: 'Amount',
                          title: 'Amount',
                          type: TableDataTypes.CURRENCY,
                        },
                      ]}
                      actions={[
                        {
                          type: TableRowActionTypes.ROW_ICON,
                          icon: 'arrow_left',
                          onSelect: row => this.transferDetailsShow(row.TransactionId),
                          iconTitle: 'View Details',
                          showIf: row => row.Type === 'Transfer'
                        },
                      ]}
                    >
                      <TableToolbar />
                      <TableContainer maxHeight='100%'>
                        <TableHeader />
                        <TableRows />
                      </TableContainer>
                      <TablePagination />
                    </SmartTable>
                  </div>
                }
              />
            </div>
            <div className={styles.ProgramView_cardRow}>
              <AccountDetailsCard
                containerStyle={{ width: '100%' }}
                canEdit={false}
                label=''
                displayChildren={
                  <div className={styles.ProgramView_tableContainer}>
                    <SmartTable
                      idKey='AccountId'
                      emptyMessage='No accounts to display.'
                      loading={this.state.loading_tables}
                      rows={this.state.accounts}
                      title='Program Accounts'
                      actions={[{
                        displayName: 'View Account',
                        type: TableRowActionTypes.ROW_MENU,
                        onSelect: row => this.props.history.push(`/accounts/${row.AccountId}`),
                      }]}
                      columns={[
                        {
                          key: 'AccountNumber',
                          title: 'Account Number',
                          type: TableDataTypes.NUMBER,
                          hideOn: [TableColumnHideOptions.PHONE],
                        },
                        {
                          key: 'BeneficiaryName',
                          title: 'Beneficiary Name',
                          type: TableDataTypes.STRING,
                        },
                        {
                          key: 'AccountType',
                          title: 'Account Type',
                          type: TableDataTypes.STRING,
                          hideOn: [TableColumnHideOptions.PHONE],
                        },
                        {
                          key: 'MarketValue',
                          title: 'Market Value',
                          type: TableDataTypes.CURRENCY,
                        },
                      ]}
                    >
                      <TableToolbar />
                      <TableContainer maxHeight='100%'>
                        <TableHeader />
                        <TableRows />
                      </TableContainer>
                      <TablePagination />
                    </SmartTable>
                  </div>
                }
              />
            </div>
          </div>
        </div>
        <TransferDetails
          open={showTransferDetails}
          onClose={this.toggleDetailsDrawer}
          transfer={selectedTransfer}
        />
        <ConfirmModal
          show={this.state.showCancelConfirmationModal}
          title={`Cancel ${selectedTransaction.typeName}`}
          body={`Are you sure you want to cancel this ${selectedTransaction.typeName}?`}
          onModalClose={() => this.setState({ showCancelConfirmationModal: false })}
          onConfirm={() => this.upcomingTransactionCancelHandler(selectedTransaction)}
          isLoading={this.state.cancelTransactionLoading}
        />
      </div>
    );
  }
}

export default connect(select, {
  getAccount,
  getAccountsByProgram,
  notificationShow,
  createAddress,
  updateAddress,
  deleteAddress,
  getUpcomingTransactions,
  getScheduledTransaction,
  cancelTransaction,
  getTransferDetails,
})(ProgramView);
