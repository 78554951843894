import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  Button,
} from '@mui/material';

import {
  notificationShow,
  allNotificationsHide
} from '@frontend/common';

import {
  twoFactorRegistration,
  getTwoFactorToken,
  savePreferredMethod
} from '../actions';

import PwddByAuthy from '../PwddByAuthy.jpg';

import RegisterPhoneAndMethod from './RegisterPhoneAndMethod';
import VerifySmsOrCall from './VerifySmsOrCall';
import Confirmation from './Confirmation';
import RegisterAuthenticator from './RegisterAuthenticator';
import VerifyAuthy from '../Shared/VerifyAuthy';
import OneTouch from '../Shared/OneTouch';

import { userLogout, clearStore, getUserInfo } from 'components/AppRoot/Navigation/actions';

import { REGISTER_METHODS } from '../constants.js';
import { TWO_FACTOR_TYPES } from 'components/AppRoot/Navigation/constants';

import styles from '../styles.module.css';

// registration steps
const REGISTRATION_STEPS = {
  REGISTER_PHONE_AND_METHOD: 'registerPhoneAndMethod',
  VERIFY_SMS_OR_PHONE: 'verifySmsOrPhone',
  VERIFY_AUTHY: 'verifyAuthy',
  ONE_TOUCH: 'oneTouch',
  VERIFY_OTHER_AUTHORIZATION: 'verifyOtherAuthorization',
  CONFIRMATION: 'confirmation'
};

const select = (state) => ({
  userDetails: state.session.userDetails,
});

export class Registration extends Component {

  static propTypes = {
    twoFactorRegistration: PropTypes.func.isRequired,
    getTwoFactorToken: PropTypes.func.isRequired,
    userLogout: PropTypes.func.isRequired,
    clearStore: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    savePreferredMethod: PropTypes.func.isRequired,
    getUserInfo: PropTypes.func.isRequired,
  };

  state = {
    isLoading: false,
    registrationStep: REGISTRATION_STEPS.REGISTER_PHONE_AND_METHOD, // TODO: will change with preferred method
    method: '',
    phoneNumber: '',
    country: { country: 'us', dialCode: 1 }
  };

  logOut = () => {
    const token = sessionStorage.getItem('token');
    this.props.userLogout({ token })
      .finally(() => {
        this.props.notificationShow('You have logged out.', 'success');
        this.props.clearStore();
        this.props.history.push('/login');
      });
  }

  onRegisterPhoneAndMethodSubmit = ({ phoneNumber, country, method }, hasError) => {
    this.setState({ method, phoneNumber, country });
    const countryCode = `+${country.dialCode}`;
    // remove the country code from the phone number and strip all non-numbers - api requires that
    phoneNumber = phoneNumber.replace(countryCode, '').replace(/\D/g, '');

    if (!hasError) {
      // clear all error toasts
      this.props.allNotificationsHide();

      const regParams = {
        countryCode,
        phoneNumber,
        emailAddress: this.props.userDetails.EmailAddress,
        verificationMethod: method
      };

      this.setState({ isLoading: true });
      this.props.twoFactorRegistration(regParams)
        .then(() => {
          switch (method) {
            // only sms and call will need api call to push security code to user's phone
            case REGISTER_METHODS.SMS:
            case REGISTER_METHODS.CALL:
              this.props.getTwoFactorToken(method)
                .then(() => this.setState({ isLoading: false, registrationStep: REGISTRATION_STEPS.VERIFY_SMS_OR_PHONE }))
                .catch(() => this.setState({ isLoading: false }));
              break;
            // user will get security code on their auth app
            case REGISTER_METHODS.INAPP:
              this.setState({ isLoading: false, registrationStep: REGISTRATION_STEPS.VERIFY_AUTHY });
              break;
            case REGISTER_METHODS.INAPPTOKEN:
              this.setState({ isLoading: false, registrationStep: REGISTRATION_STEPS.VERIFY_OTHER_AUTHORIZATION });
              break;

            default: // do nothing;
          }
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  }

  onVerificationSuccess = () => {
    // save 2FA user preferences
    const params = {
      AlwaysRequired: true, // assuming always on
      PreferredMethod: this.state.method // submits selected method based on if text or phone is chosen
    };
    this.setState({ isLoading: true });
    // also get user details with PIN here
    Promise.all([this.props.savePreferredMethod(params), this.props.getUserInfo()])
      .then(() => {
        // process again and will throw an error on submitting already registered user.
        this.setState({ registrationStep: REGISTRATION_STEPS.CONFIRMATION, isLoading: false });
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  render() {
    const { isLoading, registrationStep, method, phoneNumber, country } = this.state;

    let render2FARegistrationStep;

    const useAnotherMethodButton = () => (
      <div>
        <Button
          color='secondary'
          disabled={isLoading}
          className={styles.buttons}
          onClick={() => this.setState({ registrationStep: REGISTRATION_STEPS.REGISTER_PHONE_AND_METHOD })}
        >
          Use another method
        </Button>
      </div>
    );

    const cancelButton = () => (
      <div>
        <Button
          color='secondary'
          variant='text'
          style={{ marginTop: '10px' }}
          onClick={this.logOut}
          className={styles.buttons}
        >
          Cancel
        </Button>
      </div>
    );

    switch (registrationStep) {
      case REGISTRATION_STEPS.REGISTER_PHONE_AND_METHOD:
        render2FARegistrationStep = (
          <>
            <RegisterPhoneAndMethod
              isLoading={isLoading}
              onSubmit={this.onRegisterPhoneAndMethodSubmit}
              defaultMethod={''}
              defaultPhoneNumber={''}
              defaultCountry={country}
            />
            {cancelButton()}
          </>
        );
        break;

      case REGISTRATION_STEPS.VERIFY_SMS_OR_PHONE:
        render2FARegistrationStep = (
          <>
            <VerifySmsOrCall
              onVerificationSuccess={this.onVerificationSuccess}
              onVerificationFail={this.logOut}
              phoneNumber={phoneNumber}
              method={method}
            />
            {useAnotherMethodButton()}
            {cancelButton()}
          </>
        );
        break;

      case REGISTRATION_STEPS.VERIFY_AUTHY:
        render2FARegistrationStep = (
          <>
            <VerifyAuthy
              title='Enter security code or request OneTouch approval with the Authy App'
              showInstructions={true}
              onVerificationSuccess={this.onVerificationSuccess}
              onVerificationFail={this.logOut}
              onOneTouchClick={() => this.setState({ registrationStep: REGISTRATION_STEPS.ONE_TOUCH })}
            />
            {useAnotherMethodButton()}
            {cancelButton()}
          </>
        );
        break;

      case REGISTRATION_STEPS.ONE_TOUCH:
        render2FARegistrationStep = (
          <>
            <OneTouch
              onVerificationSuccess={this.onVerificationSuccess}
            />
            {useAnotherMethodButton()}
            {cancelButton()}
          </>
        );
        break;

      case REGISTRATION_STEPS.VERIFY_OTHER_AUTHORIZATION:
        render2FARegistrationStep = (
          <>
            <RegisterAuthenticator
              registerType={TWO_FACTOR_TYPES.REGISTRATION}
              onVerificationSuccess={this.onVerificationSuccess}
              onVerificationFail={this.logOut}
            />
            {useAnotherMethodButton()}
            {cancelButton()}
          </>
        );
        break;

      case REGISTRATION_STEPS.CONFIRMATION:
        render2FARegistrationStep = (
          <>
            <Confirmation />
          </>
        );
        break;

      default: // do nothing
    }

    return (
      <div>

        {render2FARegistrationStep}

        <div>
          <Button
            color='secondary'
            variant='outlined'
            style={{ marginTop: '10px' }}
            onClick={() => window.open('https://my529.org/faq-two-factor-authentication/', '_blank', 'noopener noreferrer')}
            className={styles.buttons}
          >
            Learn More
          </Button>
        </div>

        <div>
          <img src={PwddByAuthy} width='174px' alt='Powered By Authy' />
        </div>

      </div>
    );
  }
}

export default withRouter(connect(select, {
  twoFactorRegistration,
  getTwoFactorToken,
  userLogout,
  clearStore,
  notificationShow,
  allNotificationsHide,
  savePreferredMethod,
  getUserInfo,
})(Registration));