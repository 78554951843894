import { cloneDeep } from 'lodash';
import {
  WITHDRAWAL_PENDING,
  WITHDRAWAL_HISTORY,
} from './constants';

const initialState = {
  pending: [],
  history: [],
};

function WithdrawlsReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case WITHDRAWAL_PENDING: {
      newState.pending = action.payload.data;
      return newState;
    }

    case WITHDRAWAL_HISTORY: {
      newState.history = action.payload.data;
      return newState;
    }

    default:
      return newState;
  }
  
}

export default WithdrawlsReducer;