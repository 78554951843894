import { colors } from './constants';
import { lighten } from '@mui/material/styles';

const appColors = {
  primary: colors.primary || '#41AD49', // Appeases the unit test gods' inability to load css
  accent: colors.accent || '#EA7125',
  warn: colors.warn || 'rgb(250, 229, 0)',
  error: colors.error || '#E50000',
  text: colors.text || 'rgba(0, 0, 0, 0.87)',
  linkText: colors.linkText || '#41AD49',
};

export default {
  palette: {
    common: {
      warn: appColors.warn,
    },
    primary: {
      main: appColors.primary,
      light: lighten(appColors.primary, 0.85),
      dark: appColors.primary,
    },
    secondary: {
      main: appColors.accent,
      dark: appColors.accent,
    },
    error: {
      main: appColors.error,
    },
    text: {
      main: appColors.text,
      dark: appColors.text,
    },
    linkText: {
      main: appColors.linkText,
      dark: appColors.linkText,
    },
    row: {
      trim: lighten(appColors.primary, 0.9),
      hover: lighten(appColors.primary, 0.8),
      selected: lighten(appColors.primary, 0.6),
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        contained: {
          color: '#fff',
          backgroundColor: appColors.accent,
          '&:hover': {
            backgroundColor: lighten(appColors.accent, 0.4),
          },
        },
        outlined: {
          borderColor: appColors.accent,
        },
        text: {
          color: appColors.accent,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: appColors.accent,
        },
        label: {
          color: '#fff',
        },
        deleteIcon: {
          color: '#fff',
          '&:hover': {
            color: 'rgb(215, 215, 215)',
          },
        },
        avatar: {
          backgroundColor: '#fff',
          color: appColors.accent,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflowY: 'auto', // allows modal content to scroll, if screen height becomes too small
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          color: '#fff',
          backgroundColor: appColors.accent,
          '&:hover': {
            backgroundColor: lighten(appColors.accent, 0.4),
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          margin: '5px 0',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: appColors.accent,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'rgb(0, 0, 0, .89)',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:after': {
            borderBottomColor: appColors.accent,
          },
          '&:before': {
            borderBottom: '1px solid rgb(175, 175, 175)'
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: '2px solid rgb(175, 175, 175)',
          },
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        anchorOrigin: { // makes menu anchor show up on anchor point rather than slightly below it
          vertical: 'top',
          horizontal: 'left',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#FFF',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: '0 12px',
          color: 'secondary',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: appColors.accent,
          },
          '&.Mui-completed': {
            color: appColors.accent,
          },
        },
        text: {
          fill: '#FFF'
        }
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          padding: 24,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: appColors.accent,
          },
          indicatorColor: {
            color: appColors.accent,
          },
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'secondary',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'MyriadPro-Regular',
      'Myriad Pro Regular',
      'MyriadPro',
      'Myriad Pro',
      'PT Sans',
      'Arial',
      'sans-serif',
    ]
  },
  breakpoints: {
    keys: ['sm', 'md'],
    values: {
      sm: 600,
      md: 1200,
    },
  }
};
