import { POSTrequest } from 'utils/helpers/api_handler';
import { USER_ACTIVATE } from './constants';


export function activateUser(params) {
  const response = POSTrequest('/foundation/users/activate', params);
  return {
    type: USER_ACTIVATE,
    payload: response
  };
}