import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getEntity } from 'components/Features/protected/Dashboard/actions';

import {
  SmartTable,
  TableDataTypes,
  TableRowActionTypes,
  TableColumnHideOptions,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';

import ProgramTotals from './ProgramTotals';

import styles from './styles.module.css';


const select = (state) => ({
  programList: state.dashboard.programList,
});

export class ProgramsList extends React.Component {

  static propTypes = {
    getEntity: PropTypes.func.isRequired,
    programList: PropTypes.array.isRequired,
  };

  state = {
    loading: false,
    masterAccountsTotal: 0,
    scholarshipAccountsTotal: 0,
    individualAccountsTotal: 0,
  };

  componentDidMount() {
    let masterAccountsTotal = 0;
    let scholarshipAccountsTotal = 0;
    let individualAccountsTotal = 0;

    if (this.props.programList.length === 0) {
      this.setState({ loading: true });
      this.props.getEntity()
        .then(() => {
          this.props.programList.forEach(program => {
            masterAccountsTotal += program.MasterValue;
            scholarshipAccountsTotal += program.ScholarshipValue;
            individualAccountsTotal += program.IndividualValue;
          });

          this.setState({
            masterAccountsTotal,
            scholarshipAccountsTotal,
            individualAccountsTotal,
            loading: false,
          });
        })
        .catch(() => this.setState({ loading: false }));
    }
    else {
      this.props.programList.forEach(program => {
        masterAccountsTotal += program.MasterValue;
        scholarshipAccountsTotal += program.ScholarshipValue;
        individualAccountsTotal += program.IndividualValue;
      });

      this.setState({
        masterAccountsTotal,
        scholarshipAccountsTotal,
        individualAccountsTotal,
      });
    }

  }

  render() {
    const columns = [
      {
        key: 'AccountNumber',
        title: 'Account Number',
        type: TableDataTypes.NUMBER,
        hideOn: [TableColumnHideOptions.PHONE],
      },
      {
        key: 'Name',
        title: 'Program Name',
        type: TableDataTypes.STRING,
      },
      {
        key: 'Option',
        title: 'Investment Option',
        type: TableDataTypes.STRING,
        hideOn: [TableColumnHideOptions.PHONE],
      },
      {
        key: 'MasterValue',
        title: 'Master Account Value',
        type: TableDataTypes.CURRENCY,
        hideOn: [TableColumnHideOptions.PHONE],
      },
      {
        key: 'ProgramValue',
        title: 'Total Program Value',
        type: TableDataTypes.CURRENCY,
      },
    ];

    if (this.state.individualAccountsTotal > 0) {
      columns.splice(columns.length - 1, 0, {
        key: 'IndividualValue',
        title: 'Individual Accounts Value',
        type: TableDataTypes.CURRENCY,
        hideOn: [TableColumnHideOptions.PHONE, TableColumnHideOptions.TABLET],
      });
    }

    if (this.state.scholarshipAccountsTotal > 0) {
      columns.splice(columns.length - 1, 0, {
        key: 'ScholarshipValue',
        title: 'Scholarship Accounts Value',
        type: TableDataTypes.CURRENCY,
        hideOn: [TableColumnHideOptions.PHONE, TableColumnHideOptions.TABLET],
      });
    }

    return (
      <div className={styles.ProgramsList_container}>
        <SmartTable
          idKey='AccountId'
          emptyMessage='No programs to display.'
          rows={this.props.programList}
          columns={columns}
          actions={[{
            displayName: 'View Program',
            type: TableRowActionTypes.ROW_MENU,
            onSelect: row => this.props.history.push(`/programs/${row.AccountId}${row.TaxableEntityProgramId ? `?programId=${row.TaxableEntityProgramId}` : ''}`),
          }]}
          loading={this.state.loading}
        >
          <TableToolbar />
          <TableContainer maxHeight='100%'>
            <TableHeader stickyHeader={false} />
            <TableRows />
          </TableContainer>
          <TablePagination />
        </SmartTable>

        {this.props.programList.length > 1 && (
          <div className={styles.ProgramsList_programTotalsContainer}>
            <ProgramTotals
              masterAccountsTotal={this.state.masterAccountsTotal}
              scholarshipAccountsTotal={this.state.scholarshipAccountsTotal}
              individualAccountsTotal={this.state.individualAccountsTotal}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(connect(select, { getEntity })(ProgramsList));
