import {
  GETrequest,
  POSTrequest
} from 'utils/helpers/api_handler';

import {
  REPORT_GET,
  REPORTS_GET,
  REPORT_RUN,
  REPORTS_DOWNLOAD,
  PROGRAMS_GET
} from './constants';

export function getReport(id) {
  const response = GETrequest(`/reports/${id}`);
  return {
    type: REPORT_GET,
    payload: response
  };
}

export function getReports() {
  const response = GETrequest('/reports');
  return {
    type: REPORTS_GET,
    payload: response
  };
}

export function runReport(id, params) {
  const response = POSTrequest(`/reports/${id}`, params);
  return {
    type: REPORT_RUN,
    payload: response
  };
}

export function downloadReport(id, params) {
  const response = POSTrequest(`/reports/${id}/download`, params);
  return {
    type: REPORTS_DOWNLOAD,
    payload: response
  };
}

export function getPrograms() {
  const response = GETrequest('/foundation/taxableentity');
  return {
    type: PROGRAMS_GET,
    payload: response
  };
}