import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import TagModal from '../TagModal';
import UntagModal from './UntagModal';

import {
  properCase,
  notificationShow,
  SmartTable,
  TableDataTypes,
  TableRowActionTypes,
  TableColumnHideOptions,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';

import { getAccounts } from '../actions';

const select = (state) => ({
  accounts: cloneDeep(state.accounts.accountList).map(account => ({
    id: account.AccountId,
    accountNumber: account.AccountNumber,
    beneName: properCase(account.BeneficiaryName),
    investmentOption: account.InvestmentOption,
    program: account.ProgramName,
    marketValue: account.MarketValue,
    tags: account.Tags.map(tag => ({ id: tag.TaxableEntityTagId, tag: tag.Tag })),
    tagsString: account.Tags.map(tag => tag.Tag).join(', '),
    type: account.AccountType,
    status: account.Status,
  })),
});

export class AccountsList extends React.Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    getAccounts: PropTypes.func.isRequired,
    accounts: PropTypes.array.isRequired,
  };

  state = {
    selectedAccounts: [],
    tagOpen: false,
    untagOpen: false,
    loading: false,
  };

  accountsGet = () => {
    this.setState({ loading: true });
    this.props.getAccounts()
      .catch(() => null)
      .finally(() => this.setState({ loading: false }));
  }

  componentDidMount() {
    this.accountsGet();
  }

  render() {
    return (
      <div>
        <SmartTable
          idKey='id'
          emptyMessage='No accounts to display.'
          loading={this.state.loading}
          rows={this.props.accounts}
          selectEnabled
          columns={[
            {
              key: 'accountNumber',
              title: 'Account Number',
              type: TableDataTypes.NUMBER,
              hideOn: [TableColumnHideOptions.PHONE],
              customStyle: { width: '0.5fr' },
            },
            {
              key: 'beneName',
              title: 'Beneficiary Name',
              type: TableDataTypes.STRING,
            },
            {
              key: 'type',
              title: 'Account Type',
              type: TableDataTypes.STRING,
              hideOn: [TableColumnHideOptions.PHONE, TableColumnHideOptions.TABLET],
            },
            {
              key: 'program',
              title: 'Program',
              type: TableDataTypes.STRING,
              customStyle: { width: '0.75fr' },
            },
            {
              key: 'investmentOption',
              title: 'Investment Option',
              type: TableDataTypes.STRING,
              hideOn: [TableColumnHideOptions.PHONE],
            },
            {
              key: 'marketValue',
              title: 'Market Value',
              type: TableDataTypes.CURRENCY,
              hideOn: [TableColumnHideOptions.PHONE],
            },
            {
              key: 'status',
              title: 'Status',
              type: TableDataTypes.STRING,
              hideOn: [TableColumnHideOptions.PHONE],
            },
            {
              key: 'tagsString',
              title: 'Tags',
              type: TableDataTypes.STRING,
              hideOn: [TableColumnHideOptions.PHONE, TableColumnHideOptions.TABLET],
              customStyle: { width: '1.5fr' },
            },
          ]}
          actions={[
            {
              displayName: 'View Details',
              type: TableRowActionTypes.ROW_MENU,
              onSelect: row => this.props.history.push(`/accounts/${row.id}`)
            },
          ]}
        >
          <TableToolbar
            bulkActions={[
              {
                display: 'Tag Accounts',
                type: TableRowActionTypes.BULK_ACTION,
                icon: 'label',
                onClick: accounts => this.setState({ selectedAccounts: accounts, tagOpen: true }),
              },
              {
                display: 'Untag Accounts',
                type: TableRowActionTypes.BULK_ACTION,
                icon: 'label_outline',
                onClick: accounts => this.setState({ selectedAccounts: accounts, untagOpen: true }),
              },
            ]}
          />
          <TableContainer maxHeight='100%'>
            <TableHeader />
            <TableRows />
          </TableContainer>
          <TablePagination />
        </SmartTable>

        <TagModal
          accountsGet={this.accountsGet}
          onClose={() => this.setState({ tagOpen: false })}
          open={this.state.tagOpen}
          selectedAccounts={this.state.selectedAccounts}
        />
        <UntagModal
          accountsGet={this.accountsGet}
          onClose={() => this.setState({ untagOpen: false })}
          open={this.state.untagOpen}
          selectedAccounts={this.state.selectedAccounts}
        />
      </div>
    );
  }
}

export default withRouter(connect(select, { getAccounts, notificationShow })(AccountsList));