import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { kebabCase } from 'lodash';
import dayjs from 'dayjs';
import bowser from 'bowser';

import { errors } from 'utils/helpers/formValidators';

import {
  Button,
  Collapse,
} from '@mui/material';

import {
  KeyboardArrowLeft,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';

import {
  MultiSelector,
  LoadingOverlay,
  notificationShow,
  allNotificationsHide,
  DatePicker,
} from '@frontend/common';

import ReportDataTable from './ReportDataTable';

import {
  downloadReport,
  getReport,
  runReport,
  getPrograms
} from 'components/Features/protected/Reports/actions';

import styles from './styles.module.css';

const select = (state) => ({
  programs: state.reports.programs,
  reportData: state.reports.reportData,
});

export class ReportView extends React.Component {

  static propTypes = {
    downloadReport: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    allNotificationsHide: PropTypes.func,
    runReport: PropTypes.func.isRequired,
    getPrograms: PropTypes.func.isRequired,
    programs: PropTypes.array.isRequired,
    reportData: PropTypes.object,
  };

  state = {
    name: '',
    description: '',
    reportNumber: '',
    reportParams: [],
    reportColumns: [],
    reportColumnTypes: [],
    reportData: [],
    programs: [],
    paramsToSubmit: {},
    paramsToSubmitErrors: {},
    showForm: true,
    noDataReturned: false,

    loading_reportParams: true,
    loading_reportData: false,
    loading_reportDownload: false,
    maxDate: dayjs().subtract(1, 'day'),
  };

  formValidate() {
    const { paramsToSubmit, paramsToSubmitErrors, maxDate } = this.state;
    const updatedParamsToSubmitErrors = { ...paramsToSubmitErrors };

    Object.keys(paramsToSubmit).forEach(key => {
      if (Array.isArray(paramsToSubmit[key])) {
        if (paramsToSubmit[key].length === 0) {
          updatedParamsToSubmitErrors[key] = errors.required_field;
        }
      }
      else {
        if (key.includes('Date')) {
          const date = dayjs(paramsToSubmit[key]);
          if (!paramsToSubmit[key]) {
            updatedParamsToSubmitErrors[key] = errors.required_field;
          }
          else if (!date || !date.isValid() || !date.isBefore(maxDate)) {
            updatedParamsToSubmitErrors[key] = errors.invalid_date;
          }
        }
        else if (!key.includes('Date') && !paramsToSubmit[key]) {
          updatedParamsToSubmitErrors[key] = errors.required_field;
        }
      }
    });

    this.setState({ paramsToSubmitErrors: updatedParamsToSubmitErrors });
    return !Object.values(updatedParamsToSubmitErrors).find(err => err);
  }

  parameterInputCompose(param) {
    const { paramsToSubmitErrors, programs, paramsToSubmit, maxDate } = this.state;

    let input = null;
    switch (param.DataType) {
      case 'int':
        if (param.ParamName.toLowerCase() === 'taxableentityprogramid') {
          const mappedPrograms = programs.map(program => ({
            value: program.TaxableEntityProgramId,
            display: program.Name
          }));
          const currentlySelectedOptions = mappedPrograms.filter(program => paramsToSubmit[param.ParamName].includes(program.value));

          input = (
            <MultiSelector
              options={mappedPrograms}
              onSelection={selected => this.parameterUpdate(param, selected.map(program => program.value))}
              hintText='Add program(s)...'
              selectedOptions={currentlySelectedOptions}
              errorText={paramsToSubmitErrors[param.ParamName]}
            />
          );
        }
        break;

      case 'datetime':
        input = (
          <DatePicker
            label={param.DisplayName}
            onChange={date => this.parameterUpdate(param, date)}
            maxDate={maxDate}
            maxDateMessage={'Date cannot be today\'s date or a future date.'}
            fullWidth
            error={Boolean(paramsToSubmitErrors[param.ParamName])}
            helperText={paramsToSubmitErrors[param.ParamName]}
          />
        );
        break;

      default:
        break;
    }

    return (
      <div key={param.ReportParamId} className={styles.ReportView_input}>
        {input}
      </div>
    );
  }

  parameterUpdate = (paramDetails, newValue) => {
    // when coming from date picker strip both timestamp and timezone
    this.setState({
      paramsToSubmit: {
        ...this.state.paramsToSubmit,
        [paramDetails.ParamName]: paramDetails.DataType === 'datetime' ? newValue.format('MM-DD-YYYY') : newValue,
      },
      // always clear the field error
      paramsToSubmitErrors: {
        ...this.state.paramsToSubmitErrors,
        [paramDetails.ParamName]: '',
      }
    });
  }

  reportDownload = () => {
    this.setState({ loading_reportDownload: true });
    this.props.downloadReport(this.props.match.params.id, this.state.paramsToSubmit)
      .then(res => {
        const date = new Date();
        const blob = new Blob([res.payload.data], { type: 'text/csv;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const fileName = `${kebabCase(this.state.reportNumber)}-${this.state.name.toLowerCase().includes('report') ? kebabCase(this.state.name) : `${kebabCase(this.state.name)}-report`}-${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}.csv`;

        if (bowser.name === 'Internet Explorer' || bowser.name === 'Microsoft Edge') {
          navigator.msSaveBlob(blob, fileName);
        }
        else {
          const hiddenElement = document.createElement('a');
          hiddenElement.href = url;
          hiddenElement.download = fileName;
          document.body.appendChild(hiddenElement);
          hiddenElement.click();
          setTimeout(() => {
            document.body.removeChild(hiddenElement);
            window.URL.revokeObjectURL(url);
          }, 50);
        }
      })
      .catch(() => null)
      .finally(() => this.setState({ loading_reportDownload: false }));
  }

  reportRun = () => {
    this.props.allNotificationsHide();

    if (this.formValidate()) {
      this.setState({
        loading_reportData: true,
        reportData: [],
      });

      this.props.runReport(this.props.match.params.id, this.state.paramsToSubmit)
        .then(res => {
          const reportData = res.payload.data;
          if (reportData.Data.length > 5000) {
            this.props.notificationShow('Only displaying 5,000 rows as the selected report criteria returned a large data set. Please download report to see all report data.', 'warning');
          }

          this.setState({
            reportColumns: reportData.ColumnNames,
            reportColumnTypes: reportData.ColumnTypes,
            reportData: reportData.Data.length > 5000 ? reportData.Data.slice(0, 5000) : reportData.Data,
            showForm: reportData.Data.length < 1,
            noDataReturned: reportData.Data.length < 1,
            loading_reportData: false,
          });
        })
        .catch(() => this.setState({ loading_reportData: false }));
    }
  }

  componentDidMount() {
    const apiCalls = [this.props.getReport(this.props.match.params.id)];
    this.setState({ loading_reportParams: true });

    if (this.props.programs === undefined || this.props.programs.length === 0) {
      apiCalls.push(this.props.getPrograms());
    }

    Promise.all(apiCalls)
      .then(() => {
        const reportData = this.props.reportData;
        const paramsToSubmit = {};

        if (reportData === null) {
          this.props.history.push('/reports');
        }
        else {
          reportData.ReportParams.forEach(param => {
            if (param.ParamName.toLowerCase() === 'taxableentityprogramid') {
              paramsToSubmit[param.ParamName] = [];
            }
            else {
              paramsToSubmit[param.ParamName] = null;
            }
          });
          this.setState({
            name: reportData.DisplayName,
            description: reportData.Description,
            reportNumber: reportData.InternalReportNumber,
            reportParams: reportData.ReportParams,
            paramsToSubmit,
            programs: this.props.programs,
            loading_reportParams: false,
          });
        }
      })
      .catch(() => this.setState({ loading_reportParams: false }));
  }

  render() {
    // Doesn't show page until data has loaded to keep from jumpy rendering
    if (this.state.loading_reportParams) {
      return (
        <div className={styles.ReportView_loadingContainer}>
          <LoadingOverlay
            width='100%'
            indicatorHeight='15px'
            show
          />
        </div>
      );
    }
    else {
      return (
        <div>
          <div className={styles.ReportView_reportDetailsContainer}>
            <div className={styles.ReportView_titleContainer}>
              <Button
                className={styles.ReportView_backButton}
                onClick={() => this.props.history.push('/reports')}
                variant='contained'
                startIcon={<KeyboardArrowLeft />}
                data-testid='auto-ReportView-goToReports-button'
              >
                Back
              </Button>
              <span className={styles.ReportView_title}>{this.state.name}</span>
            </div>
            <div className={styles.ReportView_formShowToggleButton}>
              <Button
                onClick={() => this.setState({ showForm: !this.state.showForm })}
                variant='text'
                startIcon={this.state.showForm ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                data-testid='auto-ReportView-toggleFormVisibility-button'
              >
                {this.state.showForm ? 'Hide' : 'Show Report Details'}
              </Button>
            </div>
            <Collapse in={this.state.showForm}>
              <div className={this.state.showForm ? styles.ReportView_form : styles.ReportView_formHidden}>
                {this.state.reportNumber && (
                  <div>
                    <strong>Report Number: </strong>
                    <span>{this.state.reportNumber}</span>
                  </div>
                )}
                {this.state.description && (
                  <div>
                    <strong>Description: </strong>
                    <span>{this.state.description}</span>
                  </div>
                )}
                <div className={styles.ReportView_confidentialText}>
                  <b>CONFIDENTIAL</b> – This report includes confidential information that is intended only for the official use of CSA administrators that have been granted access to program accounts.
                </div>
                <div className={styles.ReportView_inputs}>
                  {this.state.reportParams.map(param => this.parameterInputCompose(param))}
                </div>
                <div className={styles.ReportView_buttons}>
                  <div className={styles.ReportView_buttonContainer}>
                    <LoadingOverlay show={this.state.loading_reportData}>
                      <Button
                        disabled={this.state.loading_reportData}
                        onClick={this.reportRun}
                        variant='contained'
                        data-testid='auto-ReportView-runReport-button'
                      >
                        Run Report
                      </Button>
                    </LoadingOverlay>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
          <ReportDataTable
            columnNames={this.state.reportColumns}
            columnTypes={this.state.reportColumnTypes}
            data={this.state.reportData}
            noDataReturned={this.state.noDataReturned}
            onDownloadClick={this.reportDownload}
            downloadLoading={this.state.loading_reportDownload}
          />
        </div>
      );
    }
  }
}


export default withRouter(connect(select, {
  notificationShow,
  allNotificationsHide,
  downloadReport,
  runReport,
  getReport,
  getPrograms
})(ReportView));
